import React, { useEffect, useState, useRef, useCallback } from "react";
import "./index.css";
import { debounce } from "lodash";
import BeneViewAllCard from "../BeneViewAllCard";
import { Box, Hidden, Tooltip, tooltipClasses } from "@mui/material";
import SearchBar from "../../../../components/_utils/search/SearchBar";
import {
  beneViewAllAcc,
  setToggle,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useDispatch, useSelector } from "react-redux";
import AllStatusTypeButton from "../AllStatusTypeButton";
import BeneAccFilterSidebar from "../BeneAccFilterSidebar";
import noaccount from "../../../../assets/Graphics/No transaction found.svg";
import { useLocation, useNavigate } from "react-router-dom";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import closeBtnImg from "../../../../assets/CloseIcons/close_with_blue_circle.svg";
import rentalImg from "../../../../assets/CategoryIcons/rental.svg";
import arrowForward from "../../../../assets/ArrowsIcons/arrow_right_blue.svg";
import arrowdown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowDown8 from "../../../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowUp4 from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import FilterBlue from "../../../../assets/FilterSearchIcons/filter_blue.svg";
import Filter from "../../../../assets/FilterSearchIcons/filter.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../../redux/features/login/login";
import search_blue from "../../../../assets/FilterSearchIcons/search_blue.svg";
import search_white from "../../../../assets/FilterSearchIcons/search_white.svg";
// import allChecker from "../../../../assets/Beneficiary Icons/whitecheckmark.png";
import ArrowLeft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import ListBlue from "../../../../assets/ViewIcons/list_view_blue.svg";
import ListWhite from "../../../../assets/ViewIcons/list_view_white.svg";
import GridBlue from "../../../../assets/ViewIcons/Grid view blue.svg";
import GridWhite from "../../../../assets/ViewIcons/Grid view white.svg";
import BeneAccListView from "../BeneAccListView";
import AllStatusTypeButtonMob from "../AllStatusTypeButton/AllStatusTypeButtonMob.jsx";
import DataCountPopup from "../../../../components/_utils/DataCountPopup";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization/index.js";
import Cancel from "../../../../assets/ViewAllTransactions/Clear filter.png";
import arrowup from "../../../../assets/ArrowsIcons/arrow_up_white.svg";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken.js";
import PaginationComponent from "../../../../components/_utils/PaginationComponent/index.jsx";

const BeneAccViewAll = ({ typepop, onClosepop, handleButtonClick }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const approvalStatus = location.state ? location.state.approvalStatus : "";
  const [searchPanText, setSearchPanText] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showSearchBarm, setShowSearchBarm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const { services, isLoading } = useSelector((state) => state.refreshToken);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(approvalStatus);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarOpenm, setIsSidebarOpenm] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [searchUserText, setSearchUserText] = useState("");
  const [initCategory, setInitCategory] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [searchIfscText, setSearchIfscText] = useState("");
  const [isIfsc, setIsIfsc] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchAccountText, setSearchAccountText] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpenm, setIsPopoverOpenm] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownOptions, setDropdownOptions] = useState([50, 100]);
  const [selectedValue, setSelectedValue] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [mainData, setMainData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRefm = useRef(null);
  const [isOpenm, setIsOpenm] = useState(false);
  const [selectedValuem, setSelectedValuem] = useState(50);
  const [showDataCountPopup, setShowDataCountPopup] = useState(false);
  const filterRef = useRef(null);
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [isWheelScrolling, setIsWheelScrolling] = useState(false);
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );
  useEffect(() => {
    if (!isLoading && services != null) {
      if (services?.includes("BVAccount") || services?.includes("Admin")) {
        dispatch(checkUsertoken());
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BVAccount" },
        });
      }
    }
  }, [services, isLoading]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getRefreshTokenNew());
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRefAcc.current &&
        !searchBarRefAcc.current.contains(event.target) &&
        searchBarRefAcc2.current &&
        !searchBarRefAcc2.current.contains(event.target)
      ) {
        setShowSearchBar(false);
      }

      if (
        searchBarRefAccm.current &&
        !searchBarRefAccm.current.contains(event.target) &&
        searchBarRefAccm2.current &&
        !searchBarRefAccm2.current.contains(event.target)
      ) {
        setShowSearchBarm(false);
      }

      if (
        searchBarRefAccm.current &&
        !searchBarRefAccm.current.contains(event.target) &&
        searchBarRefAccm2.current &&
        !searchBarRefAccm2.current.contains(event.target)
      ) {
        setShowSearchBarm(false);
      }

      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }

      if (
        dropdownRefm.current &&
        !dropdownRefm.current.contains(event.target)
      ) {
        setIsOpenm(false);
      }

      if (
        dropdownRefm.current &&
        !dropdownRefm.current.contains(event.target)
      ) {
        setIsOpenm(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalCount / pageSize);
    setTotalPages(newTotalPages);

    setCurrentPage((prevPage) => {
      if (newTotalPages === 0) {
        return 1;
      }
      if (prevPage > newTotalPages) {
        return newTotalPages;
      }
      return prevPage;
    });
  }, [totalCount, pageSize]);

  // useEffect(() => {
  //   setDropdownOptions([50, 100].filter((value) => value !== selectedValue));
  // }, [selectedValue]);

  // useEffect(() => {
  //   setDropdownOptions([50, 100].filter((value) => value !== selectedValuem));
  // }, [selectedValuem]);

  const getIcon = () => {
    if (showSearchBar) {
      return searchUserText !== "" ? ArrowUp4 : arrowup;
    } else {
      return searchUserText !== "" ? ArrowDown8 : arrowdown;
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdownm = () => {
    setIsOpenm(!isOpenm);
  };

  const handleToggle = (newToggleValue) => {
    if (toggle !== newToggleValue) {
      const scrollTarget = scrollContainerRef.current || window;
      scrollTarget.scrollTo({ top: 0, behavior: "smooth" });

      if (!showSearchBar && !isSidebarOpen) {
        dispatch(setToggle(newToggleValue));
      }

      setCurrentPage(1);
    }
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage));
    toggleDropdown();
  };

  const handleSelectm = (value) => {
    setSelectedValuem(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage));
    setAccumulatedData([]);
    toggleDropdownm();
  };

  const dispatch = useDispatch();

  const searchBarRefAcc = useRef(null);
  const searchBarRefAcc2 = useRef(null);

  const searchBarRefAccm = useRef(null);
  const searchBarRefAccm2 = useRef(null);
  const scrollContainerRef = useRef(null);
  const sidebarButtonRef = useRef(null);
  const { beneViewAllAccData, storedPage, toggle } = useSelector(
    (state) => state.beneAllVerification
  );

  const handleFilterCategory = (selectedCategoryName) => {
    setCurrentCategory(selectedCategoryName);
  };

  const lastElementRef = useRef();

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
    setShowDatePicker(false);
  };

  const toggleSidebarm = () => {
    setIsSidebarOpenm((prevState) => !prevState);
    setShowDatePicker(false);
  };

  useEffect(() => {
    if (approvalStatus && approvalStatus !== "all") {
      setIsStatusSelected(true);
    } else {
      setIsStatusSelected(false);
    }
    setCurrentStatus(approvalStatus);
  }, []);

  const handleKeyDown = useCallback((event) => {
    const activeElement = document.activeElement;
    const isTyping =
      activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA";

    if (
      !isTyping &&
      (event.key.toLowerCase() === "w" ||
        event.key.toLowerCase() === "s" ||
        event.key === "ArrowUp" ||
        event.key === "ArrowDown")
    ) {
      event.preventDefault();
      setIsWheelScrolling(false);
    }
  }, []);

  const handleWheel = useCallback((event) => {
    setIsWheelScrolling(true);
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("wheel", handleWheel);
    };
  }, [handleKeyDown, handleWheel]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (currentPage === 1) {
          setLoading(true);
        }

        let queryParams = {
          page_no: currentPage,
          page_size: pageSize,
          // search_string: searchUserText,
        };

        const addParamsBasedOnSelection = () => {
          if (currentStatus !== "all") {
            queryParams.approval_status = currentStatus;
          }
          if (currentCategory !== "") {
            queryParams.categoryName = currentCategory;
          }
          if (
            startDateFilter !== "" &&
            endDateFilter !== "" &&
            startDateFilter !== 0 &&
            endDateFilter !== 0 &&
            !isNaN(Number(startDateFilter)) &&
            !isNaN(Number(endDateFilter))
          ) {
            queryParams.start_date = startDateFilter;
            queryParams.end_date = endDateFilter;
          }
          if (accountNumber !== "") {
            queryParams.bank_account_no = accountNumber;
          }
          if (panNumber !== "") {
            queryParams.pan = panNumber;
          }
          if (searchUserText !== "") {
            queryParams.search_string = searchUserText;
          }
          if (isIfsc !== "") {
            queryParams.ifsc = isIfsc;
          }
        };
        addParamsBasedOnSelection();
        dispatch(checkUsertoken());
        const response = await dispatch(beneViewAllAcc(queryParams));
        const newData = response?.payload?.beneficiary_accounts || [];
        setMainData(response?.payload);
        setAccumulatedData(newData);
        setTotalCount(response?.payload?.total_count || 0);

        setLoading(false);
        setShowDataCountPopup(true);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
      setTimeout(() => {
        setShowDataCountPopup(false);
      }, 3000);
    }
    fetchData();
  }, [
    dispatch,
    currentPage,
    currentStatus,
    currentCategory,
    searchUserText,
    startDateFilter,
    endDateFilter,
    accountNumber,
    pageSize,
    panNumber,
    isIfsc,
  ]);
  const sidebarRef = useRef(null);
  const isFilterActive =
    currentCategory !== "" ||
    panNumber !== "" ||
    accountNumber !== "" ||
    isIfsc !== "" ||
    (startDateFilter &&
      endDateFilter &&
      startDateFilter !== 0 &&
      endDateFilter !== 0 &&
      !isNaN(Number(startDateFilter)) &&
      !isNaN(Number(endDateFilter)));

  const sidebarRefm = useRef(null);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && sidebarRef.current.contains(event.target)) {
    } else if (
      sidebarButtonRef.current &&
      sidebarButtonRef.current.contains(event.target)
    ) {
    } else {
      setIsSidebarOpen(false);
    }

    if (sidebarRefm.current && !sidebarRefm.current.contains(event.target)) {
      setIsSidebarOpenm(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isSidebarOpenm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpenm]);

  const getAccStatus = (item) => {
    setCurrentStatus(item);
  };
  useEffect(() => {
    updateIsFilterApplied();
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    accountNumber,
    panNumber,
    isIfsc,
  ]);
  const updateIsFilterApplied = () => {
    setIsFilterApplied(
      !!currentCategory ||
        !!(startDateFilter && endDateFilter) ||
        !!accountNumber ||
        !!panNumber ||
        !!isIfsc
    );
  };

  const getArrowIcon = (isSidebarOpen, isFilterApplied) => {
    if (isSidebarOpen) {
      return isFilterApplied ? ArrowUp4 : arrowup;
    } else {
      return isFilterApplied ? ArrowDown8 : arrowdown;
    }
  };
  const handleFilterSticker = () => {
    setCurrentCategory("");
    setInitCategory("");
  };
  const handleFilterPanSticker = () => {
    setPanNumber("");
    setSearchPanText("");
  };
  const handleFilterAccountSticker = () => {
    setAccountNumber("");
    setSearchAccountText("");
    setIsIfsc("");
    setSearchIfscText("");
  };
  const handleFilterDateSticker = () => {
    setStartDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    setEndDate("");
  };
  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const filteredData = accumulatedData;
  const handlePaste = (e) => {
    e.preventDefault();
    let pastedText = e.clipboardData.getData("text").trim();

    const isPhoneNumber = /^\+\d/.test(pastedText);

    if (isPhoneNumber) {
      pastedText = pastedText.replace(/^\+\d{1,3}|[^0-9]/g, "");
    } else {
      setSearchUserText(pastedText);
      setSearchTerm(pastedText);
    }

    setSearchUserText(pastedText);
    setSearchTerm(pastedText);
  };
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };
  const handleAccPaste = (e) => {
    e.preventDefault();
    setAccountNumber(e.target.value);
  };
  const handleFilterDateRange = (startDate, endDate) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };

  const handleAccountSearch = (selectedAccNumber) => {
    setAccountNumber(selectedAccNumber);
  };
  const handlePanSearch = (selectedPanNumber) => {
    setAccountNumber(selectedPanNumber);
  };
  const handleIfscSearch = (selectedIfscNumber) => {
    setIsIfsc(selectedIfscNumber);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  const [inputValue, setInputValue] = useState(1);

  useEffect(() => {
    setInputValue(String(currentPage).padStart(String(totalPages).length, "0"));
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
    setInputValue("1");
  }, []);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (value === "") {
        setInputValue("");
        setCurrentPage(1);
      } else if (numericValue > totalPages) {
        setInputValue(
          String(totalPages).padStart(String(totalPages).length, "0")
        );
        setCurrentPage(totalPages);
      } else if (numericValue < 1) {
        setInputValue(1);
        setCurrentPage(1);
      } else {
        setInputValue(value);
        handlePageChange(value);
      }
    }
  };

  const handlePageChange = useCallback(
    debounce((page) => {
      const pageNumber = Number(page);
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(1);
      }
    }, 1000),

    [totalPages]
  );

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };
  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div id="webviewd">
        <>
          {/* {showDataCountPopup && (
            <div className="fixed bottom-8 w-full flex justify-center items-center z-[99999]">
              <DataCountPopup data={mainData} pageSize={pageSize} />
            </div>
          )} */}
          <div
            id="beneScrollContainer"
            className={`bg-custom-appBack flex flex-col h-screen`}
            style={{
              position: "relative",
              overflowY: isSidebarOpen || isPopoverOpen ? "hidden" : "",
            }}
          >
            {isPopoverOpen && <div className="acc_black_div"></div>}
            {isSidebarOpen && <div className="acc_black_div"></div>}

            {
              <div
                style={{
                  zIndex: isSidebarOpen || isPopoverOpen ? "9" : "9999",
                  width: typepop === "popup" ? "96%" : "100%",
                  bottom: typepop === "popup" ? "4%" : "0%",
                  borderRadius: "0",
                  borderBottomRightRadius: typepop === "popup" ? "20px" : "0px",
                  borderBottomLeftRadius: typepop === "popup" ? "20px" : "0px",
                }}
                className="fixed w-full bg-[#244277] h-[50px]"
              >
                <PaginationComponent
                  handleSelect={handleSelect}
                  selectedValue={selectedValue}
                  data={mainData}
                  pageSize={pageSize}
                  dropdownOptions={dropdownOptions}
                  handlePagination={handlePagination}
                  currentPage={currentPage}
                />
              </div>
            }
            <div
              className="top_line_of_div"
              style={{
                display: typepop === "popup" ? "flex" : "none",
                // flexDirection: typepop === "popup" ? "flex" : "none",
              }}
            >
              {" "}
              <div className="top_line_of_div_section">
                <h3>Select Beneficiary Account:</h3>
                <p className="w-[419px] h-[16px] font-open-sans text-[12px] leading-[16.34px] text-[#F9F9F9]">
                  Only active (pending & approved) accounts are enabled for
                  selection.
                </p>
              </div>
              <div
                className="top_cancel_div_pop"
                onClick={() => {
                  if (typepop === "popup") {
                    onClosepop();
                  }
                }}
              >
                <img src={Cancel} alt="cancel" />
              </div>
            </div>
            <div
              className="bene_div_container"
              style={{
                height: showSearchBar ? "245px" : "170px",
                transition: showSearchBar
                  ? "all 0.2s ease-in"
                  : "all 0.2s ease-in",
                top: typepop === "popup" ? "4%" : "0",
                borderTopRightRadius: typepop === "popup" ? "20px" : "0",
                borderTopLeftRadius: typepop === "popup" ? "20px" : "0",
                width: typepop === "popup" ? "96%" : "100%",
              }}
            >
              <div
                className={"bene_div"}
                style={{
                  position: isPopoverOpen ? "relative" : "",
                }}
              >
                <div className="acc_left">
                  <AllStatusTypeButton
                    getAccStatus={getAccStatus}
                    selectedStatus={currentStatus}
                    setPage={setCurrentPage}
                    setIsPopoverOpen={setIsPopoverOpen}
                    isPopoverOpen={isPopoverOpen}
                    isStatusSelected={isStatusSelected}
                    setIsStatusSelected={setIsStatusSelected}
                    setShowSearchBar={setShowSearchBar}
                  />
                </div>
                <div className="acc_right">
                  <>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Search"
                    >
                      <div
                        ref={searchBarRefAcc}
                        className={`beneTxn_viewAll_search_div_acc ${
                          searchUserText !== "" ? "searched" : ""
                        } hover:bg-[#506994]`}
                        style={{
                          opacity: isSidebarOpen ? "0.6" : "1",
                          cursor: isSidebarOpen ? "default" : "pointer",
                        }}
                        onClick={() => {
                          if (!isSidebarOpen) {
                            setShowSearchBar(!showSearchBar);
                            // setIsSidebarOpen(false);
                          }
                        }}
                      >
                        <img
                          className="bene_txn_search-image"
                          src={searchUserText ? search_blue : search_white}
                          alt=""
                        />

                        <img
                          src={getIcon()}
                          alt=""
                          className="w-[13px] h-[13px]"
                        />
                      </div>
                    </Tooltip>
                    {showSearchBar && (
                      <SearchBar
                        showSearchBar={showSearchBar}
                        setShowSearchBar={setShowSearchBar}
                        setCurrentPage={setCurrentPage}
                        searchUserText={searchUserText}
                        setSearchUserText={setSearchUserText}
                        handlePaste={handlePaste}
                        bgColor={"bg-custom-card"}
                        bgHover={"hover:bg-custom-cardHover"}
                        searchBarRefAcc={searchBarRefAcc2}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        typepop={typepop}
                      />
                    )}
                  </>
                  <div
                    className={`beneacc_filter_div ${
                      currentCategory !== "" ||
                      startDateFilter ||
                      endDateFilter ||
                      panNumber ||
                      accountNumber ||
                      isIfsc
                        ? "filtered"
                        : ""
                    }`}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Filters"
                    >
                      <div
                        style={{
                          opacity: showSearchBar ? "0.6" : "1",
                          cursor: showSearchBar ? "default" : "pointer",
                        }}
                        ref={sidebarButtonRef}
                        className="filter-container_acc"
                        onClick={(e) => {
                          if (!showSearchBar) {
                            toggleSidebar();
                          }
                          // e.stopPropagation();
                        }}
                      >
                        <Box className="filter-box">
                          <img
                            src={isFilterApplied ? FilterBlue : Filter}
                            alt=""
                            className="filter-image"
                          />
                        </Box>
                        <Box className="filter-box">
                          <img
                            src={getArrowIcon(isSidebarOpen, isFilterApplied)}
                            alt=""
                            className="filter-image"
                          />
                        </Box>
                      </div>
                    </Tooltip>
                    <div>
                      <BeneAccFilterSidebar
                        sidebarRef={sidebarRef}
                        isOpen={isSidebarOpen}
                        onClose={toggleSidebar}
                        categoryName={currentCategory}
                        sendCategory={handleFilterCategory}
                        setPage={setCurrentPage}
                        setIsSidebarOpen={setIsSidebarOpen}
                        toggleSidebar={toggleSidebar}
                        initCategory={initCategory}
                        setInitCategory={setInitCategory}
                        handleFilterDateRange={handleFilterDateRange}
                        setEndDateFilter={setEndDateFilter}
                        setStartDateFilter={setStartDateFilter}
                        startDateFilter={startDateFilter}
                        endDateFilter={endDateFilter}
                        setAccountNumber={setAccountNumber}
                        accountNumber={accountNumber}
                        sendAccountNumber={handleAccountSearch}
                        sendPanNumber={handlePanSearch}
                        onPaste={handleAccPaste}
                        panNumber={panNumber}
                        setPanNumber={setPanNumber}
                        searchPanText={searchPanText}
                        setSearchPanText={setSearchPanText}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        sendIfscNumber={handleIfscSearch}
                        isIfsc={isIfsc}
                        setIsIfsc={setIsIfsc}
                        searchIfscText={searchIfscText}
                        setSearchIfscText={setSearchIfscText}
                        searchAccountText={searchAccountText}
                        setSearchAccountText={setSearchAccountText}
                        showDatePicker={showDatePicker}
                        setShowDatePicker={setShowDatePicker}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        typepop={typepop}
                        tempStartDate={tempStartDate}
                        tempEndDate={tempEndDate}
                        setTempEndDate={setTempEndDate}
                        setTempStartDate={setTempStartDate}
                      />
                    </div>
                  </div>
                  {/* This Commented Code is For Navbar Pagination Changes Implementated as per New Design */}
                  {/* <div className="bene-txn-viewall-page-select-div_acc">
                    <div
                      style={{
                        height: "34px",
                        width: "70px",
                        padding: "7px 0px 0px 8px",
                        color: "#DADADA",
                        fontSize: "12px",
                      }}
                    >
                      Per page :
                    </div>

                    <div
                      ref={dropdownRef}
                      style={{
                        height: "36px",
                        width: "56px",
                        borderRadius: "0 4px 4px 4px",
                      }}
                      className="statement-type"
                    >
                      <div
                        className="txn-main-btn"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                        style={{
                          borderRadius: isOpen
                            ? "0 4px 4px 4px"
                            : "0 4px 4px 0px",
                          height: isOpen ? "100px" : "34px",
                        }}
                      >
                        <div
                          style={{
                            height: "36px",
                            width: "57px",
                            backgroundColor: isOpen ? "#667CA3" : "#F9F9F9",
                          }}
                          className="txn_high_to_low_select_select"
                        >
                          <span
                            style={{
                              color: isOpen ? "#f9f9f9" : "#244277",
                              fontSize: "12px",
                              paddingLeft: "8px",
                            }}
                          >
                            {selectedValue}
                          </span>
                          <img
                            src={isOpen ? ArrowUp : ArrowDown8}
                            alt="Status Icon"
                            style={{
                              height: "12px",
                              width: "12px",
                            }}
                            className="status_icon-img"
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            width: "56px",
                            top: "36px",
                          }}
                        ></div>
                        <div
                          style={{
                            padding: "5px",
                            top: "30px",
                            width: "56px",
                          }}
                          className="txn_high_to_low_select_scroll"
                        >
                          {dropdownOptions.map((value) => (
                            <div
                              key={value}
                              style={{
                                width: "47px",
                                backgroundColor:
                                  selectedValue == value && "#244277",
                              }}
                              className="txn_select_scroll_com"
                              onClick={() => handleSelect(value)}
                            >
                              {value}
                              <div
                                style={{
                                  width: "56px",
                                  display:
                                    selectedValue == value ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "1px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "12px",
                                    width: "11px",
                                  }}
                                  src={allChecker}
                                  alt="check"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bene-txn-viewall-pagination-containers_acc">
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Prev"
                    >
                      <div
                        onClick={prevPage}
                        disabled={currentPage === 1}
                        style={{
                          height: "34px",
                          width: "32px",
                          padding: "10px 0px 0px 8px",
                          cursor: currentPage === 1 ? "default" : "pointer",
                        }}
                      >
                        <img
                          src={ArrowLeft}
                          alt=""
                          style={{
                            opacity: currentPage === 1 ? 0.5 : 1,
                            cursor: currentPage === 1 ? "default" : "pointer",
                          }}
                          className="w-[11px] h-[14px]"
                        />
                      </div>
                    </Tooltip>
                    <input
                      type="text"
                      value={inputValue}
                      // onBlur={handleBlur}
                      onChange={handleChange}
                      className="bene_txn_viewall_pagination_input"
                      maxLength={String(totalPages).length}
                    />
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Next "
                    >
                      <div
                        onClick={nextPage}
                        disabled={currentPage === totalPages}
                        style={{
                          height: "34px",
                          width: "74px",
                          padding: "8px 0px 0px 10px",
                          display: "flex",
                          gap: "5px",
                          cursor:
                            currentPage === totalPages ? "default" : "pointer",
                        }}
                      >
                        <span
                          className="text-xs text-center text-[#DADADA]"
                          id="id-firstLetter"
                        >
                          of {totalPages}
                        </span>
                        <img
                          src={ArrowRight}
                          style={{
                            opacity: currentPage >= totalPages ? 0.5 : 1,
                            cursor:
                              currentPage >= totalPages ? "default" : "pointer",
                          }}
                          alt=""
                          className="w-[11px] h-[14px] pt-[1px]"
                        />
                      </div>
                    </Tooltip>
                  </div> */}

                  <div
                    className="bene_txn_viewall_toggle_containers_acc"
                    style={{
                      opacity: isSidebarOpen || showSearchBar ? "0.6" : "1",
                      cursor:
                        isSidebarOpen || showSearchBar ? "default" : "pointer",
                    }}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Grid"
                    >
                      <Box
                        onClick={() => {
                          handleToggle(false);
                        }}
                        sx={{
                          backgroundColor: toggle ? "" : "#fff",
                        }}
                        className={`toggle-box`}
                      >
                        <img
                          src={toggle ? GridWhite : GridBlue}
                          alt=""
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="List"
                    >
                      <Box
                        onClick={() => handleToggle(true)}
                        sx={{
                          backgroundColor: toggle ? "#fff" : "",
                        }}
                        className={`toggle-box `}
                      >
                        <img
                          src={toggle ? ListBlue : ListWhite}
                          alt=""
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {(currentCategory !== "" ||
              panNumber !== "" ||
              accountNumber !== "" ||
              isIfsc !== "" ||
              (startDateFilter !== "" &&
                endDateFilter !== "" &&
                startDateFilter !== 0 &&
                endDateFilter !== 0 &&
                !isNaN(Number(startDateFilter)) &&
                !isNaN(Number(endDateFilter)))) && (
              <div
                className="bene_acc_filterdropdown-container-div"
                style={{
                  width: typepop === "popup" ? "96%" : "100%",
                  top: typepop === "popup" ? "4%" : "0",
                  left: typepop === "popup" ? "2%" : "0",
                  marginTop: showSearchBar ? "245px" : "170px",
                }}
              >
                <div className="bene_acc_filterdropdown">
                  <div
                    style={{
                      display: "flex",
                      // width: "45%",
                    }}
                  >
                    <div className="filter--div--left">
                      <p className="pr-2">Filtered by : </p>
                      {currentCategory && (
                        <div className="filter-body px-2 h-[16px]">
                          <div className="img-div">
                            <img src={rentalImg} alt={currentCategory} />
                          </div>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  zIndex: "2147483647 !important",
                                },
                              },
                            }}
                            title={currentCategory}
                          >
                            <p className="px-2 text-xs truncate w-[100px] h-[16px]">
                              {currentCategory}
                            </p>
                          </Tooltip>
                          <div
                            className="close-btn"
                            onClick={handleFilterSticker}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src={closeBtnImg} alt="close-btn" />
                          </div>
                        </div>
                      )}
                      {panNumber && (
                        <div className="filter-body px-2">
                          {/* <div className="img-div">
                        <img src={rentalImg} alt={currentCategory} />
                      </div> */}
                          <p className="px-2">{panNumber}</p>
                          <div
                            className="close-btn"
                            onClick={() => {
                              handleFilterPanSticker();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src={closeBtnImg} alt="close-btn" />
                          </div>
                        </div>
                      )}

                      {accountNumber && (
                        <div className="filter-body px-2">
                          {/* <div className="img-div">
                        <img src={rentalImg} alt={currentCategory} />
                      </div> */}
                          <p className="px-2">
                            {" "}
                            {accountNumber &&
                              formatAccountNumber(accountNumber)}
                          </p>
                          <div
                            className="close-btn"
                            onClick={() => {
                              handleFilterAccountSticker();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <img src={closeBtnImg} alt="close-btn" />
                          </div>
                        </div>
                      )}
                      {startDateFilter !== "" &&
                        endDateFilter !== "" &&
                        startDateFilter !== 0 &&
                        endDateFilter !== 0 &&
                        !isNaN(Number(startDateFilter)) &&
                        !isNaN(Number(endDateFilter)) && (
                          <div className="filter-body px-2">
                            {/* <div className="img-div">
                        <img src={rentalImg} alt={currentCategory} />
                      </div> */}
                            <div className="px-2">
                              {formatDate(new Date(startDateFilter))}
                            </div>
                            <img
                              src={arrowForward}
                              className="bene_acc_viewall_calendar_icon"
                            />
                            <div className="px-2">
                              {formatDate(new Date(endDateFilter))}
                            </div>
                            <div
                              className="close-btn"
                              onClick={() => {
                                handleFilterDateSticker();
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <img src={closeBtnImg} alt="close-btn" />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!toggle ? (
              <div
                ref={scrollContainerRef}
                tabIndex="0"
                id="infinityScrollContainerBeneView"
                className={
                  typepop === "popup"
                    ? "infinityScrollContainerAccBeneView"
                    : ""
                }
                style={{
                  overflow: !isWheelScrolling && "hidden",
                  marginBottom: !isWheelScrolling && "0px",
                  marginTop:
                    isFilterActive && !showSearchBar
                      ? "254px"
                      : isFilterActive && showSearchBar
                      ? "335px"
                      : showSearchBar
                      ? "252px"
                      : "175px",
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "w" ||
                    event.key === "s" ||
                    event.key === "W" ||
                    event.key === "S" ||
                    event.code == "KeyS" ||
                    event.code == "KeyW" ||
                    event.key === "ArrowUp" ||
                    event.key === "ArrowDown"
                  ) {
                    event.preventDefault();

                    return false;
                  }
                }}
              >
                {loading ? (
                  <div
                    className="loader-container grid place-items-center absolute w-full h-full"
                    style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
                  >
                    <img
                      src={dataLoading}
                      alt=""
                      style={{ width: "120px", height: "120px" }}
                    />
                  </div>
                ) : filteredData?.length > 0 ? (
                  <div
                    className={`scroll flex flex-col items-center justify-center w-full ${
                      isSidebarOpen && "overflow-y-hidden"
                    }`}
                    ref={filterRef}
                  >
                    <BeneViewAllCard
                      data={filteredData}
                      lastElementRef={lastElementRef}
                      isSidebarOpen={isSidebarOpen}
                      typepop={typepop}
                      handleButtonClick={handleButtonClick}
                    />
                  </div>
                ) : accumulatedData?.length === 0 &&
                  filteredData?.length === 0 &&
                  !loading ? (
                  <div
                    className="flex flex-col items-center justify-center m-auto w-full h-full"
                    style={{
                      marginTop:
                        typepop === "popup" && !showSearchBar
                          ? "-6vh"
                          : typepop === "popup" && showSearchBar
                          ? "-9vh"
                          : "",
                    }}
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className="bene-acc-no-acc-image"
                      style={{
                        height:
                          typepop === "popup" && showSearchBar
                            ? "220px"
                            : "230px",
                      }}
                    />
                    <p className="bene-acc-no-beneaccount-text">
                      No accounts found.
                    </p>
                  </div>
                ) : (
                  <div
                    className="loader-container grid place-items-center absolute w-full h-full"
                    style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
                  >
                    <img
                      src={dataLoading}
                      alt=""
                      style={{ width: "120px", height: "120px" }}
                    />
                  </div>
                )}

                {(currentStatus === "" || currentStatus === "all") && (
                  <div ref={lastElementRef}></div>
                )}
              </div>
            ) : (
              <div
                ref={scrollContainerRef}
                id="infinityScrollContainerBeneView"
                className={
                  typepop === "popup"
                    ? "infinityScrollContainerAccBeneView"
                    : ""
                }
                onKeyDown={(event) => {
                  if (
                    event.key === "w" ||
                    event.key === "s" ||
                    event.key === "W" ||
                    event.key === "S" ||
                    event.code == "KeyS" ||
                    event.code == "KeyW" ||
                    event.keyCode === "83" ||
                    event.keyCode === "87" ||
                    event.key === "ArrowUp" ||
                    event.key === "ArrowDown"
                  ) {
                    event.stopPropagation();
                    event.preventDefault();

                    return false;
                  }
                  event.stopPropagation();
                }}
                style={{
                  marginTop:
                    isFilterActive && !showSearchBar
                      ? "254px"
                      : isFilterActive && showSearchBar
                      ? "335px"
                      : showSearchBar
                      ? "252px"
                      : "175px",
                }}
              >
                {loading ? (
                  <div
                    className="loader-container grid place-items-center absolute w-full h-full"
                    style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
                  >
                    <img src={dataLoading} alt="" />
                  </div>
                ) : filteredData?.length > 0 ? (
                  <div
                    className={`scroll flex flex-col items-center justify-center w-full ${
                      isSidebarOpen && "overflow-y-hidden"
                    }`}
                    ref={filterRef}
                  >
                    <BeneAccListView
                      data={accumulatedData}
                      isSidebarOpen={isSidebarOpen}
                      page={currentPage}
                      isPopoverOpen={isPopoverOpen}
                      typepop={typepop}
                      handleButtonClick={handleButtonClick}
                      pageSize={pageSize}
                    />
                  </div>
                ) : accumulatedData?.length === 0 &&
                  filteredData?.length === 0 &&
                  !loading ? (
                  <div
                    className="flex flex-col items-center justify-center m-auto w-full h-full"
                    style={{ marginTop: typepop === "popup" ? "-4vh" : "" }}
                  >
                    <img
                      src={noaccount}
                      alt="No accounts found"
                      className="bene-acc-no-acc-image"
                    />
                    <p className="bene-acc-no-beneaccount-text">
                      No accounts found.
                    </p>
                  </div>
                ) : (
                  <div
                    className="loader-container grid place-items-center absolute w-full h-full"
                    style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
                  >
                    <img
                      src={dataLoading}
                      alt=""
                      style={{ width: "120px", height: "120px" }}
                    />
                  </div>
                )}

                {(currentStatus === "" || currentStatus === "all") && (
                  <div ref={lastElementRef}></div>
                )}
              </div>
            )}
          </div>
        </>
      </div>
      <div
        id="mobileviewd"
        style={{
          overflow: "hidden",
          position: "relative",
          overflowY: isSidebarOpenm || isPopoverOpenm ? "hidden" : "",
          height: isSidebarOpenm || isPopoverOpenm ? "100vh" : "100%",
        }}
      >
        {isPopoverOpenm && <div className="acc_black_divm"></div>}
        {isSidebarOpenm && <div className="acc_black_divm"></div>}
        {/* {
          <div
            style={{
              zIndex: isSidebarOpenm ? "0" : "9999",
              width: "100%",
              bottom: "0",
              borderRadius: "0",
              // backgroundColor:
              //   isDistributionPopoverOpen || isSidebarOpen || isPopoverOpen
              //     ? "#252525"
              //     : "#244277",
              opacity: isSidebarOpenm || isPopoverOpenm ? 0.1 : 1,
            }}
            className="fixed w-full bg-[#244277] h-[50px]"
          >
            <PaginationComponent
              handleSelect={handleSelect}
              selectedValue={selectedValue}
              data={mainData}
              pageSize={pageSize}
              dropdownOptions={dropdownOptions}
              handlePagination={handlePagination}
              currentPage={currentPage}
            />
          </div>
        } */}
        <div className="beneacc_approval_heder_containermv">
          <div className="benetxn_approval_heder_containermv"></div>
        </div>
        <div className="beneacc_approval_heder_containermv">
          <div className="txnBeneapprovalheadertextv">
            <div
              className="txnBeneapprovalheaderarrowvcv"
              onClick={() => navigate(-1)}
            >
              <img src={ArrowLeft} alt="" />
            </div>
            <div className="txnBeneapprovalheadertextmv">
              <div className="headingAccountDetail">Beneficiary</div>
              <div className="headertextbottomv">Account</div>
            </div>
            <div className="txnBeneapprovalheaderfilterv">
              <div className="txnimgfmv">
                <div className="txnimgsv">
                  <Tooltip
                    ref={searchBarRefAccm}
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                          zIndex: "2147483647 !important",
                        },
                      },
                    }}
                    title="Search"
                    onClick={() => {
                      // setShowSearchBarm((prev) => !prev);
                      // setIsSidebarOpenm(false);
                      showToast();
                    }}
                  >
                    <img
                      className="bene_txn_search-image"
                      src={searchUserText ? search_blue : search_white}
                      alt=""
                    />
                  </Tooltip>
                  {showSearchBarm && (
                    <SearchBar
                      showSearchBarm={showSearchBarm}
                      setShowSearchBarm={setShowSearchBarm}
                      setCurrentPage={setCurrentPage}
                      searchUserText={searchUserText}
                      setSearchUserText={setSearchUserText}
                      handlePaste={handlePaste}
                      bgColor={"bg-custom-card"}
                      bgHover={"hover:bg-custom-cardHover"}
                      searchBarRefAccm={searchBarRefAccm2}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                    />
                  )}
                </div>
                <div className="txnimgfv">
                  <div
                    ref={sidebarRefm}
                    className={`beneacc_filter_divm ${
                      isFilterApplied ? "filtered" : ""
                    }`}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Filters"
                    >
                      <Box
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSidebarm();
                          setShowSearchBarm(false);
                        }}
                      >
                        <img
                          src={isFilterApplied ? FilterBlue : Filter}
                          alt=""
                          className="w-[16px] h-[16px]"
                        />
                      </Box>
                    </Tooltip>
                    <BeneAccFilterSidebar
                      isOpen={isSidebarOpenm}
                      onClose={toggleSidebarm}
                      categoryName={currentCategory}
                      sendCategory={handleFilterCategory}
                      setPage={setCurrentPage}
                      setIsSidebarOpenm={setIsSidebarOpenm}
                      toggleSidebarm={toggleSidebarm}
                      initCategory={initCategory}
                      setInitCategory={setInitCategory}
                      sendDateRange={handleFilterDateRange}
                      setEndDateFilter={setEndDateFilter}
                      setStartDateFilter={setStartDateFilter}
                      setAccountNumber={setAccountNumber}
                      accountNumber={accountNumber}
                      sendAccountNumber={handleAccountSearch}
                      sendPanNumber={handlePanSearch}
                      onPaste={handleAccPaste}
                      panNumber={panNumber}
                      setPanNumber={setPanNumber}
                      searchPanText={searchPanText}
                      setSearchPanText={setSearchPanText}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      sendIfscNumber={handleIfscSearch}
                      isIfsc={isIfsc}
                      setIsIfsc={setIsIfsc}
                      searchIfscText={searchIfscText}
                      setSearchIfscText={setSearchIfscText}
                      searchAccountText={searchAccountText}
                      setSearchAccountText={setSearchAccountText}
                      showDatePicker={showDatePicker}
                      setShowDatePicker={setShowDatePicker}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      startDateFilter={startDateFilter}
                      endDateFilter={endDateFilter}
                      tempStartDate={tempStartDate}
                      tempEndDate={tempEndDate}
                      setTempEndDate={setTempEndDate}
                      setTempStartDate={setTempStartDate}
                    />
                  </div>
                </div>
                <div className="txnimgmv">
                  {toggle ? (
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="List"
                    >
                      <Box
                        onClick={() => {
                          dispatch(setToggle(false));
                          setCurrentPage(1);
                          setSearchUserText("");
                        }}
                        sx={{
                          backgroundColor: toggle ? "#fff" : "",
                        }}
                        className="toggle-box"
                      >
                        <img
                          src={ListBlue}
                          alt="List"
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title="Grid"
                    >
                      <Box
                        onClick={() => {
                          dispatch(setToggle(true));
                          setCurrentPage(1);
                          setSearchUserText("");
                        }}
                        sx={{
                          backgroundColor: toggle ? "" : "#fff",
                        }}
                        className="toggle-box"
                      >
                        <img
                          src={GridBlue}
                          alt="Grid"
                          className="toggle-images"
                        />
                      </Box>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="accBeneapprovalheadertextvcv"
          style={{
            borderBottom: isPopoverOpenm ? "none" : "3px solid #1d3a6d",
          }}
        >
          <div
            className="beneaccViewAllParentdivm"
            style={{
              zIndex: isPopoverOpenm ? 99999 : 0,
            }}
          >
            <div className="beneTxnViewAllchild1">
              <AllStatusTypeButtonMob
                getAccStatus={getAccStatus}
                selectedStatus={currentStatus}
                setPage={setCurrentPage}
                setIsPopoverOpenm={setIsPopoverOpenm}
                isPopoverOpenm={isPopoverOpenm}
                isStatusSelected={isStatusSelected}
                setIsStatusSelected={setIsStatusSelected}
                setShowSearchBar={setShowSearchBar}
              />
            </div>
          </div>
          <div className="beneTxnViewallAmountSectionm">
            <div className="beneTxnViewallTotalAmount"></div>
          </div>
        </div>
        {(currentCategory !== "" ||
          panNumber !== "" ||
          accountNumber !== "" ||
          isIfsc !== "" ||
          (startDateFilter !== "" && endDateFilter !== "")) &&
          showSearchBarm === false && (
            <div
              className="bene_acc_filterdropdown-container-divm"
              style={{
                zIndex: isPopoverOpenm ? 0 : 4,
              }}
            >
              <div className="bene_acc_filterdropdown">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    overflow: "auto",
                    padding: "0px 20px",
                  }}
                >
                  <div className="filter--div--left">
                    <p className="pr-2" style={{ width: "90px" }}>
                      Filtered by :
                    </p>
                    {currentCategory && (
                      <div className="filter-body flex items-center px-2 h-[16px]">
                        <div className="img-div">
                          <img src={rentalImg} alt={currentCategory} />
                        </div>
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                zIndex: "2147483647 !important",
                              },
                            },
                          }}
                          title={currentCategory}
                        >
                          <p className="px-2 text-xs truncate w-[100px] h-[16px] text-center">
                            {currentCategory}
                          </p>
                        </Tooltip>
                        <div
                          className="close-btn"
                          onClick={handleFilterSticker}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={closeBtnImg} alt="close-btn" />
                        </div>
                      </div>
                    )}
                    {panNumber && (
                      <div className="filter-body flex items-center justify-center px-2">
                        <p className="px-2 text-center">{panNumber}</p>
                        <div
                          className="close-btn"
                          onClick={() => {
                            handleFilterPanSticker();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={closeBtnImg} alt="close-btn" />
                        </div>
                      </div>
                    )}
                    {accountNumber && (
                      <div className="filter-body flex items-center justify-center px-2 w-[240px]">
                        <p className="px-2 text-center">
                          {accountNumber && formatAccountNumber(accountNumber)}
                        </p>
                        <div
                          className="close-btn"
                          onClick={() => {
                            handleFilterAccountSticker();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img src={closeBtnImg} alt="close-btn" />
                        </div>
                      </div>
                    )}
                    {startDateFilter !== "" &&
                      endDateFilter !== "" &&
                      startDateFilter !== 0 &&
                      endDateFilter !== 0 &&
                      !isNaN(Number(startDateFilter)) &&
                      !isNaN(Number(endDateFilter)) && (
                        <div className="filter-body flex items-center justify-center px-2 w-[295px]">
                          <div className="px-2 text-center">
                            {formatDate(new Date(startDateFilter))}
                          </div>
                          <img
                            src={arrowForward}
                            className="bene_acc_viewall_calendar_icon"
                          />
                          <div className="px-2 text-center">
                            {formatDate(new Date(endDateFilter))}
                          </div>
                          <div
                            className="close-btn"
                            onClick={() => {
                              handleFilterDateSticker();
                            }}
                            style={{ cursor: "pointer", marginRight: "0.5rem" }}
                          >
                            <img src={closeBtnImg} alt="close-btn" />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}
        {!toggle ? (
          <div
            id={`infinityScrollContainerBeneView${
              accumulatedData?.length === 0 && filteredData?.length === 0
                ? "_beneemptyDataClass"
                : ""
            }`}
            className={
              typepop === "popup" ? "infinityScrollContainerAccBeneView" : ""
            }
            style={{
              marginTop:
                currentCategory !== "" ||
                panNumber !== "" ||
                accountNumber !== "" ||
                isIfsc !== "" ||
                (startDateFilter !== "" && endDateFilter !== "")
                  ? "279px"
                  : showSearchBar
                  ? "267px"
                  : "198px",
              height:
                currentCategory !== "" ||
                panNumber !== "" ||
                accountNumber !== "" ||
                isIfsc !== "" ||
                (startDateFilter !== "" && endDateFilter !== "")
                  ? "55vh"
                  : "",
            }}
          >
            {loading ? (
              <div
                className="loader-container grid place-items-center absolute w-full h-full"
                style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
              >
                <img
                  src={dataLoading}
                  alt=""
                  style={{ width: "120px", height: "120px" }}
                />
              </div>
            ) : filteredData?.length > 0 ? (
              <div
                className={`scroll flex flex-col items-center justify-center w-full ${
                  isSidebarOpen && "overflow-y-hidden"
                }`}
                ref={filterRef}
              >
                <BeneViewAllCard
                  data={filteredData}
                  lastElementRef={lastElementRef}
                  isSidebarOpen={isSidebarOpen}
                  typepop={typepop}
                  handleButtonClick={handleButtonClick}
                />
              </div>
            ) : accumulatedData?.length === 0 &&
              filteredData?.length === 0 &&
              !loading ? (
              <div
                className="flex flex-col items-center w-full h-full justify-center"
                style={{ marginTop: typepop === "popup" ? "-4vh" : "" }}
              >
                <img
                  src={noaccount}
                  alt="No accounts found"
                  className="bene-acc-no-acc-image"
                />
                <p className="bene-acc-no-beneaccount-text">
                  No accounts found.
                </p>
              </div>
            ) : (
              <div
                className="loader-container grid place-items-center absolute w-full h-full"
                style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
              >
                <img
                  src={dataLoading}
                  alt=""
                  style={{ width: "120px", height: "120px" }}
                />
              </div>
            )}

            {(currentStatus === "" || currentStatus === "all") && (
              <div ref={lastElementRef}></div>
            )}
          </div>
        ) : (
          <div
            id={`infinityScrollContainerBeneView${
              accumulatedData?.length === 0 && filteredData?.length === 0
                ? "_beneemptyDataClass"
                : ""
            }`}
            className={
              typepop === "popup" ? "infinityScrollContainerAccBeneView" : ""
            }
            style={{
              marginTop:
                currentCategory !== "" ||
                panNumber !== "" ||
                accountNumber !== "" ||
                isIfsc !== "" ||
                (startDateFilter !== "" && endDateFilter !== "")
                  ? "285px"
                  : showSearchBar
                  ? "268px"
                  : "198px",
              height:
                currentCategory !== "" ||
                panNumber !== "" ||
                accountNumber !== "" ||
                isIfsc !== "" ||
                (startDateFilter !== "" && endDateFilter !== "")
                  ? "57vh"
                  : "57vh",
              overflowX: "hidden",
            }}
          >
            {loading ? (
              <div
                className="loader-container grid place-items-center absolute w-full h-full"
                style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
              >
                <img
                  src={dataLoading}
                  alt=""
                  style={{ width: "120px", height: "120px" }}
                />
              </div>
            ) : filteredData?.length > 0 ? (
              <div
                className={`scroll flex flex-col items-center justify-center w-full ${
                  isSidebarOpen && "overflow-y-hidden"
                }`}
                ref={filterRef}
              >
                <BeneAccListView
                  data={accumulatedData}
                  isSidebarOpen={isSidebarOpen}
                  page={currentPage}
                  isPopoverOpen={isPopoverOpen}
                />
              </div>
            ) : accumulatedData?.length === 0 &&
              filteredData?.length === 0 &&
              !loading ? (
              <div
                className="flex flex-col items-center w-full h-full justify-center"
                style={{ marginTop: typepop === "popup" ? "-4vh" : "" }}
              >
                <img
                  src={noaccount}
                  alt="No accounts found"
                  className="bene-acc-no-acc-image"
                />
                <p className="bene-acc-no-beneaccount-text">
                  No accounts found.
                </p>
              </div>
            ) : (
              <div
                className="loader-container grid place-items-center absolute w-full h-full"
                style={{ top: typepop === "popup" ? "-8vh" : "56px" }}
              >
                <img
                  src={dataLoading}
                  alt=""
                  style={{ width: "120px", height: "120px" }}
                />
              </div>
            )}

            {(currentStatus === "" || currentStatus === "all") && (
              <div ref={lastElementRef}></div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BeneAccViewAll;
