import React, { useState, useEffect } from "react";
import "./index.css";
import Decline from "../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import Approved from "../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import close from "../../../assets/CloseIcons/close_with_white_circle.svg";
import RightArrow from "../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import WhiteReload from "../../../assets/RefreshIcons/reload-refresh_white.svg";
import { viewAccountDetail } from "../../../redux/features/verifyPAN/verifyPAN";
import { beneTxnAccountDetail } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import addStatus from "../../../assets/InfoIcons/add_status.svg";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FormatAmount from "../../_utils/FormatAmount/FormatAmount";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import RefreshBalanceToast from "../../_utils/RefreshBalanceToast";
import {
  getCreateTransactionBalance,
  resetOwnerBalance,
} from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import BalanceLoader from "../../_utils/BalanceLoader";
import { Tooltip, tooltipClasses } from "@mui/material";
import { beneTransactionMultiApproval } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeclinedPopup from "../../../containers/Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import BeneTxnSideDrawer from "../../SideDrawer";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import AnimationUpAndDown from "../../BeneTransaction/OwnerPopup/AnimationUpAndDown";
import { checkUsertoken } from "../../../redux/features/login/login";
import { resetBeneTxnMultiDeniedApprovalData } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import BeneTxnDetailPagePopup from "../../BeneTransaction/BeneTxnDetailPopup";
const BeneficiaryMultiApprovalTxnDetails = ({ state }) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTxnDetailPop, setShowTxnDetailPop] = useState(false);
  const [txnId, setTxnId] = useState(null);
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState([]);
  const [datas, setDatas] = useState([]);
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [refreshId, setRefreshId] = useState("");
  const [refreshAnimation, setrefreshAnimation] = useState("");
  const [PrevBalance, setPrevBalance] = useState(null);
  const [transactionFilterData, setTransactionFilterData] = useState([]);
  const [transactionFilterID, setTransactionFilterID] = useState([]);
  const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);
  const [isApprovalError, setIsApprovalError] = useState(false);
  const { showReloginModal } = useSelector((state) => state.login);

  const TimestampConverter = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return formattedDate;
  };

  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setTransactionFilterData(
        storedSelectedApproval?.selectedSendTransactions
      );
      setTransactionFilterID(storedSelectedApproval.selectedTransactions);
    } else {
      setTransactionFilterData([]);
      setTransactionFilterID([]);
    }
  }, []);
  const openBeneTxnDetailModal = () => {
    setShowTxnDetailPop(true);
  };
  const closeBeneTxnDetailModal = () => {
    setShowTxnDetailPop(false);
  };
  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
    createTransactionBankRefreshData,
  } = useSelector((state) => state.ownerAllVerification);

  const handleRefresh = (e, id, txnId, prevBalance) => {
    e.stopPropagation();
    setPrevBalance(prevBalance);
    setrefreshAnimation(id);
    setRefreshId(txnId);
    dispatch(checkUsertoken());
    dispatch(getCreateTransactionBalance(id));
  };

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      setTimeout(() => {
        RefreshBalanceToast();
      }, 200);
    } else if (createTransactionBankRefreshData?.status) {
      toast.dismiss();
      setTimeout(() => {
        toast.success("Balance refreshed successfully", toast_position);
      }, 500);
      // setTimeout(() => {
      //   setRefreshId(null);
      // }, 5000);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      setTimeout(() => {
        toast.error(createTransactionBankRefreshError, toast_position);
      }, 100);
    }
    if (createTransactionBankRefreshData) {
      let data = transactionFilterData.map((val) => {
        if (
          val.owner_account_id ===
          createTransactionBankRefreshData?.owner_accounts[0]?.owner_account_id
          // &&
          // val?.txn_id === refreshId
        ) {
          return {
            ...val,
            owner_account_balance:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance,
            owner_account_balance_last_fetched_at_in_words:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
          };
        }
        return val;
      });

      setTransactionFilterData(data);
      const selectedData = {
        selectedSendTransactions: data,
        selectedTransactions: transactionFilterID,
      };

      sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
    }
    return () => {
      dispatch(checkUsertoken());
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);
  let total = transactionFilterData?.reduce((acc, obj) => {
    return acc + parseInt(obj?.amount);
  }, 0);

  const handleOpenPopup = (id) => {
    setShowPopup(true);
    // SetdeniedId(id);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const updateAllAccounts = (updatedAccounts) => {
    setAllBeneTxnAccounts(updatedAccounts);
    // SetstatustxnApproval("TxnReQuiredApproval");
  };
  const SideDrawerOpen = async (status, id, txnId) => {
    SetDrawerStatus(status);

    setisDrawerOpen(!isDrawerOpen);
    dispatch(checkUsertoken());
    await dispatch(viewAccountDetail({ id }));
    await dispatch(beneTxnAccountDetail(txnId));
  };

  const handleTxnApproval = async (id) => {
    setLoading(true);

    const imageStyle = {
      width: "40px",
      height: "40px",
      opacity: 1,
    };

    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#FFFFFF",
      opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };
    toast(
      <div style={divStyle}>
        <img src={whiteLoader} alt="" style={imageStyle} />
        <p style={paragraphStyle}>Approving transaction</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: "252px",
          height: "84px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );

    if (transactionFilterID.length > 0) {
      dispatch(checkUsertoken());
      dispatch(resetBeneTxnMultiDeniedApprovalData());
      const data = await dispatch(beneTransactionMultiApproval(id));

      setTimeout(() => {
        setLoading(false);
        toast.dismiss();
        if (data?.payload?.status === 500) {
          setTimeout(() => {
            toast.error(data?.payload?.error, toast_position);
          }, 1000);
        }
        if (data?.payload?.errors?.user_unauthorized?.[0]) {
          setTimeout(() => {
            // toast.error(
            //   data?.payload?.errors?.user_unauthorized?.[0],
            //   toast_position
            // );
            Navigate("/feature_not_assigned", {
              state: {
                unavailableService: "VBeneficiaryTxn",
              },
            });
          }, 1000);
        } else if (data.payload.status === true) {
          const selectedData = {
            selectedSendTransactions: data?.payload?.transactions,
            selectedTransactions: transactionFilterID,
          };

          sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
          Navigate(
            "/transaction/Beneficiary_Transaction/multi_transaction_approval/success"
          );
        } else if (data.payload.status === false) {
          // Navigate(
          //   "/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
          // );
          setIsApprovalError(true);
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (isApprovalError && showReloginModal === false) {
      Navigate(
        "/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
      );
    }
  }, [showReloginModal, isApprovalError]);

  useEffect(() => {
    const hasInsufficientFunds = transactionFilterData.some(
      (val) => parseInt(val.amount) > parseFloat(val.owner_account_balance)
    );

    setIsInsufficientFunds(hasInsufficientFunds);
  }, [transactionFilterData]);

  return (
    <>
      <div
        className="beneficiaryMultiApprovalTxnDetails_main"
        style={{
          overflow: showPopup ? "hidden" : "",
          height: showPopup ? "100vh" : "",
        }}
      >
        {" "}
        {loading && (
          <div
            className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#252525] bg-opacity-80
"
          >
            <div className="flex justify-center items-center w-[204px] h-[90px]   opacity-100">
              <img
                className="w-[120px] h-[120px]"
                src={xpentraloader}
                alt="Loading..."
              />
            </div>
          </div>
        )}
        <div className="beneficiaryMultiApprovalTxnDetails_top">
          <div className="beneficiaryMultiApprovalTxnDetails_top_1">
            {showTxnDetailPop && (
              <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                <BeneTxnDetailPagePopup
                  closeBeneTxnDetailModal={closeBeneTxnDetailModal}
                  bene_txn_id={txnId}
                  type={"bene_txn_detail_modal"}
                />
              </div>
            )}

            {showPopup && (
              <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
                <DeclinedPopup
                  onClose={handleClosePopup}
                  accountId={transactionFilterID}
                  allAccounts={transactionFilterData}
                  updateAllAccounts={updateAllAccounts}
                  status={"Transaction"}
                  sideDrawer={"multiApproval"}
                />
              </div>
            )}
            <Button
              onClick={() => Navigate(-1)}
              className="beneficiaryMultiApprovalTxnDetails_top_1_div1 beneficiaryMultiApprovalTxnDetails_top_1_com"
            >
              <img src={close} />
              <p>Cancel</p>
            </Button>
            <div className="beneficiaryMultiApprovalTxnDetails_top_1_div2">
              <p className="beneficiaryMultiApprovalTxnDetails_top_1_div2_p1">
                ₹ {<FormatAmount price={total} />}
              </p>
              <p className="beneficiaryMultiApprovalTxnDetails_top_1_div2_p2">
                Transaction Amount
              </p>
            </div>
            <div className="w-[204px] flex h-[90px] bg-[#506994]">
              {loading && (
                <div className="flex justify-center items-center w-[204px] h-[90px]   opacity-100">
                  <img
                    className="w-[40px] h-[40px]"
                    src={xpentraloader}
                    alt="Loading..."
                  />
                </div>
              )}
              {!loading && (
                <>
                  <Button
                    onClick={() => handleOpenPopup(transactionFilterID)}
                    className="beneficiaryMultiApprovalTxnDetails_top_1_div3 beneficiaryMultiApprovalTxnDetails_top_1_com"
                  >
                    <img src={Decline} alt="Loading..." />
                    <p>Reject</p>
                  </Button>
                  <Button
                    className="beneficiaryMultiApprovalTxnDetails_top_1_div4 beneficiaryMultiApprovalTxnDetails_top_1_com"
                    onClick={() => handleTxnApproval(transactionFilterID)}
                  >
                    <img src={Approved} alt="Loading..." />
                    <p>Approve</p>
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="beneficiaryMultiApprovalTxnDetails_top_2">
            <div>
              <p className="beneficiaryMultiApprovalTxnDetails_top_2_p1">
                Post Approval Process
              </p>
              <p className="beneficiaryMultiApprovalTxnDetails_top_2_p2">
                Once approved, your transactions will go through a quick
                verification process. Post-verification, it will be queued and
                auto settled using selected payment method. In case of
                insufficient funds, the queued transactions will settle
                automatically as soon as your balance is refilled.
              </p>
            </div>
            {isInsufficientFunds && (
              <div className="w-[306px] h-full bg-[#667CA3] flex  items-center gap-5">
                <img
                  src={addStatus}
                  alt=""
                  width={30}
                  height={30}
                  className="ml-[20px]"
                />
                <p className="w-[218px] h-[68px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                  Upon approval, distribution will not be initiated for one or
                  more transactions due to insufficient balance in the
                  associated source account.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="beneficiaryMultiApprovalTxnDetails_bottom">
          <h4>Selected Transaction ({transactionFilterData?.length})</h4>
          <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_main">
            {transactionFilterData?.map((val, i) => {
              return (
                <div
                  key={i}
                  className="beneficiaryMultiApprovalTxnDetails_bottom_data"
                >
                  <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_top min-w-0">
                    <h4 className=" whitespace-nowrap">
                      ₹ {<FormatAmount price={val?.amount} />}
                    </h4>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={val?.beneficiary_account_name
                        .toLowerCase()
                        .split(" ")
                        .map(function (word) {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        })
                        .join(" ")}
                    >
                      <h5 className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                        {val?.beneficiary_account_name
                          .toLowerCase()
                          .split(" ")
                          .map(function (word) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")}
                      </h5>
                    </Tooltip>
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_date">
                      <p>{TimestampConverter(val?.created_on)}</p>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setSideDrawerDisabled(false);
                          setDatas(val);
                          openBeneTxnDetailModal();
                          setTxnId(val.txn_id);
                        }}
                        className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_details"
                      >
                        <h6>More details</h6>
                        <img src={RightArrow} />
                      </Button>
                    </div>
                  </div>
                  <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom">
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1">
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_left">
                        <h4>
                          {" "}
                          {val?.owner_account_bank_acc_no
                            .toString()
                            .substring(0, 4)}{" "}
                          ••••{" "}
                          {val?.owner_account_bank_acc_no
                            .toString()
                            .substring(
                              val?.owner_account_bank_acc_no?.length - 4
                            )}
                        </h4>
                        <h5>Source account</h5>
                      </div>
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_1_img_div">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(val?.owner_account_ifsc_code))}
                          alt="bank"
                        />
                      </div>
                    </div>
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_left">
                        <div className="Owner_Balance">
                          <div className="name1">
                            <h4
                              style={{
                                color:
                                  val?.owner_account_balance >= val.amount
                                    ? "#4DDD37"
                                    : "#FF5860",
                              }}
                            >
                              {" "}
                              ₹{" "}
                              {
                                <FormatAmount
                                  price={val?.owner_account_balance}
                                />
                              }
                            </h4>
                          </div>
                          <div className="name2 ">
                            <p>
                              <AnimationUpAndDown
                                loader={createTransactionBankRefreshLoading}
                                currentId={val?.owner_account_id}
                                currentBalance={val?.owner_account_balance}
                                previousId={refreshAnimation}
                                previousBalance={PrevBalance}
                                refreshType={"single"}
                                classType={"MultiApproval"}
                              />
                            </p>
                          </div>
                        </div>

                        <h5 className="whitespace-nowrap  w-[140px]  overflow-ellipsis overflow-hidden ">
                          Balance -{" "}
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={
                              val?.owner_account_balance_last_fetched_at_in_words
                            }
                          >
                            {
                              val?.owner_account_balance_last_fetched_at_in_words
                            }
                          </Tooltip>
                        </h5>
                      </div>

                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title="Refresh Balance"
                      >
                        <button
                          className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2_button"
                          onClick={(e) =>
                            handleRefresh(
                              e,
                              val?.owner_account_id,
                              val?.txn_id,
                              val?.owner_account_balance
                            )
                          }
                        >
                          {createTransactionBankRefreshLoading &&
                          refreshId === val?.txn_id ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              type={"createTxn"}
                            />
                          ) : !createTransactionBankRefreshLoading &&
                            refreshId === val?.txn_id ? (
                            <BalanceLoader
                              loader={createTransactionBankRefreshLoading}
                              setAccId={setRefreshId}
                              type={"createTxn"}
                            />
                          ) : (
                            <>
                              {" "}
                              <img src={WhiteReload} />
                              <h6>Refresh</h6>
                            </>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryMultiApprovalTxnDetails;
