import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  beneVerifyThisGST,
  beneWaitingApproved,
  beneVerifyThisIFSC,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import "./index.css";
import arrowRight from "../../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import skipping_verification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import { Box, Tooltip, tooltipClasses, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import editImage from "../../../../assets/EditIcons/edit_with_white_circle.svg";
import declineImage from "../../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import associated_accounts_found from "../../../../assets/GeneralIcons/associated_accounts_found.svg";
import checkmark from "../../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import archieveImage from "../../../../assets/ArchieveUnArchieveIcons/archive.svg";
import transactionHistory from "../../../../assets/GeneralIcons/transaction_history.svg";
import payBene from "../../../../assets/Beneficiary Icons/paybene.png";
import {
  verifyThisIFSC,
  viewAccountDetail,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import unarchiveImage from "../../../../assets/ArchieveUnArchieveIcons/unarchive.svg";
import BeneEditModal from "../../../../components/BeneEditAccount/BeneEditModal";
import formatDateViewAll from "../../../../utils/formatDateViewAll";
import TransactionHistory from "../../../../components/TransactionHistoryModal";
import BeneIcon from "../../../../assets/CreateTransactionIcons/Beneficiary.png";
import DeclinedPopup from "../BeneAcountApproval/DeclinedPopup";
import BeneDetailModal from "../../../../components/Accounts/BeneDetailModal";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { beneLookUp } from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneBankLookUp } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import BeneViewAssociatedAccount from "../../../../components/BeneViewAssociatedAccount";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import RejectingTransactionPopup from "../BeneAcountApproval/RejectingTransactionPopup/RejectingTransactionPopup";
import arrowleft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import Category from "../../../../assets/Beneficiary Icons/categoryIcon.svg";
import closeIcon from "../../../../assets/General Icons/close.svg";
import { Toast } from "react-bootstrap";

const BeneAccDetails = (payload) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.state?.from ? location?.state?.from : "";
  const [showCategories, setShowCategories] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unachivedOpenPop, setUnachivedOpenPop] = useState(false);
  const [achivedOpenPop, setAchivedOpenPop] = useState(false);
  const [ViewAccountDetails, SetViewAccountDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChecker, setIsChecker] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openTransHistory, setOpenTransHistory] = useState(false);
  const [openAssociatedAccounts, setOpenAssociatedAccounts] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [nameArchive, setNameArchive] = useState("");
  const [opencatgories, setopencatgories] = useState(false);
  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    name: "",
    bank_name: "",
    ifsc_code: "",
    bank_acc_no: "",
    category: "",
    id: "",
    pan: "",
  });
  const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);
  const [showRejectAllTxnReson, setShowRejectAllTxnReson] = useState(false);
  const [preventMultiEnter, setPreventMultiEnter] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const popupRef = useRef(null);

  const handViewAccount = () => {
    setUnachivedOpenPop(false);
  };
  const handleModalClose = () => {
    setIsEditModalOpen(false);
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const { viewAccountDetailData, viewAccountDetailError } = useSelector(
    (state) => state.verify
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        dispatch(checkUsertoken());
        let response;
        if (payload?.type === "bene_account_detail_modal") {
          response = await dispatch(
            viewAccountDetail({ id: payload?.bene_account_id })
          );
        } else {
          response = await dispatch(viewAccountDetail({ id }));
        }
        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
      } catch (error) {
        console.error("Failed to load the account details", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [payload?.bene_account_id, id, dispatch]);

  useEffect(() => {
    if (viewAccountDetailData) {
      SetViewAccountDetails(viewAccountDetailData?.beneficiary_accounts);
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    let val =
      services?.find(
        (service) =>
          service === "BAAccount" ||
          service === "BUAccount" ||
          service === "Admin"
      ) === undefined;
    setIsChecker(val);
  }, [dispatch, services]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const {
        pan_legal_name,
        bank_name,
        bank_acc_no,
        category,
        ifsc_code,
        id,
        pan_no,
      } = viewAccountDetailData.beneficiary_accounts[0];
      setCurrentAccountDetails({
        name: pan_legal_name,
        bank_name,
        bank_acc_no,
        category,
        ifsc_code,
        id,
        pan: pan_no,
      });
    }
  }, [viewAccountDetailData]);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       setRejectTxnPopup(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [popupRef]);
  const handleModalOpen = (id) => {
    setIsEditModalOpen(id);
    setSelectedId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setUnachivedOpenPop(false);
    setAchivedOpenPop(false);
  };

  const handleUnarchive = () => {
    setUnachivedOpenPop(true);
    if (viewAccountDetailData?.beneficiary_accounts[0]?.archived === "N") {
      setUnachivedOpenPop(false);
    } else {
      setUnachivedOpenPop(true);
    }
  };
  const handleArchive = () => {
    if (viewAccountDetailData?.beneficiary_accounts[0]?.archived === "N") {
      setAchivedOpenPop(true);
    } else {
      setAchivedOpenPop(false);
    }
  };

  const toggleCategories = () => {
    setShowCategories(true);
    setShowContactDetails(false);
  };
  const toggleContactDetails = () => {
    setShowContactDetails(true);
    setShowCategories(false);
  };

  const openTransactionHistory = () => {
    setOpenTransHistory(true);
  };

  const closeTransactionHistory = () => {
    setOpenTransHistory(false);
  };

  const openAssociatedAccountsFound = () => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: currentAccountDetails.pan }));
    dispatch(
      beneBankLookUp({
        ifsc: currentAccountDetails.ifsc_code,
        bank_account_no: currentAccountDetails.bank_acc_no,
      })
    );
    setOpenAssociatedAccounts(true);
  };

  const closeAssociatedAccountsFound = () => {
    setOpenAssociatedAccounts(false);
  };

  const createTransaction = () => {
    if (
      services?.find(
        (service) => service === "BUAccount" || service === "Admin"
      ) !== undefined &&
      currentAccountDetails.category.length > 0
    ) {
      navigate("/transaction/beneficiary_transaction/create", {
        state: currentAccountDetails,
      });
    } else if (
      services?.find(
        (service) => service === "BUAccount" || service === "Admin"
      ) !== undefined &&
      currentAccountDetails.category.length <= 0
    ) {
      toast.error("Category can't be blank", toast_position);
    }
  };

  const handleGSTDetails = async (gstin) => {
    if (gstin?.length === 15) {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisGST({ search_string: gstin })
      );
      if (response?.payload?.status === true) {
        navigate(`/accounts/beneficiary_account/details/GST_details/${gstin}`);
      } else if (response?.payload?.status === false) {
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };

  const handleApprovalDetails = async (id) => {
    let response;
    dispatch(checkUsertoken());
    if (payload?.type === "bene_account_detail_modal") {
      response = await dispatch(beneWaitingApproved(payload?.bene_account_id));
    } else {
      response = await dispatch(beneWaitingApproved(id));
    }

    if (response?.payload?.errors?.user_unauthorized?.length > 0) {
      navigate("/feature_not_assigned", {
        state: {
          unavailableService: "BAAccount",
        },
      });
      return;
    }
    if (
      response?.payload?.status === false ||
      response?.payload === undefined
    ) {
      let error_msg = "";
      if (response?.payload === undefined) {
        error_msg = "An error occured while taking action on this account";
      } else if (response?.payload?.errors?.beneficiary_account?.[0]) {
        error_msg = response.payload.errors.beneficiary_account[0];
      } else if (response?.payload?.errors?.bank_account_number?.[0]) {
        error_msg = response.payload.errors.bank_account_number[0];
      } else {
        error_msg = "An unknown error occurred.";
      }
      if (error_msg === "Beneficiary account already approved") {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (
              item.id === (payload?.type === "bene_account_detail_modal")
                ? payload?.bene_account_id
                : id
            ) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "approved" : "pending",
              };
            }
            return item;
          }
        );
        SetViewAccountDetails(updatedData);
        dispatch(checkUsertoken());
        if (payload?.type === "bene_account_detail_modal") {
          await dispatch(viewAccountDetail({ id: payload?.bene_account_id }));
        } else {
          await dispatch(viewAccountDetail({ id }));
        }
      } else {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (
              item.id === (payload?.type === "bene_account_detail_modal")
                ? payload?.bene_account_id
                : id
            ) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "pending" : "",
                error_msg,
              };
            }
            return item;
          }
        );

        SetViewAccountDetails(updatedData);
        dispatch(checkUsertoken());
      }
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
        (item) => {
          if (
            item.id === (payload?.type === "bene_account_detail_modal")
              ? payload?.bene_account_id
              : id
          ) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "approved" : "pending",
            };
          }
          return item;
        }
      );
      SetViewAccountDetails(updatedData);
      dispatch(checkUsertoken());
      if (payload?.type === "bene_account_detail_modal") {
        await dispatch(viewAccountDetail({ id: payload?.bene_account_id }));
      } else {
        await dispatch(viewAccountDetail({ id }));
      }

      toast.success(" Beneficiary Account approved successfully ", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      if (payload?.type === "bene_account_detail_modal") {
        setTimeout(() => {
          payload?.closeBeneAccountDetailModal();
          window.location.reload();
        }, 5000);
      }
      if (pathName) {
        setTimeout(() => {
          navigate(pathName);
        }, 5000);
      }
    }
  };

  const handleDeclinedDetials = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };
  const handleClosePopup = () => {
    setRejectTxnPopup(false);
    setShowPopup(false);
    setShowRejectAllTxnReson(false);
    if (payload?.type != "bene_account_detail_modal") {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  const updateAllAccounts = (updatedAccounts) => {
    SetViewAccountDetails(updatedAccounts);
  };

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    bankLogo,
    category,
    panNo,
    beneAccountId
  ) => {
    setAccountId(beneAccountId);
  };

  useEffect(() => {
    let accountData = [];
    if (viewAccountDetailData) {
      accountData = viewAccountDetailData?.beneficiary_accounts[0].logs;
    }
    if (
      accountData &&
      viewAccountDetailData?.beneficiary_accounts[0].archived === "Y"
    ) {
      setNameArchive(
        [...accountData]
          .filter((fil) => {
            if (fil.action == "Archived") {
              return fil;
            }
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]
          .user_full_name
      );
    }
  }, [ViewAccountDetails]);
  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  useEffect(() => {
    if (beneLookData || bankData) {
      if (beneLookData?.total_count > 1 || bankData?.total_count > 1) {
        setIsDisabled(true);
      }
    }
  }, [beneLookData, bankData]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: currentAccountDetails.pan }));
    dispatch(
      beneBankLookUp({
        ifsc: currentAccountDetails.ifsc_code,
        bank_account_no: currentAccountDetails.bank_acc_no,
      })
    );
  }, [
    currentAccountDetails.pan,
    currentAccountDetails.ifsc_code,
    currentAccountDetails.bank_acc_no,
  ]);

  useEffect(() => {
    sessionStorage.removeItem("PAN GST Edit NewValue");
  }, []);

  const opencatgoriesfound = () => {
    setopencatgories(true);
  };

  const closecatgoriesFound = () => {
    setopencatgories(false);
  };

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  console.log(showRejectTnxPopup, "showRejectTnxPopup");
  return (
    <>
      <div id="webviewd">
        <div className="main-div flex flex-col h-screen overflow-auto bg-[#3a5686]">
          {showRejectTnxPopup && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998]  bg-custom-backdrop bg-opacity-80">
              <div ref={popupRef}>
                <RejectingTransactionPopup
                  type={"ArchiveAccount"}
                  accountId={
                    payload?.type === "bene_account_detail_modal"
                      ? payload?.payload?.bene_account_id
                      : id
                  }
                  handleClosePopup={handleClosePopup}
                  setShowRejectAllTxnReson={setShowRejectAllTxnReson}
                  setRejectTxnPopup={setRejectTxnPopup}
                  showRejectTnxPopup={showRejectTnxPopup}
                  closeBeneAccountDetailModal={
                    payload?.closeBeneAccountDetailModal
                  }
                />
              </div>
            </div>
          )}
          {loading ? (
            <div className="loader-container m-auto justify-center items-center flex">
              <img src={dataLoading} alt="" />
            </div>
          ) : (
            <>
              <div
                className={`h-auto bg-[#3a5686] ${
                  payload?.type === "bene_account_detail_modal"
                    ? null
                    : "mt-[76px]"
                }`}
              >
                {payload?.type === "bene_account_detail_modal" && (
                  <div
                    className={`${
                      payload?.type === "bene_account_detail_modal"
                        ? "flex flex-row justify-between items-center p-5 bg-[#1D3A6D]"
                        : "none"
                    }`}
                  >
                    <div className="flex flex-col space-y-1">
                      <p className="text-base text-[#F9F9F9] font-sans font-semibold">
                        Account Approval Pending
                      </p>
                      <p className="text-xs text-[#F9F9F9] font-sans font-normal">
                        Account is not approved, approve account before
                        approving transaction.
                      </p>
                    </div>
                    <div
                      className="flex justify-center items-center cursor-pointer w-7 h-7 rounded-[5px] bg-[#314D7E] hover:bg-[#506994]"
                      onClick={() => payload?.closeBeneAccountDetailModal()}
                    >
                      <img src={closeIcon} className="w-4 h-4" />
                    </div>
                  </div>
                )}
                {showPopup && (
                  <div className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80 flex items-center justify-center z-[9998]">
                    <DeclinedPopup
                      type="decline account details"
                      onClose={handleClosePopup}
                      accountId={deniedId}
                      id={
                        payload?.type === "bene_account_detail_modal"
                          ? payload?.payload?.bene_account_id
                          : id
                      }
                      allAccounts={ViewAccountDetails}
                      updateAllAccounts={updateAllAccounts}
                      showRejectTnxPopup={showRejectTnxPopup}
                      setRejectTxnPopup={setRejectTxnPopup}
                      preventMultiEnter={preventMultiEnter}
                      setPreventMultiEnter={setPreventMultiEnter}
                      modaltype={"bene_account_detail_modal"}
                      closeBeneAccountDetailModal={
                        payload?.closeBeneAccountDetailModal
                      }
                    />
                  </div>
                )}

                {showRejectAllTxnReson && (
                  <div className="fixed top-0 left-0 w-full h-full  flex items-center justify-center z-[9998]">
                    <BeneDetailModal
                      type="Reject-All-Transaction"
                      onClose={handleClosePopup}
                      accountId={deniedId}
                      id={id}
                      allAccounts={ViewAccountDetails}
                      updateAllAccounts={updateAllAccounts}
                      setRejectTxnPopup={setRejectTxnPopup}
                    />
                  </div>
                )}

                {openAssociatedAccounts && (
                  <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                    <BeneViewAssociatedAccount
                      onAsscociateClose={closeAssociatedAccountsFound}
                      IFSC={currentAccountDetails.ifsc_code}
                      accountNumber={currentAccountDetails.bank_acc_no}
                      beneAccountId={currentAccountDetails.id}
                      panNumber={currentAccountDetails.pan}
                      onAccountSelect={handleAccountSelection}
                      accountName={currentAccountDetails.name}
                      toggleContactDetails={toggleContactDetails}
                    />
                  </div>
                )}

                {unachivedOpenPop && (
                  <div className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80 flex items-center justify-center z-[9998]">
                    <BeneDetailModal
                      type="unarchived"
                      id={id}
                      title={"Unarchive account"}
                      message={
                        "Are you sure you want to unarchive this account?"
                      }
                      note={"This account will become active."}
                      close={handleCloseModal}
                      createFlow={"viewAll"}
                      payload={payload}
                    />
                  </div>
                )}
                {achivedOpenPop && (
                  <div className="fixed top-0 left-0 w-full h-full bg-custom-backdrop bg-opacity-80 flex items-center justify-center z-[9998]">
                    <BeneDetailModal
                      type="archived"
                      id={id}
                      title={"Archive account"}
                      message={"Are you sure you want to archive this account?"}
                      note={"This account will become inactive."}
                      close={handleCloseModal}
                      createFlow={"viewAll"}
                      payload={payload}
                      setRejectTxnPopup={setRejectTxnPopup}
                      setAchivedOpenPop={setAchivedOpenPop}
                    />
                  </div>
                )}
                {openTransHistory && (
                  <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                    <TransactionHistory
                      bene_account_id={
                        viewAccountDetailData?.beneficiary_accounts[0]?.id
                      }
                      bene_account_name={
                        viewAccountDetailData?.beneficiary_accounts[0]?.name
                      }
                      onClose={closeTransactionHistory}
                    />
                  </div>
                )}
                {opencatgories && (
                  <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                    <div
                      className={`account-details-page-categories-cardm ${
                        viewAccountDetailData?.beneficiary_accounts?.[0]
                          ?.category.length > 0
                          ? ""
                          : "w-1/2"
                      }`}
                    >
                      <span
                        className={`categories-card-assigned-text mb-5 ${
                          viewAccountDetailData?.beneficiary_accounts?.[0]
                            ?.category.length > 0
                            ? "ml-6"
                            : ""
                        }`}
                      >
                        {viewAccountDetailData?.beneficiary_accounts?.[0]
                          ?.category.length > 0
                          ? "Assigned Beneficiary Categories"
                          : "No assigned beneficiary categories found"}
                        <IconButton
                          onClick={() => {
                            closecatgoriesFound();
                          }}
                          style={{
                            backgroundColor: "#031F4F",
                            borderRadius: "5px",
                            height: "22px",
                            width: "22px",
                            float: "right",
                            marginRight: "20px",
                          }}
                        >
                          <Close className="text-white  hover:bg-[#667CA3] hover:rounded-lg" />
                        </IconButton>
                      </span>

                      <div
                        className={`${
                          viewAccountDetailData?.beneficiary_accounts?.[0]
                            ?.category.length > 0
                            ? "assigned-beneficiary-categories-data-divm"
                            : ""
                        }`}
                      >
                        {viewAccountDetailData?.beneficiary_accounts.map(
                          (account, index) => (
                            <div key={index}>
                              <Box>
                                {account.category
                                  ? account.category
                                      .split(",")
                                      .map((category, categoryIndex) => (
                                        <div
                                          className="cardm"
                                          key={categoryIndex}
                                        >
                                          <div className="bene-category-img">
                                            <img
                                              className="w-9 h-9 cateImage max-w-none"
                                              src={BeneIcon}
                                              alt="bene"
                                            />
                                          </div>
                                          <div className="bene_truncate px-3">
                                            <span className="bene-category-name antialiased">
                                              <Tooltip
                                                componentsProps={{
                                                  popper: {
                                                    sx: {
                                                      [`& .${tooltipClasses.tooltip}`]:
                                                        {
                                                          backgroundColor: (
                                                            theme
                                                          ) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                      [`& .${tooltipClasses.arrow}`]:
                                                        {
                                                          color: (theme) =>
                                                            theme.palette.common
                                                              .black,
                                                        },
                                                    },
                                                  },
                                                }}
                                                title={category.trim()}
                                                arrow
                                                placement="bottom"
                                                PopperProps={{
                                                  style: {
                                                    maxWidth: "none",
                                                  },
                                                }}
                                              >
                                                {category.trim()}
                                              </Tooltip>
                                            </span>
                                          </div>
                                        </div>
                                      ))
                                  : null}
                              </Box>
                              {!account.category && (
                                <p
                                  key={`notFound_${index}`}
                                  className="antialiased text-white text-sm"
                                >
                                  The beneficiary currently does not have any
                                  assigned categories. Please click edit to
                                  assign a category.
                                </p>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="w-full max-w-5xl m-auto bg-[#3a5686]"
                  id="container"
                >
                  <div className="flex max-w-5xl bg-[#3a5686] m-auto h-[30%]">
                    <div
                      className={`bene-account-primary-details ${
                        payload?.type === "bene_account_detail_modal"
                          ? "mt-0.5"
                          : "mt-[31px]"
                      }`}
                    >
                      {viewAccountDetailData?.beneficiary_accounts.map(
                        (account) => (
                          <div
                            key={account.id}
                            className="flex mt-[7.5px] border-b-2 border-solid border-[#1d3a6d]"
                          >
                            <div className="py-5 flex flex-col w-1/2">
                              <div className="bene-account-name text-base text-[#FFFFFF] text-left font-sans font-normal truncate opacity-100">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account.pan_legal_name.trim()}
                                  arrow
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                      zIndex:
                                        payload?.type ===
                                        "bene_account_detail_modal"
                                          ? "9999"
                                          : null,
                                    },
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      display: "block",
                                    }}
                                  >
                                    {account.pan_legal_name.trim()}
                                  </div>
                                </Tooltip>
                              </div>
                              <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                                Account name
                              </p>
                            </div>

                            {ViewAccountDetails?.map((account) => (
                              <div key={account.id} className="w-1/2 p-5">
                                <div className="flex flex-col items-start pl-5 border-l-2 border-solid border-[#1d3a6d]">
                                  <div
                                    className={`text-base text-left font-semibold capitalize opacity-100 ${
                                      account.approval_status === "pending"
                                        ? "bene-account-pending-status"
                                        : account.approval_status === "approved"
                                        ? "bene-account-approved-status"
                                        : account.approval_status === "rejected"
                                        ? "bene-account-rejected-status"
                                        : account.approval_status === "blocked"
                                        ? "bene-account-blocked-status"
                                        : account.approval_status === "archived"
                                        ? "bene-account-archived-status"
                                        : ""
                                    }`}
                                  >
                                    {account.approval_status}
                                  </div>
                                  <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                                    Account status
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      )}
                      {viewAccountDetailData?.beneficiary_accounts.map(
                        (account) => (
                          <div
                            key={account.id}
                            className="bene-account-holder-details flex border-b-2 border-solid border-[#1d3a6d]"
                          >
                            <div className="py-5 flex flex-col w-1/2">
                              <p className="text-base text-[#FAFAFA] text-left font-sans font-normal opacity-100">
                                {formatAccountNumber(account.bank_acc_no)}
                              </p>
                              <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                                Account number
                              </p>
                            </div>
                            <div className="p-5 w-1/2">
                              <div className="flex flex-col items-start pl-5 border-l-2 border-solid border-[#1d3a6d]">
                                <div className="bene-account-holder-name w-full text-base text-[#FAFAFA] text-left font-sans font-normal truncate opacity-100">
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                          [`& .${tooltipClasses.arrow}`]: {
                                            color: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={
                                      account.bank_account_holder_full_name
                                    }
                                    arrow
                                    placement="bottom"
                                    PopperProps={{
                                      style: {
                                        maxWidth: "none",
                                        zIndex:
                                          payload?.type ===
                                          "bene_account_detail_modal"
                                            ? "9999"
                                            : null,
                                      },
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        display: "block",
                                      }}
                                    >
                                      {account.bank_account_holder_full_name}
                                    </div>
                                  </Tooltip>
                                </div>
                                <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                                  Bank account holder name
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    <div
                      className={`minorAcc-div ${
                        payload?.type === "bene_account_detail_modal"
                          ? null
                          : "mt-[30px]"
                      }`}
                    >
                      <div className="associated-account-transaction-history-block flex flex-row bg-[#506994] border-b-2 border-solid border-[#1d3a6d]">
                        <div className="w-1/2 flex-grow">
                          <Box
                            className={`${
                              !isDisabled
                                ? "bg-[#506994] text-center h-full flex flex-col justify-center gap-1 p-2 opacity-50 "
                                : "cursor-pointer bg-[#506994] hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2 "
                            }`}
                            onClick={() => {
                              if (isDisabled) {
                                openAssociatedAccountsFound();
                              }
                            }}
                          >
                            <div className="flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={associated_accounts_found}
                                  alt="Associated Accounts"
                                  className="w-9 h-9"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                Associated accounts found
                              </Box>
                            </div>
                          </Box>
                        </div>

                        <div className="py-4 flex">
                          <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                        </div>

                        <div className="w-1/2 flex-grow">
                          <Box
                            className="cursor-pointer bg-[#506994] hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2"
                            onClick={openTransactionHistory}
                          >
                            <div className="flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={transactionHistory}
                                  alt="Transaction History"
                                  className="w-9 h-9"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                Transaction history
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </div>
                      <div className="settings flex flex-row bg-[#506994] border-b-2 border-solid border-[#1d3a6d]">
                        {services?.find(
                          (service) =>
                            service === "BUAccount" || service === "Admin"
                        ) === undefined ||
                        viewAccountDetailData?.beneficiary_accounts[0]
                          .approval_status === "archived" ||
                        viewAccountDetailData?.beneficiary_accounts[0]
                          .approval_status === "blocked" ? (
                          <>
                            <div
                              className="flex-grow w-1/2"
                              onClick={() => {
                                if (
                                  viewAccountDetailData?.beneficiary_accounts[0]
                                    .approval_status !== "archived" &&
                                  viewAccountDetailData?.beneficiary_accounts[0]
                                    .approval_status !== "blocked"
                                ) {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "BUAccount" ||
                                        (service === "Admin" &&
                                          payload?.servicesType !== "BenAdmin")
                                    ) !== undefined
                                  ) {
                                    handleModalOpen(id);
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BUAccount",
                                      },
                                    });
                                  }
                                }
                              }}
                            >
                              <Box className="opacity-50 cursor-not-allowed text-center h-full flex flex-col justify-center gap-1 p-2">
                                <div className="flex justify-center">
                                  <Box className="flex justify-center w-10">
                                    <img
                                      src={editImage}
                                      alt="document"
                                      className="edit-img w-9 h-9"
                                    />
                                  </Box>
                                </div>
                                <div className="flex justify-center px-2">
                                  <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                    Edit
                                  </Box>
                                </div>
                              </Box>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="flex-grow w-1/2"
                              onClick={() => {
                                if (
                                  payload?.type != "bene_account_detail_modal"
                                ) {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "BUAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    handleModalOpen(id);
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BUAccount",
                                      },
                                    });
                                  }
                                }
                              }}
                            >
                              <Box
                                className={` ${
                                  payload?.type != "bene_account_detail_modal"
                                    ? "cursor-pointer"
                                    : " cursor-auto"
                                } bg-[#506994] ${
                                  payload?.type != "bene_account_detail_modal"
                                    ? "hover:bg-[#667CA3] opacity-100"
                                    : "opacity-50"
                                }  text-center h-full flex flex-col justify-center gap-1 p-2`}
                              >
                                <div className="flex justify-center">
                                  <Box className="flex justify-center w-10">
                                    <img
                                      src={editImage}
                                      alt="document"
                                      className="edit-img w-9 h-9"
                                    />
                                  </Box>
                                </div>
                                <div className="flex justify-center px-2">
                                  <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                    Edit
                                  </Box>
                                </div>
                              </Box>
                            </div>
                            {isEditModalOpen && (
                              <BeneEditModal onClose={handleModalClose} />
                            )}
                          </>
                        )}
                        <div className="py-4 flex">
                          <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                        </div>

                        {viewAccountDetailData?.beneficiary_accounts[0]
                          .approval_status === "blocked" ||
                        services?.find(
                          (service) =>
                            service === "BAAccountArchive" ||
                            service === "Admin"
                        ) === undefined ? (
                          <>
                            <div
                              className="opacity-50 cursor-not-allowed flex-grow w-1/2"
                              onClick={() => {
                                if (
                                  viewAccountDetailData?.beneficiary_accounts[0]
                                    .approval_status !== "blocked"
                                ) {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "BAAccountArchive" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    handleUnarchive();
                                    handleArchive();
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "BAAccountArchive",
                                      },
                                    });
                                  }
                                }
                              }}
                            >
                              <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                                <div className="flex justify-center">
                                  <Box className="flex justify-center w-10">
                                    {services?.find(
                                      (service) =>
                                        service === "BAAccountArchive" ||
                                        service === "Admin"
                                    ) === undefined &&
                                    viewAccountDetailData
                                      ?.beneficiary_accounts[0]
                                      .approval_status === "archived" ? (
                                      <img
                                        src={unarchiveImage}
                                        alt="document"
                                        className="w-9 h-9"
                                      />
                                    ) : (
                                      <img
                                        src={archieveImage}
                                        alt="document"
                                        className="w-9 h-9"
                                      />
                                    )}
                                  </Box>
                                </div>
                                <div className="flex justify-center px-2">
                                  <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                    {services?.find(
                                      (service) =>
                                        service === "BAAccountArchive" ||
                                        service === "Admin"
                                    ) == undefined &&
                                    viewAccountDetailData
                                      ?.beneficiary_accounts[0]
                                      .approval_status === "archived"
                                      ? "Unarchive"
                                      : "Archive"}
                                  </Box>
                                </div>
                              </Box>
                            </div>
                          </>
                        ) : (
                          <div
                            className={`flex-grow w-1/2 bg-[#506994] ${
                              payload?.type != "bene_account_detail_modal"
                                ? "hover:bg-[#667CA3]"
                                : ""
                            } `}
                            onClick={() => {
                              if (
                                payload?.type != "bene_account_detail_modal"
                              ) {
                                if (
                                  services?.find(
                                    (service) =>
                                      service === "BAAccountArchive" ||
                                      service === "Admin"
                                  ) !== undefined
                                ) {
                                  handleUnarchive();
                                  handleArchive();
                                } else {
                                  navigate("/feature_not_assigned", {
                                    state: {
                                      unavailableService: "BAAccountArchive",
                                    },
                                  });
                                }
                              }
                            }}
                          >
                            <Box
                              className={`${
                                services?.find(
                                  (service) =>
                                    service === "BAAccountArchive" ||
                                    service === "Admin"
                                ) !== undefined &&
                                payload?.type != "bene_account_detail_modal"
                                  ? "cursor-pointer opacity-100"
                                  : "opacity-50 cursor-auto"
                              } text-center h-full flex flex-col  justify-center gap-1  p-2`}
                            >
                              {viewAccountDetailData?.beneficiary_accounts[0]
                                .approval_status === "archived" ? (
                                <>
                                  <div
                                    className={`${
                                      services?.find(
                                        (service) =>
                                          service === "BAAccountArchive" ||
                                          service === "Admin"
                                      ) !== undefined
                                        ? ""
                                        : ""
                                    } flex justify-center`}
                                  >
                                    <Box className="flex justify-center w-10">
                                      <img
                                        src={unarchiveImage}
                                        alt="document"
                                        className="w-9 h-9"
                                      />
                                    </Box>
                                  </div>
                                  <div
                                    className={`${
                                      services?.find(
                                        (service) =>
                                          service === "BAAccountArchive" ||
                                          (service === "Admin") !== undefined
                                      )
                                        ? "opacity-100"
                                        : "opacity-50 cursor-not-allowed"
                                    } flex justify-center px-2`}
                                  >
                                    <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                      Unarchive
                                    </Box>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="flex justify-center">
                                    <Box className="flex justify-center w-10">
                                      <img
                                        src={archieveImage}
                                        alt="document"
                                        className="w-9 h-9"
                                      />
                                    </Box>
                                  </div>
                                  <div className="flex justify-center px-2">
                                    <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                      Archive
                                    </Box>
                                  </div>
                                </>
                              )}
                            </Box>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center">
                <div className="flex flex-row h-[90px] w-[1024px] mx-auto">
                  {viewAccountDetailData?.beneficiary_accounts.map(
                    (account) => (
                      <>
                        <div key={account?.id} className="w-2/5">
                          <div className="flex items-center p-2.5 h-[92px] w-28 bg-[#FAFAFA]">
                            <img
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(account?.ifsc_code))}
                              alt="bank"
                            />
                          </div>
                        </div>

                        <div className="w-3/5 flex flex-row">
                          <div
                            className={`pb-5 pt-5 pl-2.5  ${
                              ViewAccountDetails[0]?.approval_status ===
                                "rejected" ||
                              (ViewAccountDetails[0]?.approval_status ===
                                "pending" &&
                                ViewAccountDetails[0]?.error_msg?.length > 0)
                                ? "denied-ifsc-div"
                                : "ifsc-div-one"
                            }`}
                          >
                            <div className="account-ifsc-div-main w-[180px] flex flex-row  hover:shadow-lg hover:rounded-md hover:bg-[#667CA3]">
                              <div
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "VIFSC" ||
                                        service === "Admin"
                                    ) !== undefined &&
                                    account.ifsc_code
                                  ) {
                                    navigate(
                                      `/accounts/beneficiary_account/details/IFSC/${account.ifsc_code}`
                                    );
                                  } else {
                                    navigate("/feature_not_assigned", {
                                      state: {
                                        unavailableService: "VIFSC",
                                      },
                                    });
                                  }
                                }}
                                className={`account-ifsc-div-content  ${
                                  account?.ifsc_code &&
                                  services?.find(
                                    (service) =>
                                      service === "VIFSC" || service === "Admin"
                                  )
                                    ? "cursor-pointer"
                                    : "cursor-not-allowed"
                                }  `}
                              >
                                <div
                                  className={`${
                                    account?.ifsc_code &&
                                    services?.find(
                                      (service) =>
                                        service === "VIFSC" ||
                                        service === "Admin"
                                    )
                                      ? "h-full w-full"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className="account-ifsc-response pl-[5px]"
                                    style={{
                                      flex: "90%",
                                      // textDecoration:
                                      //   account?.ifsc_code &&
                                      //   services?.find(
                                      //     (service) =>
                                      //       service === "VIFSC" ||
                                      //       service === "Admin"
                                      //   )
                                      //     ? "underline #FAFAFA 1px"
                                      //     : "none",
                                    }}
                                  >
                                    {account?.ifsc_code}
                                  </div>
                                  <div className="account-ifsc-code-element text-xs pl-[5px]">
                                    IFSC code
                                  </div>
                                </div>

                                <div className="grid place-content-center w-6 h-5 mt-1 bg-[#1D3A6D] rounded-md mr-[7px]">
                                  <img
                                    src={arrowRight}
                                    alt="document"
                                    className={`w-[12px] h-[12px] ${
                                      services?.includes("VIFSC")
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed"
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${
                              (ViewAccountDetails[0]?.approval_status ===
                                "rejected" &&
                                ViewAccountDetails[0]?.error_msg?.length > 0) ||
                              (ViewAccountDetails[0]?.approval_status ===
                                "pending" &&
                                ViewAccountDetails[0]?.error_msg?.length > 0)
                                ? "denied-btn-div"
                                : "button-div-element"
                            }`}
                          >
                            <div
                              className={`${
                                services?.find(
                                  (service) =>
                                    service === "CBeneficiaryTxn" ||
                                    service === "Admin"
                                ) === undefined &&
                                viewAccountDetailData?.beneficiary_accounts[0]
                                  .approval_status === "approved"
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              } pay-bene`}
                            >
                              {ViewAccountDetails?.map((account) => (
                                <>
                                  {account?.approval_status === "approved" ? (
                                    <>
                                      <div
                                        key={account.id}
                                        className={`pay-bene-box2 flex justify-center ${
                                          account?.approval_status ===
                                            "approved" &&
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) !== undefined &&
                                          currentAccountDetails.category
                                            .length > 0
                                            ? "approved-pay-bene hover:opacity-75"
                                            : account?.approval_status ===
                                              "approved"
                                            ? "approved-pay-bene"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "CBeneficiaryTxn" ||
                                                service === "Admin"
                                            ) !== undefined &&
                                            account?.approval_status ===
                                              "approved"
                                          ) {
                                            createTransaction();
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService:
                                                  "CBeneficiaryTxn",
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        <Box
                                          className={`${
                                            services?.find(
                                              (service) =>
                                                service === "CBeneficiaryTxn" ||
                                                service === "Admin"
                                            ) !== undefined &&
                                            currentAccountDetails.category
                                              .length > 0
                                              ? "cursor-pointer"
                                              : ""
                                          } text-center h-full flex flex-col justify-center gap-1 p-0 w-[195px]`}
                                        >
                                          <div className="flex justify-center">
                                            <Box className="self-center w-10">
                                              <img
                                                src={payBene}
                                                alt="document"
                                                className="w-9 h-9"
                                              />
                                            </Box>
                                          </div>
                                          <div className="flex justify-center">
                                            <Box className="text-xs text-[#F9F9F9] font-sans font-normal self-center antialiased truncate...">
                                              Pay Beneficiary
                                            </Box>
                                          </div>
                                        </Box>
                                      </div>
                                    </>
                                  ) : account?.approval_status === "rejected" &&
                                    account?.error_msg?.length > 0 ? (
                                    <div
                                      key={account.id}
                                      className={`pay-bene-box2 flex justify-center ${
                                        account?.approval_status ===
                                          "approved" &&
                                        services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined &&
                                        currentAccountDetails.category.length >
                                          0
                                          ? "approved-pay-bene hover:opacity-75"
                                          : account?.approval_status ===
                                            "approved"
                                          ? "approved-pay-bene"
                                          : ""
                                      }`}
                                    >
                                      <div className="flex flex-row gap-x-3 h-full w-full bg-[#BB5353] p-2.5">
                                        <div className="flex place-items-center w-8">
                                          <img src={skipping_verification} />
                                        </div>
                                        <div className="flex flex-col justify-center gap-y-1 w-4/5">
                                          <span className="font-semibold font-sans text-xs text-[#FFFFFF]">
                                            Failed to take action
                                          </span>
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.error_msg}
                                            arrow
                                            placement="bottom"
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                              },
                                            }}
                                          >
                                            <span className="font-sans text-[10px] text-[#FAFAFA] text-ellipsis overflow-hidden w-full block">
                                              {account?.error_msg}
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </div>
                                  ) : account.approval_status === "pending" &&
                                    account.error_msg?.length > 0 ? (
                                    <div
                                      key={account.id}
                                      className={`pay-bene-box2 flex justify-center ${
                                        account?.approval_status ===
                                          "approved" &&
                                        services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined &&
                                        currentAccountDetails.category.length >
                                          0
                                          ? "approved-pay-bene hover:opacity-75"
                                          : account?.approval_status ===
                                            "approved"
                                          ? "approved-pay-bene"
                                          : ""
                                      }`}
                                    >
                                      <div className="flex flex-row h-full w-full bg-[#BB5353] p-2.5">
                                        <div className="flex place-items-center w-1/6">
                                          <img
                                            src={skipping_verification}
                                            className="w-7 h-7 ml-2"
                                          />
                                        </div>
                                        <div className="flex flex-col justify-center space-y-1 w-5/6">
                                          <span className="font-semibold font-sans text-sm text-[#FFFFFF]">
                                            Failed to take action
                                          </span>

                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.error_msg}
                                            arrow
                                            placement="bottom"
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                              },
                                            }}
                                          >
                                            <span className="font-sans text-xs text-[#FAFAFA] text-ellipsis overflow-hidden w-full block">
                                              {account?.error_msg}
                                            </span>
                                          </Tooltip>
                                        </div>
                                      </div>
                                    </div>
                                  ) : account.approval_status === "pending" ? (
                                    <div
                                      key={account.id}
                                      className={`pay-bene-box2 flex justify-center ${
                                        account?.approval_status ===
                                          "approved" &&
                                        services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined &&
                                        currentAccountDetails.category.length >
                                          0
                                          ? "approved-pay-bene hover:opacity-75"
                                          : account?.approval_status ===
                                            "approved"
                                          ? "approved-pay-bene"
                                          : ""
                                      }`}
                                    >
                                      <div
                                        className={`${
                                          services?.find(
                                            (service) =>
                                              service === "BAAccount" ||
                                              service === "Admin"
                                          ) !== undefined
                                            ? "opacity-100 cursor-pointer hover:opacity-75"
                                            : "opacity-50 cursor-not-allowed"
                                        } w-1/2 flex justify-center	items-center bg-[#ea545b]`}
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount" ||
                                                service === "Admin"
                                            ) !== undefined
                                          ) {
                                            handleDeclinedDetials(account.id);
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "BAAccount",
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                                          <div className="decline-image-div flex justify-center">
                                            <Box className="flex justify-center w-10">
                                              <img
                                                src={declineImage}
                                                alt="document"
                                                className="w-9 h-9"
                                              />
                                            </Box>
                                          </div>
                                          <div className="flex justify-center px-2">
                                            <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                              Reject
                                            </Box>
                                          </div>
                                        </Box>
                                      </div>
                                      <div
                                        className={`${
                                          services?.find(
                                            (service) =>
                                              service === "BAAccount" ||
                                              service === "Admin"
                                          ) !== undefined
                                            ? "opacity-100 cursor-pointer hover:opacity-75"
                                            : "opacity-50 cursor-not-allowed"
                                        } w-1/2 flex justify-center items-center bg-[#69b76f]`}
                                        onClick={() => {
                                          if (
                                            services?.find(
                                              (service) =>
                                                service === "BAAccount" ||
                                                service === "Admin"
                                            ) !== undefined
                                          ) {
                                            handleApprovalDetails(account.id);
                                          } else {
                                            navigate("/feature_not_assigned", {
                                              state: {
                                                unavailableService: "BAAccount",
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                                          <div className="approve-image-div flex justify-center">
                                            <Box className="flex justify-center w-10">
                                              <img
                                                src={checkmark}
                                                alt="document"
                                                className="w-9 h-9"
                                              />
                                            </Box>
                                          </div>
                                          <div className="flex justify-center px-2">
                                            <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                              Approve
                                            </Box>
                                          </div>
                                        </Box>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>

              <div className="bg-[#1d3a6d] flex-grow w-full">
                <div className="h-auto max-w-5xl bg-[#1d3a6d] m-auto">
                  <div className="detail-category-button">
                    <button
                      className={`detail-button mr-5 text-xs text-center font-sans font-semibold ${
                        showContactDetails ? "active" : "hover:bg-[#A3A3A3]"
                      }`}
                      onClick={toggleContactDetails}
                    >
                      Details
                    </button>
                    <button
                      className={`category-button text-xs text-center font-sans font-semibold ${
                        showCategories ? "active" : "hover:bg-[#A3A3A3]"
                      }`}
                      onClick={toggleCategories}
                    >
                      Categories
                    </button>
                  </div>
                  {showContactDetails &&
                    ViewAccountDetails?.map((account) => (
                      <div className="flex flex-row justify-around mb-5 gap-5">
                        <div className="contact-detail-card p-5 h-[179px] flex-none">
                          <p className="text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 pb-5">
                            Contact Details
                          </p>
                          <div className="space-y-1">
                            <p className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100">
                              {"+91-" + account.contact_no}
                            </p>
                            <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 pb-2.5 border-b-2 border-solid border-[#1d3a6d]">
                              Contact number
                            </p>
                          </div>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={account?.email_id?.toLowerCase()}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                                zIndex:
                                  payload?.type === "bene_account_detail_modal"
                                    ? "9999"
                                    : null,
                              },
                            }}
                          >
                            <div className="mt-2.5 space-y-1">
                              <div
                                id="email-id-firstLetter"
                                className="bene_detail_email text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100"
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {account?.email_id?.toLowerCase()}
                              </div>
                              <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100">
                                Email ID
                              </p>
                            </div>
                          </Tooltip>
                        </div>

                        <div className="account-detail-card p-5 h-[179px] flex-none">
                          <p className="text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 pb-5">
                            Account Details
                          </p>
                          <div className="space-y-1">
                            <p className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100">
                              {account.pan_no}
                            </p>
                            <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 pb-2.5 border-b-2 border-solid border-[#1d3a6d]">
                              PAN details
                            </p>
                          </div>
                          <div
                            className={`gst-details mt-1.5 py-1 pr-1 space-y-1 ${
                              account?.gstin_detail
                                ? services?.find(
                                    (service) => service === "VGSTIN"
                                  )
                                  ? "hover:bg-gray-200 hover:bg-opacity-20 rounded-lg -ml-[-0.38rem] py-1 pl-1 pr-1 cursor-pointer"
                                  : "cursor-not-allowed py-1 pr-1"
                                : "cursor-default py-1 pr-1"
                            }`}
                            onClick={
                              account?.gstin_detail
                                ? () => {
                                    if (
                                      services?.find(
                                        (service) =>
                                          service === "VGSTIN" ||
                                          service === "Admin"
                                      ) !== undefined &&
                                      account?.gstin_detail
                                    ) {
                                      handleGSTDetails(account.gstin_detail);
                                    } else {
                                      navigate("/feature_not_assigned", {
                                        state: {
                                          unavailableService: "VGSTIN",
                                        },
                                      });
                                    }
                                  }
                                : null
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <div
                                className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100"
                                style={{ flex: "90%" }}
                              >
                                {account.gstin_detail
                                  ? account.gstin_detail
                                  : "Not Given"}
                              </div>
                              {account.gstin_detail && (
                                <div
                                  style={{ flex: "10%" }}
                                  className={`grid place-content-center w-3 h-4.4 bg-[#1D3A6D] rounded-md ${
                                    services?.includes("VGSTIN")
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  }`}
                                >
                                  <button>
                                    <img
                                      src={arrowRight}
                                      alt="document"
                                      className="w-3 h-3"
                                    />
                                  </button>
                                </div>
                              )}
                            </div>

                            <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100">
                              GST details
                            </p>
                          </div>
                        </div>

                        <div
                          className={`origin-timeline-card p-5 flex-none ${
                            account.approval_status === "rejected"
                              ? "h-auto"
                              : "h-[179px]"
                          }`}
                        >
                          <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5 opacity-100">
                            Origin Timeline
                          </p>
                          <div className="space-y-1">
                            <div className="flex justify-between">
                              <p className="creator-name text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate w-3/5">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account.created_by_name}
                                  arrow
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                      zIndex:
                                        payload?.type ===
                                        "bene_account_detail_modal"
                                          ? "9999"
                                          : null,
                                    },
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      display: "block",
                                    }}
                                  >
                                    {account.created_by_name}
                                  </div>
                                </Tooltip>
                              </p>
                              <p className="w-2/5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                {account.created_on
                                  ? formatDateViewAll(account.created_on, false)
                                  : "Not given"}
                              </p>
                            </div>
                            <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 w-full pb-2.5 border-b-2 border-solid border-[#1d3a6d]">
                              Created by
                            </p>
                          </div>

                          <div className="mt-2.5">
                            {account.approval_status === "approved" ||
                            account.approval_status === "rejected" ? (
                              <>
                                <div className="flex justify-between">
                                  {account.approval_status === "approved" ? (
                                    <>
                                      <p className="approved-name w-3/5 pb-1 text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={
                                            account.approved_by_name
                                              ? account.approved_by_name
                                              : "Not given"
                                          }
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              display: "block",
                                            }}
                                          >
                                            {account.approved_by_name
                                              ? account.approved_by_name
                                              : "Not given"}
                                          </div>
                                        </Tooltip>
                                      </p>
                                      <p className="w-2/5 mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                        {account.approved_on
                                          ? formatDateViewAll(
                                              account.approved_on,
                                              false
                                            )
                                          : "Not given"}
                                      </p>
                                    </>
                                  ) : account.rejected_by_name ? (
                                    <>
                                      <p className="approved-name w-3/5 pb-1 text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account.rejected_by_name}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              display: "block",
                                            }}
                                          >
                                            {account.rejected_by_name}
                                          </div>
                                        </Tooltip>
                                      </p>
                                      <p className="w-2/5 mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                        {account.rejected_on
                                          ? formatDateViewAll(
                                              account.rejected_on,
                                              false
                                            )
                                          : "Not given"}
                                      </p>
                                    </>
                                  ) : (
                                    "Not given"
                                  )}
                                </div>

                                <div
                                  className={`text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 ${
                                    account.approval_status === "rejected"
                                      ? "border-b-2 border-solid border-[#1d3a6d] pb-2.5"
                                      : ""
                                  }`}
                                >
                                  {account.approval_status === "approved"
                                    ? "Approved by"
                                    : "Rejected by"}
                                </div>
                              </>
                            ) : (
                              <div className="space-y-1">
                                <div className="flex justify-between">
                                  <div className="w-3/5 text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate">
                                    {account.approval_status === "pending" ? (
                                      "Approval pending"
                                    ) : account.approval_status ===
                                      "approved" ? (
                                      <>
                                        {account.approved_by_name
                                          ? account.approved_by_name
                                          : "Not given"}
                                      </>
                                    ) : account.approval_status ===
                                      "archived" ? (
                                      <>
                                        {account.archived === "Y" &&
                                        !account.archived_by_name ? (
                                          <>
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={nameArchive}
                                              arrow
                                              placement="bottom"
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                },
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: "100%",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  display: "block",
                                                }}
                                              >
                                                {nameArchive}
                                              </div>
                                            </Tooltip>
                                          </>
                                        ) : account.archived_by_name ? (
                                          <>
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={account.archived_by_name}
                                              arrow
                                              placement="bottom"
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                },
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: "100%",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  display: "block",
                                                }}
                                              >
                                                {account.archived_by_name}
                                              </div>
                                            </Tooltip>
                                          </>
                                        ) : (
                                          "Not given"
                                        )}
                                      </>
                                    ) : account.approval_status ===
                                      "blocked" ? (
                                      <>
                                        {account.blocked_by_name ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account.blocked_by_name}
                                            arrow
                                            placement="bottom"
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                              },
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: "100%",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                display: "block",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {account?.blocked_by_name}
                                            </div>
                                          </Tooltip>
                                        ) : (
                                          "Not given"
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {account.approval_status === "approved" && (
                                    <div className="mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                      {account.approved_on
                                        ? formatDateViewAll(
                                            account.approved_on,
                                            false
                                          )
                                        : "Not given"}
                                    </div>
                                  )}
                                  {account.approval_status === "archived" && (
                                    <div className="mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                      {account.archived_on
                                        ? formatDateViewAll(
                                            account.archived_on,
                                            false
                                          )
                                        : "Not given"}
                                    </div>
                                  )}
                                  {account.approval_status === "blocked" && (
                                    <div className="mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                      {account.blocked_on
                                        ? formatDateViewAll(
                                            account.blocked_on,
                                            false
                                          )
                                        : "Not given"}
                                    </div>
                                  )}
                                </div>

                                <div className="text-xs text-[#C9C9C9] text-left font-sans font-normal">
                                  {account.approval_status === "pending"
                                    ? "Approved by"
                                    : `${capitalize(
                                        account.approval_status
                                      )} by`}
                                </div>
                              </div>
                            )}
                            {account.approval_status === "rejected" && (
                              <div className="mt-2.5 space-y-1">
                                <div className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 break-words whitespace-normal w-full">
                                  {account?.rejection_reason}
                                </div>
                                <div className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-1">
                                  Rejected reason
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="support-information-card p-5 h-[179px] flex-none">
                          <p className="text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 pb-5">
                            Support Information
                          </p>
                          <div className="space-y-1">
                            <p className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 make-text-lower">
                              {account.id}
                            </p>
                            <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100">
                              Account ID
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {showCategories && (
                    <div
                      className={`account-details-page-categories-card h-auto ${
                        viewAccountDetailData?.beneficiary_accounts?.[0]
                          ?.category.length > 0
                          ? ""
                          : "w-1/2"
                      }`}
                    >
                      <span
                        className={`text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 block mb-5 ${
                          viewAccountDetailData?.beneficiary_accounts?.[0]
                            ?.category.length > 0
                            ? "ml-6"
                            : ""
                        }`}
                      >
                        {viewAccountDetailData?.beneficiary_accounts?.[0]
                          ?.category.length > 0
                          ? "Assigned Beneficiary Categories"
                          : "No assigned beneficiary categories found"}
                      </span>

                      <div
                        className={`${
                          viewAccountDetailData?.beneficiary_accounts?.[0]
                            ?.category.length > 0
                            ? "assigned-beneficiary-categories-data-div"
                            : ""
                        }`}
                      >
                        {viewAccountDetailData?.beneficiary_accounts.map(
                          (account, index) => (
                            <div key={index}>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(4, 1fr)",
                                  gap: 2,
                                }}
                              >
                                {account.category
                                  ? account.category
                                      .split(",")
                                      .map((category, categoryIndex) => (
                                        <div
                                          className="category-card flex flex-row items-center"
                                          key={categoryIndex}
                                        >
                                          <div className="bene-category-img flex justify-center items-center opacity-100">
                                            <img
                                              className="w-9 h-9 cateImage max-w-none"
                                              src={BeneIcon}
                                              alt="bene"
                                            />
                                          </div>
                                          <div className="truncate text-white px-3">
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              title={category.trim()}
                                              arrow
                                              placement="bottom-start"
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                  zIndex:
                                                    payload?.type ===
                                                    "bene_account_detail_modal"
                                                      ? "9999"
                                                      : null,
                                                },
                                              }}
                                            >
                                              <span className="text-sm text-[#FAFAFA] text-left font-sans font-semibold opacity-100 pl-1.5 antialiased">
                                                {category.trim()}
                                              </span>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      ))
                                  : null}
                              </Box>
                              {!account.category && (
                                <p
                                  key={`notFound_${index}`}
                                  className="antialiased text-white text-sm"
                                >
                                  The beneficiary currently does not have any
                                  assigned categories. Please click edit to
                                  assign a category.
                                </p>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div id="mobileviewd">
        {showRejectTnxPopup && (
          <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
            <RejectingTransactionPopup
              type={"ArchiveAccount"}
              accountId={id}
              handleClosePopup={handleClosePopup}
              setShowRejectAllTxnReson={setShowRejectAllTxnReson}
              setRejectTxnPopup={setRejectTxnPopup}
            />
          </div>
        )}
        {loading ? (
          <div className="loader-container flex justify-center items-center min-h-screen mx-auto">
            <img src={dataLoading} alt="" />
          </div>
        ) : (
          <>
            {showPopup && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <DeclinedPopup
                  type="decline account details"
                  onClose={handleClosePopup}
                  accountId={deniedId}
                  id={id}
                  pathName={pathName}
                  allAccounts={ViewAccountDetails}
                  updateAllAccounts={updateAllAccounts}
                  showRejectTnxPopup={showRejectTnxPopup}
                  setRejectTxnPopup={setRejectTxnPopup}
                  preventMultiEnter={preventMultiEnter}
                  setPreventMultiEnter={setPreventMultiEnter}
                />
              </div>
            )}

            {showRejectAllTxnReson && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="Reject-All-Transaction"
                  onClose={handleClosePopup}
                  accountId={deniedId}
                  id={id}
                  allAccounts={ViewAccountDetails}
                  updateAllAccounts={updateAllAccounts}
                  setRejectTxnPopup={setRejectTxnPopup}
                />
              </div>
            )}

            {openAssociatedAccounts && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneViewAssociatedAccount
                  onAsscociateClose={closeAssociatedAccountsFound}
                  IFSC={currentAccountDetails.ifsc_code}
                  accountNumber={currentAccountDetails.bank_acc_no}
                  beneAccountId={currentAccountDetails.id}
                  panNumber={currentAccountDetails.pan}
                  onAccountSelect={handleAccountSelection}
                  accountName={currentAccountDetails.name}
                  toggleContactDetails={toggleContactDetails}
                />
              </div>
            )}

            {unachivedOpenPop && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="unarchived"
                  id={id}
                  title={"Unarchive account"}
                  message={"Are you sure you want to unarchive this account?"}
                  note={"This account will become active."}
                  close={handleCloseModal}
                  createFlow={"viewAll"}
                  payload={payload}
                />
              </div>
            )}
            {achivedOpenPop && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="archived"
                  id={id}
                  title={"Archive account"}
                  message={"Are you sure you want to archive this account?"}
                  note={"This account will become inactive."}
                  close={handleCloseModal}
                  createFlow={"viewAll"}
                  payload={payload}
                  setRejectTxnPopup={setRejectTxnPopup}
                  setAchivedOpenPop={setAchivedOpenPop}
                />
              </div>
            )}
            {openTransHistory && (
              <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                <TransactionHistory
                  bene_account_id={
                    viewAccountDetailData?.beneficiary_accounts[0]?.id
                  }
                  bene_account_name={
                    viewAccountDetailData?.beneficiary_accounts[0]?.name
                  }
                  onClose={closeTransactionHistory}
                />
              </div>
            )}
            <div className="bene_detail_heder_containerm">
              <div className="allBenedetailheadertext">
                <div
                  className="allBenedetailheaderarrow"
                  onClick={() => navigate(-1)}
                >
                  <img src={arrowleft} alt="" />
                </div>
                <div className="allBenedetailheadertextm">
                  <div className="headingAccountDetail text-xl">
                    Beneficiary
                  </div>
                  <div className="headertextbottomv text-sm">
                    Accounts details
                  </div>
                </div>
              </div>
            </div>
            <div className="bene_detail_containerm">
              <div className="allBenedetailtext">
                {viewAccountDetailData?.beneficiary_accounts.map((account) => (
                  <div
                    key={account.id}
                    className="bene-account-name-and-status-details"
                  >
                    <div className="bene-account-name-blockm">
                      <div className="bene-account-name">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={account.pan_legal_name.trim()}
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              display: "block",
                            }}
                          >
                            {account?.pan_legal_name.trim()}
                          </div>
                        </Tooltip>
                      </div>
                      <p className="bene-account-name-label">Account name</p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="allBenedetailtext">
                {ViewAccountDetails?.map((account) => (
                  <div key={account.id} className="bene-account-status-blockm">
                    <div className="bene-account-status-contentm">
                      <div
                        className={`bene-account-status-div ${
                          account.approval_status === "pending"
                            ? "bene-account-pending-status"
                            : account.approval_status === "approved"
                            ? "bene-account-approved-status"
                            : account.approval_status === "rejected"
                            ? "bene-account-rejected-status"
                            : account.approval_status === "blocked"
                            ? "bene-account-blocked-status"
                            : account.approval_status === "archived"
                            ? "bene-account-archived-status"
                            : ""
                        }`}
                      >
                        {account?.approval_status}
                      </div>
                      <p className="bene-account-status-label">
                        Account status
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="allBenedetailtext">
                {viewAccountDetailData?.beneficiary_accounts.map((account) => (
                  <div key={account.id} className="bene-account-holder-details">
                    <div className="bene-account-number-blockm">
                      <p className="text-white text-sm">
                        {formatAccountNumber(account?.bank_acc_no)}
                      </p>
                      <p className="bene-account-number-label text-xs">
                        Account number
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="allBenedetailtext">
                {viewAccountDetailData?.beneficiary_accounts.map((account) => (
                  <div key={account.id} className="bene-account-holder-details">
                    <div className="bene-account-bank-holder-blockm">
                      <div className="bene-account-holder-contentm">
                        <div className="bene-account-holder-name w-full">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={account?.bank_account_holder_full_name}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "block",
                              }}
                            >
                              {account?.bank_account_holder_full_name}
                            </div>
                          </Tooltip>
                        </div>
                        <p className="bene-account-holder-label">
                          Bank account holder name
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="allBenedetailtext"
                style={{ borderBottom: "0px" }}
              >
                {viewAccountDetailData?.beneficiary_accounts.map((account) => (
                  <>
                    <div key={account.id} className="bank-img-blockm">
                      <div className="flex items-center p-2.5 w-28 bg-[#FAFAFA]">
                        <img
                          style={{
                            height:
                              services?.find(
                                (service) =>
                                  service === "BAAccount" || service === "Admin"
                              ) !== undefined
                                ? "64px"
                                : "63px",
                          }}
                          src={require("../../../../assets/IfscLogo/" +
                            IfscCodeMapper(account?.ifsc_code))}
                          alt="bank"
                        />
                      </div>
                    </div>

                    <div className="flex flex-row">
                      <div
                        className={`py-6 ${
                          ViewAccountDetails[0]?.approval_status ===
                            "rejected" ||
                          (ViewAccountDetails[0]?.approval_status ===
                            "pending" &&
                            ViewAccountDetails[0]?.error_msg?.length > 0)
                            ? "denied-ifsc-div"
                            : "ifsc-div-one"
                        }`}
                      >
                        <div className="acc-content-elementm">
                          <p className="text-sm">{account.ifsc_code}</p>
                          <p className="acc-number-label text-xs">IFSC code</p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div
                className={`${
                  (ViewAccountDetails[0]?.approval_status === "rejected" &&
                    ViewAccountDetails[0]?.error_msg?.length > 0) ||
                  (ViewAccountDetails[0]?.approval_status === "pending" &&
                    ViewAccountDetails[0]?.error_msg?.length > 0)
                    ? "denied-btn-div"
                    : "button-div-element"
                }`}
              >
                <div
                  className={`${
                    services?.find(
                      (service) =>
                        service === "CBeneficiaryTxn" || service === "Admin"
                    ) === undefined &&
                    viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "approved"
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  style={{ width: "100%" }}
                >
                  {ViewAccountDetails?.map((account) => (
                    <>
                      {account?.approval_status === "approved" ? (
                        <>
                          <div
                            key={account.id}
                            className={`${
                              account?.approval_status === "approved" &&
                              services?.find(
                                (service) =>
                                  service === "CBeneficiaryTxn" ||
                                  service === "Admin"
                              ) !== undefined &&
                              currentAccountDetails.category.length > 0
                                ? "approved-pay-bene hover:opacity-75"
                                : account?.approval_status === "approved"
                                ? "approved-pay-bene"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "CBeneficiaryTxn" ||
                                    service === "Admin"
                                ) !== undefined &&
                                account?.approval_status === "approved"
                              ) {
                                // createTransaction();
                                toast.info(
                                  "Feature coming soon!",
                                  toast_position
                                );
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "CBeneficiaryTxn",
                                  },
                                });
                              }
                            }}
                          >
                            <Box
                              className={`${
                                services?.find(
                                  (service) =>
                                    service === "CBeneficiaryTxn" ||
                                    service === "Admin"
                                ) !== undefined &&
                                currentAccountDetails.category.length > 0
                                  ? "cursor-pointer"
                                  : ""
                              } text-center h-full w-full flex flex-col justify-center gap-1 p-2`}
                            >
                              <div className="paybene-image-div flex justify-center">
                                <Box className="self-center w-10">
                                  <img
                                    src={payBene}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center">
                                <Box className="pay-bene-label h-8/12 self-center text-white text-xs antialiased truncate...">
                                  Pay Beneficiary
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </>
                      ) : account?.approval_status === "rejected" &&
                        account?.error_msg?.length > 0 ? (
                        <>
                          <div className="flex flex-row gap-x-3 h-full bg-[#BB5353] p-2.5">
                            <div className="flex place-items-center w-11">
                              <img src={skipping_verification} />
                            </div>
                            <div className="flex flex-col justify-center gap-y-1 w-4/5">
                              <span className="font-semibold font-sans text-xs text-[#FFFFFF]">
                                Failed to take action
                              </span>
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account?.error_msg}
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <span className="font-sans text-[10px] text-[#FAFAFA] text-ellipsis overflow-hidden w-full block">
                                  {account?.error_msg}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      ) : account.approval_status === "pending" &&
                        account.error_msg?.length > 0 ? (
                        <>
                          <div className="flex flex-row h-full w-full bg-[#BB5353] p-2.5">
                            <div className="flex place-items-center w-11">
                              <img
                                src={skipping_verification}
                                className="w-7 h-7 ml-2"
                              />
                            </div>
                            <div className="flex flex-col justify-center gap-y-1 w-4/5">
                              <span className="font-semibold font-sans text-sm text-[#FFFFFF]">
                                Failed to take action
                              </span>

                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account?.error_msg}
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <span className="font-sans text-xs text-[#FAFAFA] text-ellipsis overflow-hidden w-full block">
                                  {account?.error_msg}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </>
                      ) : account.approval_status === "pending" ? (
                        <div
                          className={`flex flex-row w-full ${
                            ViewAccountDetails[0]?.approval_status === "pending"
                              ? "h-[90px]"
                              : "h-full"
                          }`}
                        >
                          <div
                            className={`${
                              services?.find(
                                (service) =>
                                  service === "BAAccount" || service === "Admin"
                              ) !== undefined
                                ? "opacity-100 cursor-pointer hover:opacity-75"
                                : "opacity-50 cursor-not-allowed"
                            } w-1/2 flex justify-center items-center bg-[#ea545b]`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "BAAccount" ||
                                    service === "Admin"
                                ) !== undefined
                              ) {
                                handleDeclinedDetials(account.id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccount",
                                  },
                                });
                              }
                            }}
                            style={{ float: "left", height: "100%" }}
                          >
                            <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                              <div className="decline-image-div flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={declineImage}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                  Reject
                                </Box>
                              </div>
                            </Box>
                          </div>
                          <div
                            className={`${
                              services?.find(
                                (service) =>
                                  service === "BAAccount" || service === "Admin"
                              ) !== undefined
                                ? "opacity-100 cursor-pointer hover:opacity-75"
                                : "opacity-50 cursor-not-allowed"
                            } w-[50%] flex justify-center items-center bg-[#69b76f]`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "BAAccount" ||
                                    service === "Admin"
                                ) !== undefined
                              ) {
                                handleApprovalDetails(account.id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccount",
                                  },
                                });
                              }
                            }}
                            style={{
                              float: "right",
                              height: "100%",
                            }}
                          >
                            <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                              <div className="approve-image-div flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={checkmark}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                  Approve
                                </Box>
                              </div>
                            </Box>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </div>
            <div className="bene_quickaction_containerm">
              <div className="bg-[#506994] px-6 py-5 text-[#dadada] text-lg font-semibold">
                Quick Actions
              </div>
              <div>
                <div>
                  <div className="associated-account-transaction-history-block flex flex-row">
                    <div className="w-1/3 flex-grow">
                      <Box
                        className={`${
                          !isDisabled
                            ? "bg-[#506994] text-center h-full flex flex-col justify-center gap-1 p-2 opacity-50 cursor-not-allowed"
                            : "cursor-pointer hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2 "
                        }`}
                        onClick={() => {
                          if (isDisabled) {
                            openAssociatedAccountsFound();
                          }
                        }}
                      >
                        <div className="flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={associated_accounts_found}
                              alt="document"
                              className="secondary-details-block-image-dimensions"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Associated accounts found
                          </Box>
                        </div>
                      </Box>
                    </div>

                    <div className="py-4 flex">
                      <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                    </div>

                    <div className="w-1/3 flex-grow">
                      <Box
                        className="cursor-pointer hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2"
                        onClick={() => {
                          showToast();
                        }}
                      >
                        <div className="recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={transactionHistory}
                              alt="document"
                              className="secondary-details-block-image-dimensions"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Transaction history
                          </Box>
                        </div>
                      </Box>
                    </div>

                    <div className="py-4 flex">
                      <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                    </div>

                    <div className="w-1/3 flex-grow">
                      <Box
                        className="cursor-pointer hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2"
                        onClick={() => {
                          showToast();
                        }}
                      >
                        <div className="recipient-image-div flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={Category}
                              alt="document"
                              className="secondary-details-block-image-dimensions"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="image-label h-8/12 self-center antialiased truncate...">
                            Categories
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div className="settingsm flex flex-row">
                    {services?.find(
                      (service) =>
                        service === "BUAccount" || service === "Admin"
                    ) === undefined ||
                    viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "archived" ||
                    viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "blocked" ? (
                      <>
                        <div
                          className="flex-grow w-1/2"
                          onClick={() => {
                            if (
                              viewAccountDetailData?.beneficiary_accounts[0]
                                .approval_status !== "archived" &&
                              viewAccountDetailData?.beneficiary_accounts[0]
                                .approval_status !== "blocked"
                            ) {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "BUAccount" ||
                                    service === "Admin"
                                ) !== undefined
                              ) {
                                handleModalOpen(id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BUAccount",
                                  },
                                });
                              }
                            }
                          }}
                        >
                          <Box className="opacity-50 cursor-not-allowed text-center h-full flex flex-col justify-center gap-1 p-2">
                            <div className="edit-image-div flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={editImage}
                                  alt="document"
                                  className="edit-img"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="image-label h-8/12 self-center antialiased truncate...">
                                Edit
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="flex-grow w-1/2"
                          onClick={() => showToast()}
                        >
                          <Box className="cursor-pointer hover:bg-[#667CA3] opacity-100 text-center h-full flex flex-col justify-center gap-1 p-2">
                            <div className="edit-image-div flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={editImage}
                                  alt="document"
                                  className="edit-img"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="image-label h-8/12 self-center antialiased truncate...">
                                Edit
                              </Box>
                            </div>
                          </Box>
                        </div>
                        {/* {isEditModalOpen && (
                          <BeneEditModal onClose={handleModalClose} />
                        )} */}
                      </>
                    )}
                    <div className="py-4 flex">
                      <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                    </div>

                    {viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "blocked" ||
                    services?.find(
                      (service) =>
                        service === "BAAccountArchive" || service === "Admin"
                    ) === undefined ? (
                      <>
                        <div
                          className="flex-grow w-1/2"
                          onClick={() => {
                            if (
                              viewAccountDetailData?.beneficiary_accounts[0]
                                .approval_status !== "blocked"
                            )
                              if (
                                services?.find(
                                  (service) =>
                                    service === "BAAccountArchive" ||
                                    service === "Admin"
                                ) !== undefined
                              ) {
                                handleUnarchive();
                                handleArchive();
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccountArchive",
                                  },
                                });
                              }
                          }}
                        >
                          <Box className="opacity-50 cursor-not-allowed text-center h-full flex flex-col justify-center gap-1 p-2">
                            <div className="recipient-image-div flex justify-center">
                              <Box className="flex justify-center w-10">
                                {services?.find(
                                  (service) =>
                                    service === "BAAccountArchive" ||
                                    service === "Admin"
                                ) === undefined &&
                                viewAccountDetailData?.beneficiary_accounts[0]
                                  .approval_status === "archived" ? (
                                  <img
                                    src={unarchiveImage}
                                    alt="document"
                                    className="secondary-details-block-image-dimensions"
                                  />
                                ) : (
                                  <img
                                    src={archieveImage}
                                    alt="document"
                                    className="secondary-details-block-image-dimensions"
                                  />
                                )}
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate">
                                {services?.find(
                                  (service) =>
                                    service === "BAAccountArchive" ||
                                    service === "Admin"
                                ) === undefined &&
                                viewAccountDetailData?.beneficiary_accounts[0]
                                  .approval_status === "archived"
                                  ? "Unarchive"
                                  : "Archive"}
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </>
                    ) : (
                      <div
                        className="flex-grow w-1/2 hover:bg-[#667CA3]"
                        onClick={() => {
                          if (
                            viewAccountDetailData?.beneficiary_accounts[0]
                              .approval_status !== "blocked"
                          )
                            if (
                              services?.find(
                                (service) =>
                                  service === "BAAccountArchive" ||
                                  service === "Admin"
                              ) !== undefined
                            ) {
                              handleUnarchive();
                              handleArchive();
                            } else {
                              navigate("/feature_not_assigned", {
                                state: {
                                  unavailableService: "BAAccountArchive",
                                },
                              });
                            }
                        }}
                      >
                        <Box
                          className={`${
                            services?.find(
                              (service) =>
                                service === "BAAccountArchive" ||
                                service === "Admin"
                            ) !== undefined
                              ? "cursor-pointer"
                              : ""
                          } text-center h-full flex flex-col justify-center gap-1 p-2`}
                        >
                          {viewAccountDetailData?.beneficiary_accounts[0]
                            .approval_status === "archived" ? (
                            <>
                              <div
                                className={`${
                                  services?.find(
                                    (service) =>
                                      service === "BAAccountArchive" ||
                                      service === "Admin"
                                  ) !== undefined
                                    ? "opacity-100"
                                    : "opacity-50 cursor-not-allowed"
                                } recipient-image-div flex justify-center`}
                              >
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={unarchiveImage}
                                    alt="document"
                                    className="secondary-details-block-image-dimensions"
                                  />
                                </Box>
                              </div>
                              <div
                                className={`${
                                  services?.find(
                                    (service) =>
                                      service === "BAAccountArchive" ||
                                      service === "Admin"
                                  ) !== undefined
                                    ? "opacity-100"
                                    : "opacity-50 cursor-not-allowed"
                                } flex justify-center px-2`}
                              >
                                <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate">
                                  Unarchive
                                </Box>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="recipient-image-div flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={archieveImage}
                                    alt="document"
                                    className="secondary-details-block-image-dimensions"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="image-label h-8/12 self-center text-white text-xs antialiased truncate">
                                  Archive
                                </Box>
                              </div>
                            </>
                          )}
                        </Box>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bene_detailcard_containerm">
              {ViewAccountDetails?.map((account) => (
                <div className="service-card-rowm grid grid-cols-1">
                  <div className="contact-detail-cardm w-full">
                    <div>
                      <h4 className="title">Contact Details</h4>
                      <div>
                        <div>
                          <span className="font-sans text-sm text-[#FFFFFF]">
                            {"+91-" + account.contact_no}
                          </span>
                        </div>
                        <div className="phone">Contact number</div>
                      </div>
                    </div>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            [`& .${tooltipClasses.arrow}`]: {
                              color: (theme) => theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={account?.email_id?.toLowerCase()}
                      arrow
                      placement="bottom"
                      PopperProps={{
                        style: {
                          maxWidth: "none",
                        },
                      }}
                    >
                      <div className="email-section">
                        <div
                          id="email-id-firstLetter"
                          className="email-id-div"
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {account?.email_id?.toLowerCase()}
                        </div>
                        <div className="email">Email ID</div>
                      </div>
                    </Tooltip>
                  </div>

                  <div className="contact-detail-cardm w-full">
                    <div className="w-50 h-50">
                      <h4 className="title">Account Details</h4>
                      <div>
                        <div>
                          <span className="font-sans text-sm text-[#FFFFFF]">
                            {account.pan_no}
                          </span>
                        </div>
                        <div className="pan">PAN details</div>
                      </div>
                    </div>
                    <div
                      className={`gst-details ${
                        account?.gstin_detail ? "hover:bg-[#667CA3]" : ""
                      } div-gst-hover`}
                      onClick={() =>
                        toast.info(
                          "Feature coming soon for mobile!",
                          toast_position
                        )
                      }
                    >
                      <div
                        style={{ marginTop: "10px" }}
                        className="flex items-center justify-between"
                      >
                        <div className="text-sm text-[#FFFFFF] font-sans font-normal opacity-100">
                          {account.gstin_detail
                            ? account.gstin_detail
                            : "Not Given"}
                        </div>

                        {account.gstin_detail && (
                          <button className="flex items-center bg-[#1D3A6D] rounded-md p-1 cursor-pointer ml-2">
                            <img
                              src={arrowRight}
                              alt="document"
                              className="w-3 h-3"
                            />
                          </button>
                        )}
                      </div>

                      <div className="gst-label">GST details</div>
                    </div>
                  </div>

                  <div
                    className={`origin-timeline-cardm w-full ${
                      account?.approval_status === "rejected"
                        ? "h-auto"
                        : "h-[180px]"
                    }`}
                  >
                    <div>
                      <h4 className="title">Origin Timeline</h4>
                      <div className="timeline-section-inner-div">
                        <div className="Approval-part">
                          <div className="approval-name-div">
                            <div className="approval-name w-3/5">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account?.created_by_name}
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                  }}
                                >
                                  {account?.created_by_name}
                                </div>
                              </Tooltip>
                            </div>
                            <div className="approval-date w-2/5 text-right">
                              {account?.created_on
                                ? formatDateViewAll(account?.created_on, false)
                                : "Not given"}
                            </div>
                          </div>
                        </div>
                        <div className="phone">Created by</div>
                      </div>
                    </div>

                    <div className="email-section">
                      {account?.approval_status === "approved" ||
                      account?.approval_status === "rejected" ? (
                        <div>
                          <div>
                            <div className="approved-name">
                              {account?.approval_status === "approved" ? (
                                <>
                                  <span className="w-3/5 truncate">
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={
                                        account?.approved_by_name
                                          ? account?.approved_by_name
                                          : "Not given"
                                      }
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                        }}
                                      >
                                        {account?.approved_by_name
                                          ? account?.approved_by_name
                                          : "Not given"}
                                      </div>
                                    </Tooltip>
                                  </span>
                                  <span className="w-2/5 text-right approval-date">
                                    {account.approved_on
                                      ? formatDateViewAll(
                                          account?.approved_on,
                                          false
                                        )
                                      : "Not given"}
                                  </span>
                                </>
                              ) : account?.rejected_by_name ? (
                                <>
                                  <span className="w-3/5 truncate">
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account?.rejected_by_name}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                        }}
                                      >
                                        {account?.rejected_by_name}
                                      </div>
                                    </Tooltip>
                                  </span>
                                  <span className="w-2/5 text-right approval-date">
                                    {account?.rejected_on
                                      ? formatDateViewAll(
                                          account?.rejected_on,
                                          false
                                        )
                                      : "Not given"}
                                  </span>
                                </>
                              ) : (
                                "Not given"
                              )}
                            </div>
                          </div>

                          <div
                            className={`approved-by ${
                              account?.approval_status === "rejected"
                                ? "denied-border"
                                : ""
                            }`}
                          >
                            {account?.approval_status === "approved"
                              ? "Approved by"
                              : "Rejected by"}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="acc-status-name-last-div">
                            <div className="email-part w-3/5 truncate">
                              {account?.approval_status === "pending" ? (
                                "Approval pending"
                              ) : account?.approval_status === "approved" ? (
                                <>
                                  {account?.approved_by_name
                                    ? account?.approved_by_name
                                    : "Not given"}
                                </>
                              ) : account?.approval_status === "archived" ? (
                                <>
                                  {account?.archived === "Y" ? (
                                    <>
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                              [`& .${tooltipClasses.arrow}`]: {
                                                color: (theme) =>
                                                  theme.palette.common.black,
                                              },
                                            },
                                          },
                                        }}
                                        title={nameArchive}
                                        arrow
                                        placement="bottom"
                                        PopperProps={{
                                          style: {
                                            maxWidth: "none",
                                          },
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                          }}
                                        >
                                          {nameArchive}
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : account?.archived_by_name ? (
                                    <>
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                              [`& .${tooltipClasses.arrow}`]: {
                                                color: (theme) =>
                                                  theme.palette.common.black,
                                              },
                                            },
                                          },
                                        }}
                                        title={account?.archived_by_name}
                                        arrow
                                        placement="bottom"
                                        PopperProps={{
                                          style: {
                                            maxWidth: "none",
                                          },
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                          }}
                                        >
                                          {account?.archived_by_name}
                                        </div>
                                      </Tooltip>
                                    </>
                                  ) : (
                                    "Not given"
                                  )}
                                </>
                              ) : account.approval_status === "blocked" ? (
                                <>
                                  {account?.blocked_by_name ? (
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account?.blocked_by_name?.toLowerCase()}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {account?.blocked_by_name}
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    "Not given"
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            {account.approval_status === "approved" && (
                              <div className="approval-date">
                                {account.approved_on
                                  ? formatDateViewAll(
                                      account.approved_on,
                                      false
                                    )
                                  : "Not given"}
                              </div>
                            )}
                            {account.approval_status === "archived" && (
                              <div className="archive-date">
                                {account.archived_on
                                  ? formatDateViewAll(
                                      account.archived_on,
                                      false
                                    )
                                  : "Not given"}
                              </div>
                            )}
                            {account.approval_status === "blocked" && (
                              <div className="block-date">
                                {account.blocked_on
                                  ? formatDateViewAll(
                                      account?.blocked_on,
                                      false
                                    )
                                  : "Not given"}
                              </div>
                            )}
                          </div>

                          <div className="approved-by">
                            {account.approval_status === "pending"
                              ? "Approved by"
                              : `${capitalize(account?.approval_status)} by`}
                          </div>
                        </div>
                      )}
                      {account.approval_status === "rejected" && (
                        <div className="denied-section w-full">
                          <div className="denial-reason-part break-words whitespace-normal w-full">
                            {account?.rejection_reason}
                          </div>
                          <div className="email">Rejected reason</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="contact-detail-cardm w-full">
                    <div className="w-50 h-50">
                      <h4 className="title">Support Information</h4>
                      <div className="">
                        <div id="id-firstLetter">
                          <span className="font-sans text-sm text-[#FFFFFF]">
                            {account?.id}
                          </span>
                        </div>
                        <div className="support">Account ID</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BeneAccDetails;
