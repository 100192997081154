import React, { useState, useEffect } from "react";
import "./index.css";
import declineImage from "../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import approveImage from "../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import transactionHistory from "../../../assets/GeneralIcons/transaction_history.svg";
import arrowRight from "../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import info from "../../../assets/InfoIcons/Info.svg";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import arrow_right_black from "../../../assets/ArrowsIcons/arrow_right_white – 2.svg";
import arrow_right_white_filled from "../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import arrow_down from "../../../assets/ArrowsIcons/arrow_down_white.svg";
import arrow_up from "../../../assets/ArrowsIcons/arrow_up_white.svg";
import timeline_dot from "../../../assets/RadioIcons/timeline_dot.svg";
import whiteLoader from "../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import skipping_verification from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import BeneIcon from "../../../assets/CategoryIcons/beneficiary_category_default.svg";
import error_with_white_outlined_circle from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import DeclinedPopup from "../../Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import { Tooltip, tooltipClasses } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  beneTxnAccountDetail,
  beneTxnWaitingApproval,
} from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import ISO_DataFormatter from "../../../utils/ISO_DataFormatter";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../../../utils/CurrencyFormater";
import TransactionHistory from "../../../components/TransactionHistoryModal";
import { toast, Bounce } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { formatAccountNumber } from "../../../components/_utils/FormatAccountNumber";
import { BeneTxnRequireApprovalPage } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import { displayPlatform } from "../../../components/_utils/DisplayEnvironment/displayenv";
import { displayBrowser } from "../../../components/_utils/DisplayEnvironment/displayenv";
import { displayOS } from "../../../components/_utils/DisplayEnvironment/displayenv";
import arrowDownBlueWhite from "../../../assets/ArrowsIcons/arrow_down_blue_white.svg";
import closeIcon from "../../../assets/General Icons/close.svg";
import arrowLeftimg from "../../../assets/ArrowsIcons/arrow_left_white.svg";
import BeneAccDetailPagePopup from "../../../components/Accounts/BeneAccDetailPagePopup";

const BeneTxnDetails = ({ bene_txn_id, closeBeneTxnDetailModal, type }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showDetails, setShowDetails] = useState(true);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [viewTxnDetails, setviewTxnDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [beneTxnDetailsID, setbeneTxnDetailsID] = useState("");
  const [openTransHistory, setOpenTransHistory] = useState(false);
  const [infoBox, setInfoBox] = useState(false);
  const [entityId, setEntityId] = useState("");
  const [checkTransaction, setCheckTransaction] = useState(false);
  const [loadingBox, setLoadingBox] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [beneAccountDetailpop, setBeneAccountDetailpop] = useState(false);
  const [showApprovalTimeline, setShowApprovalTimeline] = useState(false);
  const [showDistributionTimeline, setShowDistributionTimeline] =
    useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const {
    beneTxnAccountLoading,
    beneTxnAccountData,
    BeneTxnDeniedApprovalData,
  } = useSelector((state) => state.beneTxnViewAccData);

  const bene_account_id =
    beneTxnAccountData?.transaction?.[0]?.beneficiary_account_id;

  useEffect(() => {
    async function fetchData() {
      if (beneTxnAccountLoading) setLoading(true);
      try {
        dispatch(checkUsertoken());
        let response;
        if (type === "bene_txn_detail_modal") {
          response = await dispatch(beneTxnAccountDetail(bene_txn_id));
        } else {
          response = await dispatch(beneTxnAccountDetail(id));
        }
        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
      } catch (error) {
        console.error("Failed to load the transaction details", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [id, bene_txn_id, dispatch]);

  useEffect(() => {
    if (beneTxnAccountData) {
      const newData = beneTxnAccountData?.transaction?.map((account) => ({
        ...account,
        loading: false,
        error: false,
        message: "",
        last_updated_user_id_check: false,
      }));
      setviewTxnDetails(newData);
      setEntityId(beneTxnAccountData?.transaction[0]?.entity_id);
      setbeneTxnDetailsID(beneTxnAccountData?.transaction[0]?.txn_id);
    }
  }, [beneTxnAccountData]);

  useEffect(() => {
    const fetchTotalPages = async () => {
      try {
        let response = null;
        if (entityId) {
          dispatch(checkUsertoken());
          response = await dispatch(
            BeneTxnRequireApprovalPage({
              entityId: entityId,
              page_no: 1,
            })
          );
        }
        const total_pages = response?.payload?.title?.total_pages || 1;
        setPages(total_pages);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchTotalPages();
  }, [dispatch, entityId]);

  useEffect(() => {
    const fetchAllData = async () => {
      if (!pages) return;
      let allTransactions = [];

      setLoadingBox(true);
      for (let page = 1; page <= pages; page++) {
        try {
          let response = null;
          if (
            entityId &&
            beneTxnAccountData?.transaction[0]?.lifecycle_status ===
              "pending" &&
            services?.find((service) => service === "ABeneficiaryTxn") !==
              undefined
          ) {
            dispatch(checkUsertoken());
            response = await dispatch(
              BeneTxnRequireApprovalPage({
                entityId: entityId,
                page_no: page,
              })
            );
          }

          const transactionsData = response?.payload?.transactions || [];
          allTransactions = [...allTransactions, ...transactionsData];
        } catch (error) {
          console.error(`Error fetching data for page ${page}:`, error);
          break;
        }
      }
      const transactionExists = allTransactions.some(
        (obj) => obj.txn_id === beneTxnDetailsID
      );
      if (transactionExists) {
        setCheckTransaction(transactionExists);
      }
      setLoadingBox(false);
    };
    fetchAllData();
  }, [dispatch, entityId, beneTxnDetailsID, pages]);

  const renderApprovalData = (obj) => {
    return Object.keys(obj).map((key, index, array) => {
      const isLastItem = index === array.length - 1;
      if (key !== "action") {
        return (
          <>
            <div
              key={key}
              className={`flex flex-row items-center ${
                !isLastItem ? "mb-5" : ""
              }`}
            >
              <div className="w-2 border-b-2 border-solid border-[#DADADA]"></div>

              <p
                key={key}
                className="w-full text-[#DADADA] text-sm text-left font-sans font-normal ml-2 make-first-letter-small break-words"
              >{`"${key}": "${obj[key]}"`}</p>
            </div>
          </>
        );
      }
      return null;
    });
  };

  const renderDistributionData = (obj) => {
    return Object.keys(obj).map((key, index, array) => {
      const isLastItem = index === array.length - 1;
      if (key !== "action") {
        return (
          <>
            <div
              key={key}
              className={`flex flex-row items-center ${
                !isLastItem ? "mb-5" : ""
              }`}
            >
              <div className="w-2 border-b-2 border-solid border-[#DADADA]"></div>
              <p
                key={key}
                className="w-full text-[#DADADA] text-sm text-left font-sans font-normal ml-2 make-first-letter-small truncate"
              >{`"${key}": "${obj[key]}"`}</p>
            </div>
          </>
        );
      }
      return null;
    });
  };

  const openTransactionHistory = () => {
    setOpenTransHistory(true);
  };

  const closeTransactionHistory = () => {
    setOpenTransHistory(false);
  };

  const toggleDetails = () => {
    setShowDetails(true);
    setShowAdditionalDetails(false);
  };

  const toggleAdditionalDetails = () => {
    setShowAdditionalDetails(true);
    setShowDetails(false);
  };

  const handleApproveTransaction = async (id) => {
    dispatch(checkUsertoken());

    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "FFFFFF",
      Opacity: 1,
    };

    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };

    const imageStyle = {
      width: "40px",
      height: "40px",
      Opacity: 1,
    };

    toast(
      <div style={divStyle} className="approving-toast-beneTxn">
        <img src={whiteLoader} alt="" style={imageStyle} />
        <p style={paragraphStyle}> Approving transaction </p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        fontSize: "20px",
        transition: Bounce,
        style: {
          width: window.innerWidth > 600 ? "252px" : window.innerWidth + "px",
          height: "84px",
          borderRadius: "10px",
          backgroundColor: "#AE9041",
          color: "#fff",
        },
      }
    );

    setTimeout(() => {
      toast.dismiss();
    }, 1000);

    let res;
    if (type === "bene_txn_detail_modal") {
      res = await dispatch(beneTxnWaitingApproval(bene_txn_id));
    } else {
      res = await dispatch(beneTxnWaitingApproval(id));
    }

    if (res?.payload === undefined) {
      setNetworkError(
        "An error occured while taking action on this transaction"
      );
      setTimeout(() => {
        toast.error(
          "An error occured while taking action on this transaction",
          toast_position
        );
      }, 2000);
    } else if (res?.payload?.errors?.user_unauthorized?.[0]) {
      setTimeout(() => {
        navigate("/feature_not_assigned", {
          state: {
            unavailableService: "VBeneficiaryTxn",
          },
        });
      }, 2000);
    } else if (
      res?.payload?.status === false ||
      res.type === "beneTxnWaitingApproval/rejected"
    ) {
      if (
        res?.payload?.errors?.base[0]?.errors &&
        res?.payload?.errors?.base[0]?.errors[0]?.reason
      ) {
        const error_msg = res?.payload?.errors?.base[0]?.errors[0]?.reason;
        if (!Array.isArray(error_msg)) {
          setErrorMessage(error_msg);
          setTimeout(() => {
            toast.error(error_msg, toast_position);
          }, 1000);
        } else {
          setErrorMessage(res.payload.errors.base[0].errors[0].reason[0]);
          if (res.payload.errors.base[0].errors[0].reason.length >= 1) {
            let msgs = res.payload.errors.base[0].errors[0].reason;
            msgs.forEach((error) => {
              if (res?.payload?.status === false) {
                setTimeout(() => {
                  toast.error(error, toast_position);
                }, 1000);
              }
            });
          }
        }
      } else if (
        res?.payload?.errors?.base[0]?.reason &&
        res?.payload?.errors?.base[0]?.reason[0]
      ) {
        setErrorMessage(res?.payload?.errors?.base[0]?.reason[0]);
        setTimeout(() => {
          toast.error(res?.payload?.errors?.base[0]?.reason[0], toast_position);
        }, 1000);
      } else if (res?.payload?.errors?.base[0]) {
        setErrorMessage(res.payload.errors.base[0]);
        setTimeout(() => {
          toast.error(res.payload.errors.base[0], toast_position);
        }, 2000);
      }
    } else {
      const updatedData = beneTxnAccountData?.transaction?.map((data) => {
        return {
          ...data,
          lifecycle_status: res?.payload?.transactions[0]?.lifecycle_status,
          last_updated_status:
            res?.payload?.transactions[0]?.last_updated_status,
          last_updated_user_id:
            res?.payload?.transactions[0]?.last_updated_user_id,
        };
      });
      setviewTxnDetails(updatedData);
      dispatch(checkUsertoken());
      if (type === "bene_txn_detail_modal") {
        await dispatch(beneTxnAccountDetail(bene_txn_id));
      } else {
        await dispatch(beneTxnAccountDetail(id));
      }

      if (res?.payload?.status === true) {
        setTimeout(() => {
          toast.success(" Transaction approved successfully", toast_position);
        }, 1000);
      }
      if (type === "bene_txn_detail_modal") {
        setTimeout(() => {
          closeBeneTxnDetailModal();
          window.location.reload();
        }, 5000);
      }
    }
  };

  const handleDeclinedDetails = (id) => {
    setbeneTxnDetailsID(id);
    setShowDeclineModal(true);
  };

  const closeDeclineModal = () => {
    setShowDeclineModal(false);
  };

  const closeBeneAccountDetailModal = () => {
    setBeneAccountDetailpop(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    setviewTxnDetails(updatedAccounts);
  };

  const showAmountInfo = () => {
    setInfoBox(!infoBox);
  };

  const closeModal = () => {
    if (infoBox || showDeclineModal) {
      setInfoBox(false);
      setShowDeclineModal(false);
    }
  };

  const showToast = () => {
    toast.info("Feature coming soon for mobile!", toast_position);
  };

  const actionRestricted = () => {
    toast(
      <div className="flex flex-row p-2.5">
        <img src={error_with_white_outlined_circle} className="w-6 h-6" />
        <p className="text-sm ml-2 mt-0.5">Action restricted in popup mode.</p>
      </div>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          // width: window.innerWidth > 600 ? "252px" : window.innerWidth + "px",
          // height: "84px",
          // borderRadius: "10px",
          backgroundColor: "#e74c3c",
          color: "#fff",
        },
      }
    );
  };

  const additional_data = beneTxnAccountData?.transaction[0]?.additional_data;

  const validItems = additional_data?.data ? additional_data?.data : [];

  const loginid = JSON.parse(localStorage.getItem("response"));

  const [openApprovalTimeline, setOpenApprovalTimeline] = useState(
    Array(viewTxnDetails?.[0]?.logs?.length || 0).fill(false)
  );

  const [openDistributionTimeline, setOpenDistributionTimeline] = useState(
    Array(viewTxnDetails?.[0]?.distribution_logs?.length || 0).fill(false)
  );

  const handleApprovalTimeline = (index) => {
    setOpenApprovalTimeline((prevOpenDivs) => {
      if (!Array.isArray(prevOpenDivs)) {
        return [];
      }
      const newOpenDivs = [...prevOpenDivs];
      newOpenDivs[index] = !newOpenDivs[index];
      return newOpenDivs;
    });
    setOpenDistributionTimeline(
      Array(viewTxnDetails[0]?.distribution_logs?.length).fill(false)
    );
  };

  const handleDistributionTimeline = (index) => {
    setOpenDistributionTimeline((prevOpenDivs) => {
      if (!Array.isArray(prevOpenDivs)) {
        return [];
      }
      const newOpenDivs = [...prevOpenDivs];
      newOpenDivs[index] = !newOpenDivs[index];
      return newOpenDivs;
    });
    setOpenApprovalTimeline(Array(viewTxnDetails[0]?.logs?.length).fill(false));
  };

  const handleDistributionClick = () => {
    setShowDistributionTimeline(!showDistributionTimeline);
    if (!showDistributionTimeline) {
      setShowDetails(true);
      setShowAdditionalDetails(false);
      setShowApprovalTimeline(false);
    }
    setOpenApprovalTimeline(false);
  };

  return (
    <>
      <div
        style={{
          height: type === "bene_txn_detail_modal" ? "100%" : "100vh",
          overflowX: "hidden",
          overflowY: showDeclineModal || openTransHistory ? "hidden" : "scroll",
          scrollbarWidth: "none",
        }}
        onClick={closeModal}
      >
        {loading ? (
          <div className="bene-detail-loader-box m-auto justify-center items-center flex">
            <img src={xpentraloader} alt="" />
          </div>
        ) : (
          <div
            className="beneTxnDetailsPage"
            style={{
              marginTop: type === "bene_txn_detail_modal" ? "" : "76px",
            }}
          >
            {type === "bene_txn_detail_modal" && (
              <div
                className={`${
                  type === "bene_txn_detail_modal"
                    ? "flex flex-row justify-between items-center p-5 bg-[#1D3A6D]"
                    : "none"
                }`}
              >
                <div className="flex flex-col space-y-1">
                  <p className="text-base text-[#F9F9F9] font-sans font-semibold">
                    Beneficiary Transaction Details
                  </p>
                </div>
                <div
                  className="flex justify-center items-center cursor-pointer w-7 h-7 rounded-[5px] bg-[#314D7E] hover:bg-[#506994]"
                  onClick={() => closeBeneTxnDetailModal()}
                >
                  <img src={closeIcon} className="w-4 h-4" />
                </div>
              </div>
            )}
            {showDeclineModal && (
              <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
                <DeclinedPopup
                  modal_type="bene_txn_detail_modal"
                  onClose={closeDeclineModal}
                  accountId={beneTxnDetailsID}
                  status="Transaction-bene"
                  allAccounts={viewTxnDetails}
                  updateAllAccounts={updateAllAccounts}
                  closeBeneAccountDetailModal={closeBeneAccountDetailModal}
                  closeBeneTxnDetailModal={closeBeneTxnDetailModal}
                />
              </div>
            )}

            {beneAccountDetailpop && (
              <div className="fixed top-0 left-0 w-screen h-screen z-[9999] bg-[#2fd62c] bg-opacity-80">
                <BeneAccDetailPagePopup
                  closeBeneAccountDetailModal={closeBeneAccountDetailModal}
                  bene_account_id={bene_account_id}
                  type={"bene_account_detail_modal"}
                />
              </div>
            )}

            {openTransHistory && (
              <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                <TransactionHistory
                  bene_account_id={
                    beneTxnAccountData?.transaction[0]?.beneficiary_account_id
                  }
                  bene_account_name={
                    beneTxnAccountData?.transaction[0]?.beneficiary_account_name
                  }
                  onClose={closeTransactionHistory}
                />
              </div>
            )}

            <div className="bene-txn-account-primary-details">
              {beneTxnAccountData?.transaction?.map((account) => (
                <div
                  key={account.txn_id}
                  className={`w-full h-auto ${
                    type === "bene_txn_detail_modal" ? "" : "mt-7"
                  }`}
                >
                  <div className="flex flex-row border-b-2 border-solid border-[#1d3a6d]">
                    <div className="flex flex-row py-5 w-1/2 justify-between">
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row gap-x-1">
                          <div className="text-[#4DDD37] font-sans font-semibold text-base text-left">
                            &#8377; {formatCurrency(account.total_amount)}
                          </div>
                          <div className="relative">
                            <img
                              src={info}
                              className="w-3 h-3 cursor-pointer relative"
                              onClick={showAmountInfo}
                            />
                            {infoBox && (
                              <div
                                className="w-80 h-64 absolute flex flex-col ml-3 top-0 z-10	shadow-2xl"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="h-14 bg-[#506994] rounded-t-[20px] p-5">
                                  <div className="flex flex-row justify-between">
                                    <span className="text-[#C9C9C9] font-sans text-xs">
                                      Transaction amount
                                    </span>
                                    <span className="text-[#4DDD37] font-sans font-semibold text-sm">
                                      &#8377;
                                      {formatCurrency(account.total_amount)}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex-1 bg-[#314D7E] rounded-b-[20px] p-5">
                                  <div className="flex flex-row justify-between">
                                    <span className="text-[#C9C9C9] font-sans text-xs">
                                      Amount debited
                                    </span>
                                    <span className="text-[#FAFAFA] font-sans text-sm">
                                      &#8377;
                                      {formatCurrency(account.total_amount)}
                                    </span>
                                  </div>
                                  <div className="my-3 flex items-center justify-center ">
                                    <span className="w-3.5 h-0.5 rounded-[10px] bg-[#FF5860]"></span>
                                  </div>
                                  <div className="flex flex-row justify-between">
                                    <span className="text-[#C9C9C9] font-sans text-xs">
                                      Tax amount
                                    </span>
                                    <span className="text-[#FAFAFA] font-sans text-sm">
                                      &#8377;
                                      {formatCurrency(account.tax_amount)}
                                    </span>
                                  </div>
                                  <div className="my-3 flex items-center justify-center ">
                                    <span className="w-3.5 h-0.5 rounded-[10px] bg-[#FF5860]"></span>
                                  </div>
                                  <div className="flex flex-row justify-between">
                                    <span className="text-[#C9C9C9] font-sans text-xs">
                                      Payment charge
                                    </span>
                                    <span className="text-[#FAFAFA] font-sans text-sm">
                                      &#8377;
                                      {formatCurrency(account.payment_charge)}
                                    </span>
                                  </div>
                                  <div className="my-5 calculatedFeeSeparation"></div>
                                  <div className="flex flex-row justify-between">
                                    <div className="w-1/2">
                                      <div className="flex flex-row justify-between">
                                        <span className="text-[#C9C9C9] font-sans text-xs mt-px">
                                          Amount credited
                                        </span>
                                        <span className="w-3.5 h-2 text-[#FFFFFF] flex justify-end">
                                          =
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-1/2 flex justify-end">
                                      <span className="text-[#FAFAFA] font-sans text-sm">
                                        &#8377;
                                        {formatCurrency(
                                          account.total_amount -
                                            account.tax_amount -
                                            account.payment_charge
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="text-[#DADADA] font-sans font-normal text-xs text-left">
                          Transaction amount
                        </div>
                      </div>
                      <div className="mr-10 space-y-1">
                        <p className="text-[#F9F9F9] font-sans	text-base text-right">
                          {account.distribution_utr
                            ? account.distribution_utr
                            : "N/A"}
                        </p>
                        <p className="text-[#DADADA] font-sans text-xs text-right">
                          UTR number
                        </p>
                      </div>
                    </div>

                    <div className="border border-solid border-[#1d3a6d] my-3.5"></div>
                    <div className="flex flex-row justify-between py-5 w-1/2">
                      <div className="ml-10 space-y-1 ">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={account.beneficiary_account_name}
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          <p className="text-[#F9F9F9] text-base	text-left	font-sans font-normal capitalize w-[350px] truncate block overflow-ellipsis">
                            {account.beneficiary_account_name.toLowerCase()}
                          </p>
                        </Tooltip>
                        <p className="text-[#DADADA] text-xs text-left	font-sans font-normal">
                          Beneficiary name
                        </p>
                      </div>
                      <div className="space-y-1">
                        <p
                          className={`${
                            account.beneficiary_account_approval_status ===
                            "pending"
                              ? "text-[#31E5E5]"
                              : "text-[#F9F9F9]"
                          } text-base	text-right capitalize font-sans font-normal`}
                        >
                          {account.beneficiary_account_approval_status}
                        </p>
                        <p className="text-[#DADADA] text-xs text-right font-sans font-normal">
                          Account status
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row">
                    <div className="flex flex-row py-5 w-1/2 justify-between">
                      <div
                        className="p-2 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 rounded-lg -ml-2.5"
                        onClick={() =>
                          navigate(
                            `/accounts/owner/owner_account_details/${account.owner_account_id}`
                          )
                        }
                      >
                        <div className="flex flex-col space-y-1">
                          <div className="flex flex-row">
                            <p className="text-[#F9F9F9] text-base font-sans font-normal	text-left">
                              {formatAccountNumber(
                                account.owner_account_bank_acc_no
                              )}
                            </p>
                            <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md ml-5">
                              <img
                                src={arrowRight}
                                alt="document"
                                className="w-2.5 h-2.5 "
                              />
                            </div>
                          </div>
                          <p className="text-[#DADADA] text-xs	font-sans font-normal text-left">
                            Source account
                          </p>
                        </div>
                      </div>
                      <div className="bg-[#ffffff] rounded-[20px] flex items-center justify-around h-7 w-20 mt-2 mr-10">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(account.owner_account_ifsc_code))}
                          alt="Source account bank name"
                          className="w-14 h-3.5"
                        />
                      </div>
                    </div>
                    <div className="border border-solid border-[#1d3a6d] mt-4 h-11"></div>
                    <div className="flex flex-row py-5 w-1/2 justify-between">
                      <div
                        className="p-2 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 rounded-lg ml-8"
                        onClick={() =>
                          navigate(
                            `/accounts/beneficiary_account/details/${account.beneficiary_account_id}`
                          )
                        }
                      >
                        <div className="flex flex-col space-y-1">
                          <div className="flex flex-row">
                            <p className="text-[#F9F9F9] text-base font-sans font-normal	text-left">
                              {formatAccountNumber(
                                account.beneficiary_account_bank_acc_no
                              )}
                            </p>
                            <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md ml-5">
                              <img
                                src={arrowRight}
                                alt="document"
                                className="w-2.5 h-2.5 "
                              />
                            </div>
                          </div>
                          <p className="text-[#DADADA] text-xs	font-sans font-normal text-left">
                            Destination account
                          </p>
                        </div>
                      </div>
                      <div className="bg-[#ffffff] rounded-[20px] flex items-center justify-around h-7 w-20 mt-2">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(
                              account.beneficiary_account_ifsc_code
                            ))}
                          alt="Destination account bank name"
                          className="w-14 h-3.5"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <img
                      src={arrow_right_black}
                      className="w-5 h-5 -mt-9"
                      alt="Transaction"
                    />
                  </div>

                  <div className="flex flex-row -mt-6">
                    <div className="flex flex-row py-5 w-1/2 justify-between">
                      <div className="flex flex-col space-y-1">
                        <div className="flex flex-row">
                          <p className="text-[#F9F9F9] text-base font-sans font-normal	text-left">
                            {account.owner_account_ifsc_code}
                          </p>
                        </div>
                        <p className="text-[#DADADA] text-xs	font-sans font-normal text-left">
                          IFSC code
                        </p>
                      </div>
                      <div className="flex flex-col space-y-1 mr-10">
                        <p className="text-[#F9F9F9] text-base font-sans font-normal	text-right">
                          {account.distribution_mode_of_payment
                            ? account.distribution_mode_of_payment
                            : account.payment_method
                            ? account.payment_method
                            : "N/A"}
                        </p>
                        <p className="text-[#DADADA] text-xs	font-sans font-normal text-right">
                          Payment method
                        </p>
                      </div>
                    </div>
                    <div className="border border-solid border-[#1d3a6d] h-10 mt-7"></div>
                    <div className="flex flex-row w-1/2 justify-between">
                      <div className="flex flex-col space-y-1 py-5 ml-10">
                        <div className="flex flex-row">
                          <p className="text-[#F9F9F9] text-base font-sans font-normal	text-left">
                            {account.beneficiary_account_ifsc_code}
                          </p>
                        </div>
                        <p className="text-[#DADADA] text-xs	font-sans font-normal text-left">
                          IFSC code
                        </p>
                      </div>

                      <div className="flex flex-row w-44 bg-[#506994] rounded-tl-[15px] rounded-tr-[15px] overflow-hidden">
                        <div
                          className={`w-1/2 flex flex-col justify-center place-items-center space-y-1 ${
                            type === "bene_txn_detail_modal"
                              ? "opacity-50 cursor-default"
                              : "hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer"
                          }`}
                          onClick={() => {
                            if (type === "bene_txn_detail_modal") {
                              actionRestricted();
                            } else {
                              openTransactionHistory();
                            }
                          }}
                        >
                          <img src={transactionHistory} className="w-8 h-8" />
                          <p className="text-[#F9F9F9] text-[10px] text-center font-sans font-normal">
                            Transaction history
                          </p>
                        </div>
                        <div className="border border-solid border-[#1d3a6d] my-3"></div>
                        <div
                          className={`w-1/2 flex flex-col place-items-center p-2 space-y-1 ${
                            type === "bene_txn_detail_modal"
                              ? "opacity-50 cursor-default"
                              : "cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                          }`}
                          onClick={() => {
                            if (type === "bene_txn_detail_modal") {
                              actionRestricted();
                            } else {
                              navigate(
                                `/transaction/beneficiary_transaction/view_all`,
                                {
                                  state: {
                                    category: account?.category,
                                    approvalStatus: "all",
                                  },
                                }
                              );
                            }
                          }}
                        >
                          <img
                            src={account.logo_url ? account.logo_url : BeneIcon}
                            className="w-9 h-8"
                          />
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={account?.category}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <p className="text-[#F9F9F9] text-[10px] text-center font-sans font-normal block w-full truncate">
                              {account?.category}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className={`${
                type === "bene_txn_detail_modal" ? "w-full" : "w-screen"
              } bg-[#1D3A6D]`}
            >
              <div className="flex flex-row w-full max-w-5xl m-auto">
                <div
                  className={`flex flex-col w-1/2 h-auto mt-5 rounded-t-[15px] rounded-bl-[15px] overflow-hidden relative ${
                    beneTxnAccountData?.transaction[0]
                      ?.distribution_lifecycle_status ===
                      "distribution_complete" ||
                    beneTxnAccountData?.transaction[0]?.lifecycle_status ===
                      "abandoned" ||
                    (beneTxnAccountData?.transaction[0]?.lifecycle_status ===
                      "approved" &&
                      beneTxnAccountData?.transaction[0]
                        ?.beneficiary_account_approval_status !== "pending")
                      ? "bg-[#1D3A6D]"
                      : "bg-[#102C5D]"
                  }`}
                >
                  <div className="pt-px bg-[#314D7E]">
                    <p className="text-[#DADADA] text-xs text-left font-sans font-normal my-2 ml-5">
                      Transaction Timeline
                    </p>
                  </div>
                  <div className="border border-solid border-[#102C5D]"></div>
                  <div className="flex flex-row flex-grow">
                    <div
                      className="Approval-box w-1/2 cursor-pointer bg-[#314D7E] hover:bg-[#506994]"
                      onClick={() => {
                        setShowApprovalTimeline(!showApprovalTimeline);
                        if (!showApprovalTimeline) {
                          setShowDetails(true);
                          setShowAdditionalDetails(false);
                          setShowDistributionTimeline(false);
                        }
                        setOpenDistributionTimeline(false);
                      }}
                    >
                      <div className="flex flex-row justify-between mt-2 mx-5">
                        <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                          Stage 1 - Approval
                        </p>
                        <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                          <img
                            src={showApprovalTimeline ? arrow_up : arrow_down}
                            alt="document"
                            className="w-2.5 h-2.5 "
                          />
                        </div>
                      </div>
                      {viewTxnDetails?.length >= 1 &&
                        viewTxnDetails?.map((account) =>
                          account?.lifecycle_status === "rejected" ? (
                            <>
                              <p className="text-[#FF5860] text-sm text-left capitalize font-sans font-semibold mt-2.5 mb-1 ml-5">
                                {account.lifecycle_status}
                              </p>
                            </>
                          ) : account?.lifecycle_status === "approved" ? (
                            <>
                              <p className="text-[#B196FA] text-sm text-left capitalize font-sans font-semibold mt-2.5 mb-1 ml-5">
                                {account.lifecycle_status}
                              </p>
                            </>
                          ) : account?.lifecycle_status === "abandoned" ? (
                            <>
                              <p className="text-[#B4B4B4] text-sm text-left capitalize font-sans font-semibold mt-2.5 mb-1 ml-5">
                                {account.lifecycle_status}
                              </p>
                            </>
                          ) : account.last_updated_status === "Approved" &&
                            account.last_updated_user_id ===
                              loginid?.employees?.id ? (
                            <>
                              <p className="text-[#31E5E5] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5">
                                Awaiting approval
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="text-[#31E5E5] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5">
                                {services?.find(
                                  (service) => service === "ABeneficiaryTxn"
                                ) === undefined
                                  ? "Awaiting approval"
                                  : "Require approval"}
                              </p>
                            </>
                          )
                        )}

                      <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal ml-5">
                        Status
                      </p>
                    </div>
                    <div className="bg-[#314D7E]">
                      <div className="border border-solid border-[#102C5D] h-16 mt-2"></div>
                    </div>
                    <div
                      className={`Distribution-box w-1/2 rounded-br-[15px] ${
                        viewTxnDetails?.length >= 1 &&
                        viewTxnDetails[0]?.distribution_lifecycle_status
                          ? "bg-[#314D7E] hover:bg-[#506994] cursor-pointer"
                          : "bg-[#314D7E] cursor-default"
                      }`}
                      onClick={
                        viewTxnDetails?.length >= 1 &&
                        viewTxnDetails[0]?.distribution_lifecycle_status
                          ? handleDistributionClick
                          : null
                      }
                    >
                      <div className="flex flex-row justify-between mt-2 mx-5">
                        <p
                          className={`text-[#DADADA] text-xs text-left font-sans font-normal ${
                            viewTxnDetails?.length >= 1 &&
                            viewTxnDetails[0]?.distribution_lifecycle_status
                              ? "opacity-100"
                              : "opacity-50"
                          }`}
                        >
                          Stage 2 - Distribution
                        </p>
                        <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                          <img
                            src={
                              showDistributionTimeline ? arrow_up : arrow_down
                            }
                            alt="document"
                            className={`w-2.5 h-2.5 ${
                              viewTxnDetails?.length >= 1 &&
                              viewTxnDetails[0]?.distribution_lifecycle_status
                                ? "opacity-100"
                                : "opacity-50"
                            }`}
                          />
                        </div>
                      </div>
                      {viewTxnDetails?.map((account) =>
                        account?.distribution_lifecycle_status ===
                        "distribution_complete" ? (
                          <>
                            <p className="text-[#4DDD37] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                              Distribution complete
                            </p>
                          </>
                        ) : account?.distribution_lifecycle_status ===
                            "rejected" ||
                          account?.distribution_lifecycle_status ===
                            "failed" ? (
                          <>
                            <p className="text-[#FF5860] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                              {`Distribution ${account?.distribution_lifecycle_status}`}
                            </p>
                          </>
                        ) : account?.distribution_lifecycle_status ===
                          "abandoned" ? (
                          <>
                            <p className="text-[#B4B4B4] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                              {"Distribution".concat(
                                " ",
                                account.distribution_lifecycle_status
                              )}
                            </p>
                          </>
                        ) : account?.distribution_lifecycle_status ? (
                          <>
                            <p className="text-[#FBC02D] text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5 opacity-100">
                              {account.distribution_lifecycle_status ===
                              "created"
                                ? "Distribution created"
                                : account.distribution_lifecycle_status ===
                                  "approved"
                                ? "Distribution approved"
                                : account.distribution_lifecycle_status ===
                                  "awaiting_funds"
                                ? "Awaiting funds"
                                : account.distribution_lifecycle_status ===
                                  "distribution_initiated"
                                ? "Distribution initiated"
                                : account.distribution_lifecycle_status}
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              className={`${
                                account?.distribution_lifecycle_status
                                  ? "opacity-100 text-[#FBC02D]"
                                  : "opacity-50 text-[#F9F9F9]"
                              } text-sm text-left font-sans font-semibold mt-2.5 mb-1 ml-5`}
                            >
                              {account?.distribution_lifecycle_status
                                ? account?.distribution_lifecycle_status
                                : "N/A"}
                            </p>
                          </>
                        )
                      )}

                      <p
                        className={`text-[#DADADA] text-[10px] text-left font-sans font-normal ml-5 ${
                          beneTxnAccountData?.transaction[0]
                            ?.distribution_lifecycle_status
                            ? "opacity-100"
                            : "opacity-50"
                        }`}
                      >
                        Status
                      </p>
                    </div>
                  </div>
                  {viewTxnDetails?.length >= 1 &&
                    viewTxnDetails?.map((account) =>
                      account?.lifecycle_status === "rejected" ? (
                        <>
                          <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-rejected-approval absolute top-0.5 left-0 px-0.5 pt-px"
                                value={0.5}
                              />
                            </div>
                          </div>
                        </>
                      ) : account?.distribution_lifecycle_status ===
                          "rejected" ||
                        account?.distribution_lifecycle_status === "failed" ? (
                        <>
                          <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-rejected-approval absolute top-0.5 left-0 px-0.5 pt-px"
                                value={1}
                              />
                            </div>
                          </div>
                        </>
                      ) : account?.lifecycle_status === "abandoned" ||
                        account?.distribution_lifecycle_status ===
                          "abandoned" ? (
                        <>
                          <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-abandoned-transaction absolute top-0.5 left-0 px-0.5 pt-px"
                                value={`${
                                  account?.lifecycle_status === "abandoned"
                                    ? 0.5
                                    : account?.distribution_lifecycle_status ===
                                      "abandoned"
                                    ? 1
                                    : 0.5
                                }`}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="px-5 h-3 absolute w-full top-28 mt-1.5">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-require-approval absolute top-0.5 left-0 px-0.5 pt-px"
                                value={`${
                                  account?.distribution_lifecycle_status ===
                                  "distribution_complete"
                                    ? 1
                                    : account?.distribution_lifecycle_status
                                    ? 0.75
                                    : 0.25
                                }`}
                              />
                            </div>
                          </div>
                        </>
                      )
                    )}
                </div>
                {viewTxnDetails?.map((account) =>
                  loadingBox && account.lifecycle_status === "pending" ? (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-3/5 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                        <div className="flex justify-center items-center w-2/5 h-[106px] mt-[52px] bg-[#102C5D] rounded-tr-[15px] rounded-br-[15px]">
                          <img
                            src={xpentraloader}
                            className="w-10 h-10 loader"
                          />
                        </div>
                      </div>
                    </>
                  ) : networkError || errorMessage || account.message ? (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                        <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#BB5353] rounded-tr-[15px] rounded-br-[15px] p-4 space-y-0.5">
                          <div className="flex flex-row h-full">
                            <div className="flex-none w-[10%] mt-2">
                              <img
                                src={skipping_verification}
                                className="h-7 w-7"
                              />
                            </div>
                            <div className="flex-1 flex-col space-y-1 ml-2.5">
                              <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal">
                                Failed to take action
                              </p>

                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  networkError
                                    ? "An error occurred while taking action on this transaction"
                                    : errorMessage
                                    ? errorMessage
                                    : account.message
                                    ? account.message
                                    : ""
                                }
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <p className="text-[#DADADA] text-xs text-left font-sans font-normal line-clamp">
                                  {networkError
                                    ? "An error occurred while taking action on this transaction"
                                    : errorMessage
                                    ? errorMessage
                                    : account.message
                                    ? account.message
                                    : ""}
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : account.last_updated_status === "Approved" &&
                    account.lifecycle_status === "pending" &&
                    account.last_updated_user_id === loginid?.employees?.id ? (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                        <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] p-5 space-y-1">
                          <p className="text-[#31E5E5] text-sm text-left font-sans font-normal">
                            Further approval pending
                          </p>
                          <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                            This transaction has been approved by you, but it
                            requires another user's approval before settlement.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : account.last_updated_status === "Approved" &&
                    account.lifecycle_status === "pending" &&
                    services?.find(
                      (service) => service === "ABeneficiaryTxn"
                    ) === undefined ? (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                        <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] p-5 space-y-1">
                          <p className="text-[#31E5E5] text-sm text-left font-sans font-normal">
                            Further approval pending
                          </p>
                          <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                            This transaction requires another user's approval
                            before settlement.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : account?.lifecycle_status === "rejected" ? (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                        <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] p-5 space-y-0.5">
                          <p className="text-[#FF5860] text-sm text-left font-sans font-normal">
                            Rejected reason
                          </p>

                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={account.rejection_reason}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <p className="text-[#DADADA] text-xs text-justify font-sans font-normal line-clamp">
                              {account.rejection_reason}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  ) : account?.beneficiary_account_approval_status ===
                    "pending" ? (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-1/12 h-[106px] mt-[52px] bg-[#102C5D]"></div>
                        <div className="flex-grow w-11/12 h-[106px] mt-[52px] bg-[#314D7E] rounded-tr-[15px] rounded-br-[15px] px-5 py-4">
                          <p className="text-[#31E5E5] text-sm text-left font-sans font-normal">
                            Account approval pending
                          </p>
                          <p className="text-[#DADADA] text-xs text-left font-sans font-normal mt-1">
                            {services?.find(
                              (service) => service === "ABeneficiaryTxn"
                            ) !== undefined
                              ? "Account is not approved, approve account before approving transaction."
                              : "To approve the account, please contact the authorized person."}
                          </p>
                          <div
                            className={`flex justify-between items-center w-[150px] h-7 py-1 px-2.5 mt-2 rounded-[5px] bg-[#1D3A6D] ${
                              services?.find(
                                (service) => service === "ABeneficiaryTxn"
                              ) !== undefined
                                ? "opacity-100 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                                : "opacity-50 cursor-not-allowed"
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "BAAccount"
                                ) !== undefined
                              ) {
                                setBeneAccountDetailpop(true);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccount",
                                  },
                                });
                              }
                            }}
                          >
                            <p className="text-xs text-[#FFFFFF] font-sans font-regular">
                              Approve Account
                            </p>
                            <img
                              src={arrow_right_white_filled}
                              className="w-3 h-3"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : account?.beneficiary_account_approval_status ===
                      "approved" && account.lifecycle_status === "pending" ? (
                    <>
                      <div className="w-1/2 flex flex-row mt-[52px] bg-[#102C5D] rounded-tr-[15px] rounded-br-[15px]">
                        <div className="flex-grow w-3/5 h-[106px]"></div>
                        <div
                          className={`flex flex-row w-2/5 h-[106px] rounded-[15px] overflow-hidden ${
                            checkTransaction ? "opacity-100" : "opacity-50"
                          }`}
                        >
                          <div
                            className={`w-1/2 h-full bg-[#F05A6A]  flex flex-col justify-center place-items-center space-y-1 ${
                              checkTransaction
                                ? "hover:opacity-80 cursor-pointer"
                                : "cursor-not-allowed"
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "ABeneficiaryTxn"
                                ) !== undefined &&
                                checkTransaction
                              ) {
                                handleDeclinedDetails(account.txn_id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "ABeneficiaryTxn",
                                  },
                                });
                              }
                            }}
                          >
                            <img src={declineImage} className="w-7 h-7" />
                            <p className="text-[#FFFFFF] text-xs text-center font-sans font-normal">
                              Reject
                            </p>
                          </div>
                          <div
                            className={`w-1/2 h-full bg-[#69B76F] flex flex-col justify-center place-items-center space-y-1 ${
                              checkTransaction
                                ? "hover:opacity-80 cursor-pointer"
                                : " cursor-not-allowed"
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "ABeneficiaryTxn"
                                ) !== undefined &&
                                checkTransaction
                              ) {
                                handleApproveTransaction(account.txn_id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "ABeneficiaryTxn",
                                  },
                                });
                              }
                            }}
                          >
                            <img src={approveImage} className="w-7 h-7" />
                            <p className="text-[#FFFFFF] text-xs text-center font-sans font-normal">
                              Approve
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-1/2 flex flex-row">
                        <div className="flex-grow w-5/12 h-[106px] mt-[52px] bg-[#1D3A6D]"></div>
                        <div className="flex-grow w-7/12 h-[106px] mt-[52px] bg-[#1D3A6D]"></div>
                      </div>
                    </>
                  )
                )}
              </div>
            </div>

            {showApprovalTimeline ? (
              <>
                <div className="bg-[#1D3A6D] py-10">
                  {viewTxnDetails?.length >= 1 &&
                    [...viewTxnDetails[0]?.logs]
                      ?.reverse()
                      .map((account, index) => {
                        const trackCount =
                          (index + 1) % viewTxnDetails[0]?.logs?.length === 0;
                        const logs_length = viewTxnDetails[0]?.logs?.length;
                        const num = index + 1;
                        return (
                          <>
                            <div
                              key={index}
                              className="flex fex-row items-start w-full max-w-5xl m-auto space-x-10 relative"
                            >
                              <div
                                className={`absolute border-l-2 border-solid border-[#506994] w-[4%] ${
                                  num === logs_length
                                    ? "h-10 duration-500 ease-in-out"
                                    : openApprovalTimeline[index]
                                    ? "h-full duration-500 ease-in-out"
                                    : "h-20 duration-500 ease-in-out"
                                }`}
                              >
                                <div className="absolute flex flex-row w-full">
                                  <img
                                    src={timeline_dot}
                                    className="w-5 h-5 mt-[31px] ml-[-11px]"
                                  />
                                  <div className="w-6 border-t-2 mt-10 ml-1 border-solid border-[#506994]"></div>
                                </div>

                                {showApprovalTimeline && num === 1 && (
                                  <>
                                    <div className="absolute border-l-2 border-solid border-[#506994] h-6 ml-[-1.75px] -mt-6"></div>
                                    <div className="absolute border-t-2 border-solid border-[#506994] w-36 ml-0 -mt-6"></div>
                                    <div className="absolute border-r-2 border-solid border-[#506994] h-[18px] ml-36 -mt-10"></div>
                                  </>
                                )}
                              </div>

                              <div
                                className={`flex flex-col w-full px-5 py-5 rounded-[15px] bg-[#314D7E] hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer overflow-hidden approval-data ${
                                  openApprovalTimeline[index]
                                    ? "h-auto"
                                    : "h-20"
                                }`}
                                onClick={() => handleApprovalTimeline(index)}
                              >
                                <div className="flex flex-row items-center">
                                  <div className="flex flex-row items-center w-3/12 border-r-2 border-solid border-[#1d3a6d]">
                                    <div className="flex flex-col space-y-1 ml-2">
                                      <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                        {account.action === "Add Status"
                                          ? account.remark.toLowerCase()
                                          : account.action.toLowerCase()}
                                      </p>
                                      <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                        Action
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center w-9/12">
                                    {account?.browser_name && (
                                      <div className="flex flex-row items-center w-1/3 border-r-2 border-solid border-[#1d3a6d]">
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayBrowser(
                                              account?.browser_name
                                            ) +
                                            ".svg")}
                                          alt="Browser"
                                          className="w-7 h-7 ml-4"
                                        />
                                        <div className="flex flex-col space-y-1 ml-3">
                                          <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                            {account?.browser_name ===
                                            "Microsoft Edge (Chromium)"
                                              ? "Microsoft Edge"
                                              : account.browser_name}
                                          </p>
                                          <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                            Browser
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    {account?.platform && (
                                      <div className="flex flex-row items-center w-1/3 border-r-2 border-solid border-[#1d3a6d]">
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayPlatform(account?.platform) +
                                            ".svg")}
                                          alt="Platform"
                                          className="w-7 h-7 ml-4"
                                        />
                                        <div className="flex flex-col space-y-1 ml-3">
                                          <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                            {account?.platform}
                                          </p>
                                          <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                            Platform
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                    {account?.os_version && (
                                      <div className="flex flex-row items-center w-1/3">
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayOS(account?.os_version) +
                                            ".svg")}
                                          alt="OS version"
                                          className="w-7 h-7 ml-4"
                                        />
                                        <div className="flex flex-col space-y-1 ml-3">
                                          <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                            {account?.os_version}
                                          </p>
                                          <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                            Operating system
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                                    <img
                                      src={
                                        openApprovalTimeline[index]
                                          ? arrow_up
                                          : arrow_down
                                      }
                                      alt="document"
                                      className="w-2.5 h-2.5 "
                                    />
                                  </div>
                                </div>
                                {openApprovalTimeline[index] && (
                                  <>
                                    <div className="ml-8 mt-5">
                                      {renderApprovalData(account)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {num !== logs_length && (
                              <>
                                <div className="max-w-5xl m-auto h-5 border-l-2 border-solid border-[#506994]"></div>
                              </>
                            )}
                          </>
                        );
                      })}
                </div>
              </>
            ) : showDistributionTimeline ? (
              <>
                <div className="bg-[#1D3A6D] py-10">
                  {viewTxnDetails?.length >= 1 &&
                    [...viewTxnDetails[0]?.distribution_logs]
                      ?.reverse()
                      .map((account, index) => {
                        const trackCount =
                          (index + 1) %
                            viewTxnDetails[0]?.distribution_logs?.length ===
                          0;
                        const distributionLogs_length =
                          viewTxnDetails[0]?.distribution_logs?.length;
                        const num = index + 1;
                        return (
                          <>
                            <div
                              key={index}
                              className="flex fex-row items-start w-full max-w-5xl m-auto space-x-10 relative"
                            >
                              <div
                                className={`absolute border-l-2 border-solid border-[#506994] w-[4%] ${
                                  num === distributionLogs_length
                                    ? "h-10 duration-500 ease-in-out"
                                    : openDistributionTimeline[index]
                                    ? "h-full duration-500 ease-in-out"
                                    : "h-20 duration-500 ease-in-out"
                                }`}
                              >
                                <div className="absolute flex flex-row w-full">
                                  <img
                                    src={timeline_dot}
                                    className="w-5 h-5 mt-[31px] ml-[-11px]"
                                  />
                                  <div className="w-6 border-t-2 mt-10 ml-1 border-solid border-[#506994]"></div>
                                </div>

                                {showDistributionTimeline && num === 1 && (
                                  <>
                                    <div className="absolute border-l-2 border-solid border-[#506994] h-6 ml-[-1.75px] -mt-6"></div>
                                    <div className="absolute border-t-2 border-solid border-[#506994] w-96 ml-0 -mt-6"></div>
                                    <div className="absolute border-r-2 border-solid border-[#506994] h-[18px] ml-96 -mt-10"></div>
                                  </>
                                )}
                              </div>
                              <div
                                className={`flex flex-col w-full px-5 py-5 rounded-[15px] bg-[#314D7E] hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer overflow-hidden distribution-data ${
                                  openDistributionTimeline[index]
                                    ? "h-auto"
                                    : "h-20"
                                }`}
                                onClick={() =>
                                  handleDistributionTimeline(index)
                                }
                              >
                                <div className="flex flex-row justify-between items-center">
                                  <div className="flex flex-row items-center">
                                    <div className="flex flex-col space-y-1 ml-2">
                                      <p className="text-[#FCFCFC] text-sm text-left font-sans font-normal capitalize">
                                        {account.action === "Add Status"
                                          ? account.remark.toLowerCase()
                                          : account.action.toLowerCase()}
                                      </p>
                                      <p className="text-[#DADADA] text-[10px] text-left font-sans font-normal">
                                        Action
                                      </p>
                                    </div>
                                  </div>
                                  <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                                    <img
                                      src={
                                        openDistributionTimeline[index]
                                          ? arrow_up
                                          : arrow_down
                                      }
                                      alt="document"
                                      className="w-2.5 h-2.5 "
                                    />
                                  </div>
                                </div>
                                {openDistributionTimeline[index] && (
                                  <>
                                    <div className="ml-2 mt-5">
                                      {renderDistributionData(account)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {num !== distributionLogs_length && (
                              <>
                                <div className="max-w-5xl m-auto h-5 border-l-2 border-solid border-[#506994]"></div>
                              </>
                            )}
                          </>
                        );
                      })}
                </div>
              </>
            ) : (
              <div
                className={`details-div-trans mb-5 bg-[#1d3a6d] ${
                  type === "bene_txn_detail_modal" ? "w-full" : "w-screen"
                }`}
              >
                <div className="detail-section-trans h-auto bg-[#1d3a6d] m-auto max-w-5xl">
                  <div className="buttonsfordetails">
                    <button
                      className={`DetailsButton w-20 h-7 mr-5 ${
                        showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                      }`}
                      onClick={toggleDetails}
                    >
                      <span
                        className={`text-xs text-center font-sans font-semibold ${
                          showDetails ? "text-black" : "text-white"
                        } `}
                      >
                        Details
                      </span>
                    </button>
                    <button
                      className={`AdditionalDetailsButton w-36 h-7 ${
                        showAdditionalDetails
                          ? "effective"
                          : "hover:bg-[#A3A3A3]"
                      }`}
                      onClick={toggleAdditionalDetails}
                    >
                      <span
                        className={`text-xs text-center font-sans font-semibold ${
                          showAdditionalDetails ? "text-black" : "text-white"
                        }`}
                      >
                        Additional Details
                      </span>
                    </button>
                  </div>
                  {showDetails &&
                    viewTxnDetails?.map((account) => (
                      <div
                        className={`flex my-8 ${
                          account?.distribution_lifecycle_status ===
                          "distribution_complete"
                            ? "justify-between"
                            : "space-x-5"
                        }`}
                      >
                        <div
                          className="
                           w-[328px]
                        h-auto px-7 py-5 rounded-[20px] bg-[#314D7E]"
                        >
                          <p className="text-[#DADADA] text-sm text-left font-sans font-semibold pb-5">
                            Origin Details
                          </p>
                          <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal mb-1 truncate">
                            {formatDateViewAll(account?.created_on, true, true)}
                          </p>
                          <p className="text-[#DADADA] text-xs text-left font-sans font-normal border-b border-solid border-[#102C5D] pb-2.5">
                            Initiated on
                          </p>
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={
                              account?.logs?.[0]?.first_name +
                              " " +
                              account?.logs?.[0]?.last_name[0].toUpperCase() +
                              account?.logs?.[0]?.last_name.slice(1)
                            }
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal pt-2.5 mb-1 truncate">
                              {account?.logs?.[0]?.first_name +
                                " " +
                                account?.logs?.[0]?.last_name[0].toUpperCase() +
                                account?.logs?.[0]?.last_name.slice(1)}
                            </p>
                          </Tooltip>
                          <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                            Initiated by
                          </p>
                        </div>

                        {account?.distribution_lifecycle_status ===
                        "distribution_complete" ? (
                          <>
                            <div
                              className="
                           w-[328px]
                        h-auto px-7 py-5 rounded-[20px] bg-[#314D7E]"
                            >
                              <p className="text-[#DADADA] text-sm text-left font-sans font-semibold pb-5">
                                Settlement Details
                              </p>
                              <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal mb-1">
                                {account?.distribution_credited_at
                                  ? ISO_DataFormatter(
                                      account?.distribution_credited_at
                                    )
                                  : "N/A"}
                              </p>
                              <p className="text-[#DADADA] text-xs text-left font-sans font-normal border-b border-solid border-[#102C5D] pb-2.5">
                                Settled on
                              </p>
                              <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal pt-2.5 mb-1 block w-full truncate">
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                        [`& .${tooltipClasses.arrow}`]: {
                                          color: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account.distribution_settlement_id}
                                  arrow
                                  placement="bottom"
                                  PopperProps={{
                                    style: {
                                      maxWidth: "none",
                                    },
                                  }}
                                >
                                  <p className="text-[#F9F9F9] text-sm font-sans font-normal text-left truncate block w-full mb-1">
                                    {account?.distribution_settlement_id
                                      ? account?.distribution_settlement_id
                                      : "N/A"}
                                  </p>
                                </Tooltip>
                              </p>
                              <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                                Settlement ID
                              </p>
                            </div>
                            <div className="w-[328px] h-auto px-7 pt-5 rounded-[20px] bg-[#314D7E]">
                              <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5">
                                Support Information
                              </p>

                              <p
                                id="id-firstLetter"
                                className="text-[#F9F9F9] text-sm font-sans font-normal text-left block w-full mb-1"
                              >
                                {account.txn_id}
                              </p>

                              <p className="text-[#DADADA] text-xs font-sans font-normal text-left">
                                Transaction ID
                              </p>
                            </div>
                          </>
                        ) : (
                          <div className="w-[328px] h-auto px-7 pt-5 rounded-[20px] bg-[#314D7E]">
                            <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5">
                              Support Information
                            </p>

                            <p
                              id="id-firstLetter"
                              className="text-[#F9F9F9] text-sm font-sans font-normal text-left block w-full mb-1"
                            >
                              {account.txn_id}
                            </p>
                            <p className="text-[#DADADA] text-xs font-sans font-normal text-left">
                              Transaction ID
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                  {showAdditionalDetails && (
                    <>
                      <div
                        className={`my-8 p-5 bg-[#314D7E] h-auto rounded-[20px] w-${
                          validItems?.length === 0
                            ? "2/5"
                            : validItems?.length === 1
                            ? "2/6"
                            : validItems?.length === 2
                            ? "4/6"
                            : "full"
                        }`}
                      >
                        <p className="text-[#DADADA] text-sm text-left font-sans font-semibold mb-5">
                          Additional Details
                        </p>

                        {validItems?.length === 0 ? (
                          <>
                            <p className="text-left text-[#DADADA] text-xs font-sans font-normal">
                              Additional details are not supported for the
                              selected category.
                            </p>
                          </>
                        ) : (
                          <div className="mx-auto">
                            {validItems?.map((obj, index) => {
                              if (index % 3 === 0) {
                                const isLastRow =
                                  index + 3 >= validItems?.length;
                                const isFirstRow = index === 0;

                                return (
                                  <div
                                    key={index}
                                    className={`flex flex-row ${
                                      !isFirstRow ? "mt-2.5" : ""
                                    }`}
                                  >
                                    {validItems
                                      .slice(index, index + 3)
                                      .map((subItem, subIndex) => {
                                        const isLastInRow = subIndex === 2;
                                        const nextItemExists =
                                          validItems[index + subIndex + 1] !==
                                          undefined;
                                        return (
                                          <>
                                            <div
                                              key={subIndex}
                                              className={`flex flex-col w-72 ${
                                                !isLastRow
                                                  ? "border-b border-solid border-[#102C5D]"
                                                  : ""
                                              }`}
                                            >
                                              {subItem?.value ? (
                                                <Tooltip
                                                  componentsProps={{
                                                    popper: {
                                                      sx: {
                                                        [`& .${tooltipClasses.tooltip}`]:
                                                          {
                                                            backgroundColor: (
                                                              theme
                                                            ) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                        [`& .${tooltipClasses.arrow}`]:
                                                          {
                                                            color: (theme) =>
                                                              theme.palette
                                                                .common.black,
                                                          },
                                                      },
                                                    },
                                                  }}
                                                  title={
                                                    typeof subItem?.value ===
                                                    "boolean"
                                                      ? JSON.stringify(
                                                          subItem?.value
                                                        )
                                                      : subItem?.data_type ===
                                                        "multi_select_list"
                                                      ? subItem?.value?.join(
                                                          ","
                                                        )
                                                      : subItem?.data_type ===
                                                        "date_range"
                                                      ? (subItem?.value?.start_date).concat(
                                                          " - ",
                                                          subItem?.value
                                                            ?.end_date
                                                        )
                                                      : subItem?.data_type ===
                                                        "from_year_to_year"
                                                      ? (subItem?.value?.from)
                                                          .toString()
                                                          .concat(
                                                            " - ",
                                                            (subItem?.value?.to).toString()
                                                          )
                                                      : typeof subItem?.value ===
                                                        "object"
                                                      ? "Not Given"
                                                      : subItem.value
                                                  }
                                                  placement="bottom-start"
                                                  PopperProps={{
                                                    modifiers: [
                                                      {
                                                        name: "offset",
                                                        options: {
                                                          offset: [-5, -10],
                                                        },
                                                      },
                                                    ],
                                                  }}
                                                >
                                                  <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal truncate">
                                                    {typeof subItem?.value ===
                                                    "boolean"
                                                      ? JSON.stringify(
                                                          subItem?.value
                                                        )
                                                      : subItem?.data_type ===
                                                        "multi_select_list"
                                                      ? subItem?.value?.join(
                                                          ","
                                                        )
                                                      : subItem?.data_type ===
                                                        "date_range"
                                                      ? (subItem?.value?.start_date).concat(
                                                          " - ",
                                                          subItem?.value
                                                            ?.end_date
                                                        )
                                                      : subItem?.data_type ===
                                                        "from_year_to_year"
                                                      ? (subItem?.value?.from)
                                                          .toString()
                                                          .concat(
                                                            " - ",
                                                            (subItem?.value?.to).toString()
                                                          )
                                                      : typeof subItem?.value !==
                                                        "object"
                                                      ? subItem.value
                                                      : "Not Given"}
                                                  </p>
                                                </Tooltip>
                                              ) : (
                                                <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal capitalize">
                                                  Not given
                                                </p>
                                              )}
                                              <p
                                                className={`text-[#C9C9C9] text-xs text-left font-sans font-normal mt-1 ${
                                                  !isLastInRow ? "mb-2.5" : ""
                                                }`}
                                              >
                                                {subItem?.data_type ===
                                                "from_year_to_year"
                                                  ? "From year".concat(
                                                      " - ",
                                                      "To year"
                                                    )
                                                  : subItem?.data_type ===
                                                    "date_range"
                                                  ? "From date".concat(
                                                      " - ",
                                                      "To date"
                                                    )
                                                  : subItem.name}
                                              </p>
                                            </div>
                                            {!isLastInRow && nextItemExists && (
                                              <div className="border-r border-solid border-[#102C5D] mx-7"></div>
                                            )}
                                          </>
                                        );
                                      })}
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {loading ? (
          <div className="bene-detail-loader-box m-auto justify-center items-center flex">
            <img src={xpentraloader} alt="" />
          </div>
        ) : (
          <div className="beneTxnDetailsMob">
            {showDeclineModal && (
              <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
                <DeclinedPopup
                  onClose={closeDeclineModal}
                  accountId={beneTxnDetailsID}
                  status="Transaction-bene"
                  allAccounts={viewTxnDetails}
                  updateAllAccounts={updateAllAccounts}
                />
              </div>
            )}

            {openTransHistory && (
              <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                <TransactionHistory
                  bene_account_id={
                    beneTxnAccountData?.transaction[0]?.beneficiary_account_id
                  }
                  bene_account_name={
                    beneTxnAccountData?.transaction[0]?.beneficiary_account_name
                  }
                  onClose={closeTransactionHistory}
                />
              </div>
            )}

            <div className="fixed top-0 left-0 mt-0 w-full z-30 bg-[#3A5686]">
              <div className="w-full flex flex-row items-center">
                <div
                  className="backArrowMobile p-[20px]"
                  onClick={() => navigate(-1)}
                >
                  <img
                    src={arrowLeftimg}
                    alt="arrowleft"
                    className="h-[16px] w-[16px]"
                  />
                </div>
                <div className="w-full py-[8px]">
                  <div className="font-open-sans text-[16px] font-normal leading-[21.79px] text-left text-[#FFFFFF]">
                    Beneficiary
                  </div>

                  <div className="text-[#c9c9c9] font-open-sans font-semibold text-[14px] leading-[23px] tracking-[0]">
                    Transaction details
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[60px] w-full bg-[#314D7E] opacity-100">
              {beneTxnAccountData?.transaction?.map((account) => (
                <div key={account.txn_id} className="w-full">
                  <div className="flex justify-between py-[15px] px-[20px] border-b border-[#102C5D]">
                    <div className="flex flex-col gap-y-[4px]">
                      <div className="flex flex-row gap-x-[4px]">
                        <div className="text-[#4DDD37] font-open-sans font-semibold text-[16px] leading-[21.79px] text-left opacity-100 overflow-hidden text-ellipsis lowercase whitespace-nowrap">
                          &#8377; {formatCurrency(account.total_amount)}
                        </div>
                        <div className="relative">
                          <img
                            src={info}
                            className="w-3 h-3 cursor-pointer relative"
                            onClick={showAmountInfo}
                          />
                          {infoBox && (
                            <div
                              className="w-80 h-64 absolute flex flex-col top-[24px] right-[-270px] z-10	shadow-2xl"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="h-14 bg-[#506994] rounded-t-[20px] p-5">
                                <div className="flex flex-row justify-between">
                                  <span className="text-[#DADADA] font-open-sans font-normal text-[12px] leading-[16.34px] text-left">
                                    Transaction amount
                                  </span>
                                  <span className="text-[#4DDD37] font-sans font-semibold text-sm">
                                    &#8377;
                                    {formatCurrency(account.total_amount)}
                                  </span>
                                </div>
                              </div>
                              <div className="flex-1 bg-[#314D7E] rounded-b-[20px] p-5">
                                <div className="flex flex-row justify-between">
                                  <span className="text-[#C9C9C9] font-sans text-xs">
                                    Amount debited
                                  </span>
                                  <span className="text-[#FAFAFA] font-sans text-sm">
                                    &#8377;
                                    {formatCurrency(account.total_amount)}
                                  </span>
                                </div>
                                <div className="my-3 flex items-center justify-center ">
                                  <span className="w-3.5 h-0.5 rounded-[10px] bg-[#FF5860]"></span>
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="text-[#C9C9C9] font-sans text-xs">
                                    Tax amount
                                  </span>
                                  <span className="text-[#FAFAFA] font-sans text-sm">
                                    &#8377;
                                    {formatCurrency(account.tax_amount)}
                                  </span>
                                </div>
                                <div className="my-3 flex items-center justify-center ">
                                  <span className="w-3.5 h-0.5 rounded-[10px] bg-[#FF5860]"></span>
                                </div>
                                <div className="flex flex-row justify-between">
                                  <span className="text-[#C9C9C9] font-sans text-xs">
                                    Payment charge
                                  </span>
                                  <span className="text-[#FAFAFA] font-sans text-sm">
                                    &#8377;
                                    {formatCurrency(account.payment_charge)}
                                  </span>
                                </div>
                                <div className="my-5 calculatedFeeSeparation"></div>
                                <div className="flex flex-row justify-between">
                                  <div className="w-1/2">
                                    <div className="flex flex-row justify-between">
                                      <span className="text-[#C9C9C9] font-sans text-xs mt-px">
                                        Amount credited
                                      </span>
                                      <span className="w-3.5 h-2 text-[#FFFFFF] flex justify-end">
                                        =
                                      </span>
                                    </div>
                                  </div>
                                  <div className="w-1/2 flex justify-end">
                                    <span className="text-[#DADADA] font-open-sans font-normal text-[12px] leading-[16.34px]">
                                      &#8377;
                                      {formatCurrency(
                                        account.total_amount -
                                          account.tax_amount -
                                          account.payment_charge
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-[#DADADA] font-sans font-normal text-[12px] opacity-100 text-left">
                        Transaction amount
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-[4px]">
                      <p className="text-[#F9F9F9] font-open-sans font-semibold text-[16px] leading-[21.79px] text-right">
                        {account.distribution_utr
                          ? account.distribution_utr
                          : "N/A"}
                      </p>
                      <p className="text-[#DADADA] font-sans font-normal text-[12px] opacity-100 text-right">
                        UTR number
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between py-[15px] px-[20px] border-b border-[#102C5D]">
                    <div className="flex flex-col gap-y-[4px]">
                      <Tooltip
                        title={account.beneficiary_account_name}
                        arrow
                        placement="bottom"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "preventOverflow",
                              options: {
                                boundary: "viewport",
                              },
                            },
                          ],
                          style: {
                            maxWidth: "none",
                          },
                        }}
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                                color: "#FFFFFF",
                                fontSize: "14px",
                                padding: "8px",
                                borderRadius: "4px",
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        enterTouchDelay={0}
                      >
                        <p
                          className="text-[#F9F9F9] text-base text-left font-sans font-normal capitalize w-[170px] truncate block"
                          tabIndex={0}
                        >
                          {account.beneficiary_account_name.toLowerCase()}
                        </p>
                      </Tooltip>
                      <p className="text-[#DADADA] font-sans font-normal text-[12px] opacity-100 text-left">
                        Beneficiary name
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-[4px]">
                      <p
                        className={`${
                          account.beneficiary_account_approval_status ===
                          "pending"
                            ? "text-[#31E5E5]"
                            : "text-[#F9F9F9]"
                        } text-base	text-right capitalize font-sans font-normal`}
                      >
                        {account.beneficiary_account_approval_status}
                      </p>
                      <p className="text-[#DADADA] font-sans font-normal text-[12px] opacity-100 text-right">
                        Account status
                      </p>
                    </div>
                  </div>

                  <div className="flex py-[15px] px-[20px] justify-between items-center">
                    <div
                      className="cursor-pointer rounded-lg"
                      onClick={() =>
                        navigate(
                          `/accounts/owner/owner_account_details/${account.owner_account_id}`
                        )
                      }
                    >
                      <div className="flex flex-col gap-y-[4px]">
                        <p className="text-[#F9F9F9] text-base font-sans font-normal text-left">
                          {formatAccountNumber(
                            account.owner_account_bank_acc_no
                          )}
                        </p>
                        <p className="text-[#DADADA] text-xs font-sans font-normal leading-[16.34px] text-left">
                          Source account
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#ffffff] rounded-tl-[30px] rounded-bl-[30px] flex items-center justify-around h-[2rem] w-[6rem] mr-[-20px]">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(account.owner_account_ifsc_code))}
                        alt="Source account bank name"
                        className="w-14 h-3.5"
                      />
                    </div>
                  </div>

                  <div className="flex items-center gap-[10px] justify-between px-[20px]">
                    <div className="w-[40%] border-b border-[#102C5D]"></div>
                    <div className="w-[30px]">
                      <img src={arrowDownBlueWhite} alt="arrowDownBlueWhite" />
                    </div>
                    <div className="w-[40%] border-b border-[#102C5D]"></div>
                  </div>

                  <div className="flex justify-between py-[15px] px-[20px] items-center border-b border-[#102C5D]">
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/accounts/beneficiary_account/details/${account.beneficiary_account_id}`
                        )
                      }
                    >
                      <div className="flex flex-col gap-y-[4px]">
                        <p className="text-[#F9F9F9] text-base font-sans font-normal text-left">
                          {formatAccountNumber(
                            account.beneficiary_account_bank_acc_no
                          )}
                        </p>
                        <p className="text-[#DADADA] text-xs font-sans font-normal leading-[16.34px] text-left">
                          Destination account
                        </p>
                      </div>
                    </div>
                    <div className="bg-[#ffffff] rounded-tl-[30px] rounded-bl-[30px] flex items-center justify-around h-[2rem] w-[6rem] mr-[-20px]">
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(account.owner_account_ifsc_code))}
                        alt="Source account bank name"
                        className="w-14 h-3.5"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between py-[15px] px-[20px] items-center border-b border-[#102C5D]">
                    <div className="flex flex-col gap-y-[4px]">
                      <div className="flex flex-row">
                        <p className="text-[#F9F9F9] text-base font-sans font-normal leading-[21.79px] text-left">
                          {account.owner_account_ifsc_code}
                        </p>
                      </div>
                      <p className="text-[#DADADA] text-xs font-sans font-normal leading-[16.34px] text-left">
                        IFSC code
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-[4px]">
                      <p className="text-[#F9F9F9] text-base font-sans font-normal leading-[21.79px] text-right">
                        {account.distribution_mode_of_payment
                          ? account.distribution_mode_of_payment
                          : account.payment_method
                          ? account.payment_method
                          : "N/A"}
                      </p>
                      <p className="text-[#DADADA] text-xs font-sans font-normal leading-[16.34px] text-right">
                        Payment method
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-row bg-[#506994] items-center h-[96px] border-b border-[#102C5D]">
                    <div
                      className="w-1/2 h-full py-[10px] cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 flex flex-col justify-center place-items-center space-y-1"
                      // onClick={openTransactionHistory}
                      onClick={() => {
                        showToast();
                      }}
                    >
                      <img
                        src={transactionHistory}
                        className="w-[38px] h-[38px]"
                      />
                      <p className="text-[#F9F9F9] text-[12px] text-center font-sans font-normal leading-[16.34px] w-[69px]">
                        Transaction history
                      </p>
                    </div>
                    <div className="border-2 border-solid border-[#1d3a6d] h-full"></div>
                    <div
                      className="w-1/2 h-full py-[10px] cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 flex flex-col justify-center place-items-center space-y-1"
                      onClick={() => {
                        navigate(
                          `/transaction/beneficiary_transaction/view_all`,
                          {
                            state: {
                              category: account?.category,
                              approvalStatus: "all",
                            },
                          }
                        );
                      }}
                    >
                      <img
                        src={account.logo_url ? account.logo_url : BeneIcon}
                        className="w-[38px] h-[38px]"
                      />
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={account?.category}
                        arrow
                        placement="bottom"
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <p className="text-[#F9F9F9] text-[12px] text-center font-sans truncate font-normal leading-[16.34px] w-[69px]">
                          {account?.category}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-screen bg-[#1D3A6D]">
              <div className="flex flex-col w-full max-w-5xl m-auto">
                {viewTxnDetails?.map((account) =>
                  loadingBox && account.lifecycle_status === "pending" ? (
                    <>
                      <div className="w-full">
                        <div className="flex justify-center items-center w-full h-[106px] bg-[#102C5D] rounded-tr-[15px] rounded-br-[15px]">
                          <img
                            src={xpentraloader}
                            className="w-10 h-10 loader"
                          />
                        </div>
                      </div>
                    </>
                  ) : networkError || errorMessage || account.message ? (
                    <>
                      <div className="w-full">
                        <div className="flex-grow w-full h-[96px] bg-[#BB5353] px-[20px] py-[15px] gap-[4px]">
                          <div className="flex flex-row items-center h-full">
                            <div className="flex-none">
                              <img
                                src={skipping_verification}
                                className="h-[40px] w-[40px]"
                              />
                            </div>
                            <div className="flex flex-col space-y-1 ml-2.5">
                              <p className="text-[#F9F9F9] text-base text-left font-sans font-normal">
                                Failed to take action
                              </p>

                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  networkError
                                    ? "An error occurred while taking action on this transaction"
                                    : errorMessage
                                    ? errorMessage
                                    : account.message
                                    ? account.message
                                    : ""
                                }
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <p className="text-[#DADADA] text-xs text-left font-sans font-normal line-clamp">
                                  {networkError
                                    ? "An error occurred while taking action on this transaction"
                                    : errorMessage
                                    ? errorMessage
                                    : account.message
                                    ? account.message
                                    : ""}
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : account.last_updated_status === "Approved" &&
                    account.lifecycle_status === "pending" &&
                    account.last_updated_user_id === loginid?.employees?.id ? (
                    <>
                      <div className="w-full">
                        <div className="flex-grow w-full h-[106px] bg-[#314D7E] px-[20px] py-[15px] gap-[4px]">
                          <p className="text-[#31E5E5] text-base text-left font-sans font-normal">
                            Further approval pending
                          </p>
                          <p className="text-[#DADADA] text-sm text-left font-sans font-normal">
                            This transaction has been approved by you, but it
                            requires another user's approval before settlement.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : account.last_updated_status === "Approved" &&
                    account.lifecycle_status === "pending" &&
                    services?.find(
                      (service) => service === "ABeneficiaryTxn"
                    ) === undefined ? (
                    <>
                      <div className="w-full flex flex-row">
                        <div className="flex-grow w-full h-[106px] bg-[#314D7E] px-[20px] py-[15px] gap-[4px]">
                          <p className="text-[#31E5E5] text-base text-left font-sans font-normal">
                            Further approval pending
                          </p>
                          <p className="text-[#DADADA] text-sm text-left font-sans font-normal">
                            This transaction requires another user's approval
                            before settlement.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : account?.lifecycle_status === "rejected" ? (
                    <>
                      <div className="w-full h-[96px] bg-[#314D7E]">
                        <div className="flex-grow w-full h-[96px] bg-[#314D7E] px-[20px] py-[15px] gap-[4px]">
                          <p className="text-[#FF5860] text-base leading-[21.79px] text-left font-sans font-normal">
                            Rejected reason
                          </p>

                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={account.rejection_reason}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            <p className="text-[#DADADA] text-[12px] leading-[16.34px] text-justify font-sans font-normal line-clamp">
                              {account.rejection_reason}
                            </p>
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  ) : account?.beneficiary_account_approval_status ===
                    "pending" ? (
                    <>
                      <div className="w-full">
                        <div className="w-full h-auto bg-[#314D7E] px-[20px] py-[15px]">
                          <p className="text-[#31E5E5] text-base text-left font-sans font-normal">
                            Account approval pending
                          </p>
                          <p className="text-[#DADADA] text-sm text-left font-sans font-normal mt-1">
                            {services?.find(
                              (service) => service === "ABeneficiaryTxn"
                            ) !== undefined
                              ? "Account is not approved, approve account before approving transaction."
                              : "To approve the account, please contact the authorized person."}
                          </p>
                          <div
                            className={`flex justify-between items-center w-[150px] h-7 py-1 px-2.5 mt-2 rounded-[5px] bg-[#1D3A6D] ${
                              services?.find(
                                (service) => service === "ABeneficiaryTxn"
                              ) !== undefined
                                ? "opacity-100 cursor-pointer hover:bg-gray-200 hover:bg-opacity-20"
                                : "opacity-50 cursor-not-allowed"
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "ABeneficiaryTxn"
                                ) !== undefined
                              ) {
                                // handleApproveTransaction(account.txn_id);
                                // setBeneAccountDetailpop(true);
                                navigate(
                                  `/accounts/beneficiary_account/details/${account?.beneficiary_account_id}`
                                );
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccount",
                                  },
                                });
                              }
                            }}
                          >
                            <p className="text-sm text-[#FFFFFF] font-sans font-regular">
                              Approve Account
                            </p>
                            <img
                              src={arrow_right_white_filled}
                              className="w-4 h-4"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : account?.beneficiary_account_approval_status ===
                      "approved" && account.lifecycle_status === "pending" ? (
                    <>
                      <div className="w-full bg-[#314D7E]">
                        <div
                          className={`flex flex-row w-full h-[96px] overflow-hidden ${
                            checkTransaction ? "opacity-100" : "opacity-50"
                          }`}
                        >
                          <div
                            className={`w-full h-full bg-[#F05A6A] flex flex-col justify-center place-items-center space-y-1 ${
                              checkTransaction
                                ? "hover:opacity-80 cursor-pointer"
                                : "cursor-default"
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "ABeneficiaryTxn"
                                ) !== undefined &&
                                checkTransaction
                              ) {
                                handleDeclinedDetails(account.txn_id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccount",
                                  },
                                });
                              }
                            }}
                          >
                            <img
                              src={declineImage}
                              className="w-[38px] h-[38px]"
                            />
                            <p className="text-[#FFFFFF] text-[12px] leading-[16.34px] text-center font-sans font-normal">
                              Reject
                            </p>
                          </div>
                          <div
                            className={`w-full h-full bg-[#69B76F] flex flex-col justify-center place-items-center space-y-1 ${
                              checkTransaction
                                ? "hover:opacity-80 cursor-pointer"
                                : "cursor-default"
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) => service === "ABeneficiaryTxn"
                                ) !== undefined &&
                                checkTransaction
                              ) {
                                handleApproveTransaction(account.txn_id);
                              } else {
                                navigate("/feature_not_assigned", {
                                  state: {
                                    unavailableService: "BAAccount",
                                  },
                                });
                              }
                            }}
                          >
                            <img
                              src={approveImage}
                              className="w-[38px] h-[38px]"
                            />
                            <p className="text-[#FFFFFF] text-[12px] leading-[16.34px] text-center font-sans font-normal">
                              Approve
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="w-1/2 flex flex-row"
                        style={{ display: "none" }}
                      >
                        <div className="flex-grow w-5/12 h-[106px] mt-[52px] bg-red-500"></div>
                        <div className="flex-grow w-7/12 h-[106px] mt-[52px] bg-[#1D3A6D]"></div>
                      </div>
                    </>
                  )
                )}
                <div
                  className={`flex flex-col w-full h-auto mt-5 overflow-hidden relative ${
                    beneTxnAccountData?.transaction[0]
                      ?.distribution_lifecycle_status ===
                      "distribution_complete" ||
                    beneTxnAccountData?.transaction[0]?.lifecycle_status ===
                      "abandoned" ||
                    (beneTxnAccountData?.transaction[0]?.lifecycle_status ===
                      "approved" &&
                      beneTxnAccountData?.transaction[0]
                        ?.beneficiary_account_approval_status !== "pending")
                      ? "bg-[#1D3A6D]"
                      : "bg-[#102C5D]"
                  }`}
                >
                  <div className="bg-[#3A5686]">
                    <p className="text-left font-sans font-normal text-[16px] leading-[21.79px] text-[#DADADA] py-[15px] px-[20px]">
                      Transaction Timeline
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <div
                      className="Approval-box border-b border-[#102C5D] flex flex-row justify-between items-center w-full cursor-pointer bg-[#506994] py-[15px] px-[20px]"
                      onClick={() => {
                        setShowApprovalTimeline(!showApprovalTimeline);
                        if (!showApprovalTimeline) {
                          setShowDetails(true);
                          setShowAdditionalDetails(false);
                          setShowDistributionTimeline(false);
                        }
                        setOpenDistributionTimeline(false);
                      }}
                    >
                      <div className="flex flex-col gap-[4px]">
                        {viewTxnDetails?.length >= 1 &&
                          viewTxnDetails?.map((account) =>
                            account?.lifecycle_status === "rejected" ? (
                              <>
                                <p className="text-[#FF5860] text-[16px] leading-[21.79px] text-left capitalize font-sans font-semibold">
                                  {account.lifecycle_status}
                                </p>
                              </>
                            ) : account?.lifecycle_status === "approved" ? (
                              <>
                                <p className="text-[#B196FA] text-[16px] leading-[16.34px] text-left capitalize font-sans font-semibold">
                                  {account.lifecycle_status}
                                </p>
                              </>
                            ) : account?.lifecycle_status === "abandoned" ? (
                              <>
                                <p className="text-[#B4B4B4] text-[16px] leading-[21.79px] text-left capitalize font-sans font-semibold">
                                  {account.lifecycle_status}
                                </p>
                              </>
                            ) : account.last_updated_status === "Approved" &&
                              account.last_updated_user_id ===
                                loginid?.employees?.id ? (
                              <>
                                <p className="text-[#31E5E5] text-[16px] leading-[21.79px] text-left font-sans font-semibold">
                                  Awaiting approval
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="text-[#31E5E5] text-[16px] leading-[21.79px] text-left font-sans font-semibold">
                                  {services?.find(
                                    (service) => service === "ABeneficiaryTxn"
                                  ) === undefined
                                    ? "Awaiting approval"
                                    : "Require approval"}
                                </p>
                              </>
                            )
                          )}

                        <p className="text-[#DADADA] font-sans font-normal text-[12px] leading-[16.34px] text-left ">
                          Status
                        </p>
                      </div>
                      <div className="flex flex-col gap-[4px] items-end">
                        <p className="text-[#DADADA] text-[16px] leading-[21.79px] text-right font-sans font-normal">
                          Stage 1 - Approval
                        </p>
                        <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                          <img
                            src={showApprovalTimeline ? arrow_up : arrow_down}
                            alt="document"
                            className="w-2.5 h-2.5 "
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={`Distribution-box flex flex-row justify-between items-center w-full py-[15px] px-[20px] ${
                        viewTxnDetails?.length >= 1 &&
                        viewTxnDetails[0]?.distribution_lifecycle_status
                          ? "bg-[#314D7E] hover:bg-[#506994] cursor-pointer"
                          : "bg-[#314D7E] cursor-default"
                      }`}
                      onClick={
                        viewTxnDetails?.length >= 1 &&
                        viewTxnDetails[0]?.distribution_lifecycle_status
                          ? handleDistributionClick
                          : null
                      }
                    >
                      <div className="flex flex-col gap-[4px]">
                        {viewTxnDetails?.map((account) =>
                          account?.distribution_lifecycle_status ===
                          "distribution_complete" ? (
                            <>
                              <p className="text-[#4DDD37] text-[16px] leading-[21.79px] text-left font-sans font-semibold opacity-100">
                                Distribution complete
                              </p>
                            </>
                          ) : account?.distribution_lifecycle_status ===
                              "rejected" ||
                            account?.distribution_lifecycle_status ===
                              "failed" ? (
                            <>
                              <p className="text-[#FF5860] text-[16px] leading-[21.79px] text-left font-sans font-semibold opacity-100">
                                {`Distribution ${account?.distribution_lifecycle_status}`}
                              </p>
                            </>
                          ) : account?.distribution_lifecycle_status ===
                            "abandoned" ? (
                            <>
                              <p className="text-[#B4B4B4] text-[16px] w-[170px] break-words	leading-[21.79px] text-left font-sans font-semibold opacity-100">
                                {"Distribution".concat(
                                  " ",
                                  account.distribution_lifecycle_status
                                )}
                              </p>
                            </>
                          ) : account?.distribution_lifecycle_status ? (
                            <>
                              <p className="text-[#FBC02D] text-[16px] leading-[21.79px] text-left font-sans font-semibold opacity-100">
                                {account.distribution_lifecycle_status ===
                                "created"
                                  ? "Distribution created"
                                  : account.distribution_lifecycle_status ===
                                    "awaiting_funds"
                                  ? "Awaiting funds"
                                  : account.distribution_lifecycle_status ===
                                    "distribution_initiated"
                                  ? "Distribution initiated"
                                  : account.distribution_lifecycle_status}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                className={`${
                                  account?.distribution_lifecycle_status
                                    ? "opacity-100 text-[#FBC02D]"
                                    : "opacity-50 text-[#F9F9F9]"
                                } text-[16px] leading-[21.79px] text-left font-sans font-semibold`}
                              >
                                {account?.distribution_lifecycle_status
                                  ? account?.distribution_lifecycle_status
                                  : "N/A"}
                              </p>
                            </>
                          )
                        )}

                        <p
                          className={`text-[#DADADA] text-[12px] leading-[16.34px] text-left font-sans font-normal ${
                            beneTxnAccountData?.transaction[0]
                              ?.distribution_lifecycle_status
                              ? "opacity-100"
                              : "opacity-50"
                          }`}
                        >
                          Status
                        </p>
                      </div>
                      <div className="flex flex-col gap-[4px] items-end">
                        <p
                          className={`text-[#DADADA] text-[16px] leading-[21.79px] text-left font-sans font-normal ${
                            viewTxnDetails?.length >= 1 &&
                            viewTxnDetails[0]?.distribution_lifecycle_status
                              ? "opacity-100"
                              : "opacity-50"
                          }`}
                        >
                          Stage 2 - Distribution
                        </p>
                        <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                          <img
                            src={
                              showDistributionTimeline ? arrow_up : arrow_down
                            }
                            alt="document"
                            className={`w-2.5 h-2.5 ${
                              viewTxnDetails?.length >= 1 &&
                              viewTxnDetails[0]?.distribution_lifecycle_status
                                ? "opacity-100"
                                : "opacity-50"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#3A5686]">
                      <div className="h-[46px]"></div>
                    </div>
                  </div>

                  {viewTxnDetails?.length >= 1 &&
                    viewTxnDetails?.map((account) =>
                      account?.lifecycle_status === "rejected" ? (
                        <>
                          <div className="px-5 py-[15px] h-3 absolute w-full top-[206px]">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-rejected-approval absolute top-0.5 left-0 px-0.5 pt-px"
                                value={0.5}
                              />
                            </div>
                          </div>
                        </>
                      ) : account?.distribution_lifecycle_status ===
                          "rejected" ||
                        account?.distribution_lifecycle_status === "failed" ? (
                        <>
                          <div className="px-5 py-[15px] h-3 absolute w-full top-[206px]">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-rejected-approval absolute top-0.5 left-0 px-0.5 pt-px"
                                value={1}
                              />
                            </div>
                          </div>
                        </>
                      ) : account?.lifecycle_status === "abandoned" ||
                        account?.distribution_lifecycle_status ===
                          "abandoned" ? (
                        <>
                          <div
                            className={`px-5 py-[15px] h-3 absolute w-full ${
                              account?.distribution_lifecycle_status ===
                              "abandoned"
                                ? "top-[225px]"
                                : "top-[206px]"
                            }`}
                          >
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-abandoned-transaction absolute top-0.5 left-0 px-0.5 pt-px"
                                value={`${
                                  account?.lifecycle_status === "abandoned"
                                    ? 0.5
                                    : account?.distribution_lifecycle_status ===
                                      "abandoned"
                                    ? 1
                                    : 0.5
                                }`}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="px-4 py-[15px] h-3 absolute w-full top-[206px]">
                            <div className="bg-[#102C5D] w-full h-full rounded-[15px] relative">
                              <progress
                                className="w-full h-2 progress-require-approval absolute top-0.5 left-0 px-0.5 pt-px"
                                value={`${
                                  account?.distribution_lifecycle_status ===
                                  "distribution_complete"
                                    ? 1
                                    : account?.distribution_lifecycle_status
                                    ? 0.75
                                    : 0.25
                                }`}
                              />
                            </div>
                          </div>
                        </>
                      )
                    )}
                </div>
              </div>
            </div>

            {showApprovalTimeline ? (
              <>
                <div className="bg-[#1D3A6D] py-[40px] px-[20px] approvalTimelineMob">
                  {viewTxnDetails?.length >= 1 &&
                    [...viewTxnDetails[0]?.logs]
                      ?.reverse()
                      .map((account, index) => {
                        const trackCount =
                          (index + 1) % viewTxnDetails[0]?.logs?.length === 0;
                        const logs_length = viewTxnDetails[0]?.logs?.length;
                        const num = index + 1;
                        return (
                          <>
                            <div
                              key={index}
                              className="flex flex-row items-start w-full max-w-5xl space-x-5 relative"
                            >
                              <div
                                className={`absolute border-l-2 border-solid border-[#506994] w-[4%] ${
                                  num === logs_length
                                    ? "h-10 duration-500 ease-in-out"
                                    : openApprovalTimeline[index]
                                    ? "h-full duration-500 ease-in-out"
                                    : "h-full duration-500 ease-in-out"
                                }`}
                              >
                                <div className="absolute flex flex-row w-full">
                                  <img
                                    src={timeline_dot}
                                    className="w-5 h-5 mt-[34px] ml-[-8px]"
                                  />
                                  <div className="w-2 border-t-2 mt-[2.7rem] border-solid border-[#506994]"></div>
                                </div>

                                {showApprovalTimeline && num === 1 && (
                                  <>
                                    <div className="absolute border-l-2 border-solid border-[#506994] h-6 ml-[-1.75px] -mt-6"></div>
                                    <div className="absolute border-t-2 border-solid border-[#506994] w-36 ml-0 -mt-6"></div>
                                    <div className="absolute border-r-2 border-solid border-[#506994] h-[18px] ml-36 -mt-10"></div>
                                  </>
                                )}
                              </div>

                              <div
                                className={`flex flex-col w-full px-[20px] py-[15px] rounded-[15px] bg-[#314D7E] hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer overflow-hidden approval-data ${
                                  openApprovalTimeline[index]
                                    ? "h-auto"
                                    : "h-100"
                                }`}
                                onClick={() => handleApprovalTimeline(index)}
                              >
                                <div className="flex flex-col items-center">
                                  <div className="flex flex-row justify-between items-center w-full">
                                    <div className="flex flex-col gap-[4px]">
                                      <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                        {account.action === "Add Status"
                                          ? account.remark.toLowerCase()
                                          : account.action.toLowerCase()}
                                      </p>
                                      <p className="text-[#DADADA] text-[12px] leading-[16.34px] text-left font-sans font-normal">
                                        Action
                                      </p>
                                    </div>

                                    <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                                      <img
                                        src={
                                          openApprovalTimeline[index]
                                            ? arrow_up
                                            : arrow_down
                                        }
                                        alt="document"
                                        className="w-2.5 h-2.5 "
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-start w-full gap-[15px] mt-[15px]">
                                    {account?.browser_name && (
                                      <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col gap-[4px]">
                                          <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                            {account?.browser_name ===
                                            "Microsoft Edge (Chromium)"
                                              ? "Microsoft Edge"
                                              : account.browser_name}
                                          </p>
                                          <p className="text-[#DADADA] text-[12px] leading-[16.34px]  text-left font-sans font-normal">
                                            Browser
                                          </p>
                                        </div>
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayBrowser(
                                              account?.browser_name
                                            ) +
                                            ".svg")}
                                          alt="Browser"
                                          className="w-7 h-7"
                                        />
                                      </div>
                                    )}
                                    {account?.platform && (
                                      <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col gap-[4px]">
                                          <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                            {account?.platform}
                                          </p>
                                          <p className="text-[#DADADA] text-[12px] leading-[16.34px] text-left font-sans font-normal">
                                            Platform
                                          </p>
                                        </div>
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayPlatform(account?.platform) +
                                            ".svg")}
                                          alt="Platform"
                                          className="w-7 h-7"
                                        />
                                      </div>
                                    )}
                                    {account?.os_version && (
                                      <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col gap-[4px]">
                                          <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                            {account?.os_version}
                                          </p>
                                          <p className="text-[#DADADA] text-[12px] leading-[16.34px] text-left font-sans font-normal">
                                            Operating system
                                          </p>
                                        </div>
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayOS(account?.os_version) +
                                            ".svg")}
                                          alt="OS version"
                                          className="w-7 h-7"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex flex-col items-start w-full gap-[15px] mt-[15px]">
                                    {account?.browser_name && (
                                      <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col gap-[4px]">
                                          <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                            {account?.browser_name ===
                                            "Microsoft Edge (Chromium)"
                                              ? "Microsoft Edge"
                                              : account.browser_name}
                                          </p>
                                          <p className="text-[#DADADA] text-[12px] leading-[16.34px]  text-left font-sans font-normal">
                                            Browser
                                          </p>
                                        </div>
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayBrowser(
                                              account?.browser_name
                                            ) +
                                            ".svg")}
                                          alt="Browser"
                                          className="w-7 h-7"
                                        />
                                      </div>
                                    )}
                                    {account?.platform && (
                                      <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col gap-[4px]">
                                          <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                            {account?.platform}
                                          </p>
                                          <p className="text-[#DADADA] text-[12px] leading-[16.34px] text-left font-sans font-normal">
                                            Platform
                                          </p>
                                        </div>
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayPlatform(account?.platform) +
                                            ".svg")}
                                          alt="Platform"
                                          className="w-7 h-7"
                                        />
                                      </div>
                                    )}
                                    {account?.os_version && (
                                      <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col gap-[4px]">
                                          <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                            {account?.os_version}
                                          </p>
                                          <p className="text-[#DADADA] text-[12px] leading-[16.34px] text-left font-sans font-normal">
                                            Operating system
                                          </p>
                                        </div>
                                        <img
                                          src={require("../../../assets/Timeline/" +
                                            displayOS(account?.os_version) +
                                            ".svg")}
                                          alt="OS version"
                                          className="w-7 h-7"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {openApprovalTimeline[index] && (
                                  <>
                                    <div className="mt-[15px] border border-[#102C5D]"></div>
                                    <div className="mt-[15px]">
                                      {renderApprovalData(account)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {num !== logs_length && (
                              <>
                                <div className="max-w-5xl m-auto h-5 border-l-2 border-solid border-[#506994]"></div>
                              </>
                            )}
                          </>
                        );
                      })}
                </div>
              </>
            ) : showDistributionTimeline ? (
              <>
                <div className="bg-[#1D3A6D] py-[40px] px-[20px] distributionTimelineMob">
                  {viewTxnDetails?.length >= 1 &&
                    [...viewTxnDetails[0]?.distribution_logs]
                      ?.reverse()
                      .map((account, index) => {
                        const trackCount =
                          (index + 1) %
                            viewTxnDetails[0]?.distribution_logs?.length ===
                          0;
                        const distributionLogs_length =
                          viewTxnDetails[0]?.distribution_logs?.length;
                        const num = index + 1;
                        return (
                          <>
                            <div
                              key={index}
                              className="flex fex-row items-start w-full max-w-5xl space-x-5 relative"
                            >
                              <div
                                className={`absolute border-l-2 border-solid border-[#506994] w-[4%] ${
                                  num === distributionLogs_length
                                    ? "h-10 duration-500 ease-in-out"
                                    : openDistributionTimeline[index]
                                    ? "h-full duration-500 ease-in-out"
                                    : "h-20 duration-500 ease-in-out"
                                }`}
                              >
                                <div className="absolute flex flex-row w-full">
                                  <img
                                    src={timeline_dot}
                                    className="w-5 h-5 mt-[31px] ml-[-8px]"
                                  />
                                  <div className="w-6 border-t-2 mt-10 ml-1 border-solid border-[#506994]"></div>
                                </div>

                                {showDistributionTimeline && num === 1 && (
                                  <>
                                    <div className="absolute border-l-2 border-solid border-[#506994] h-6 ml-[-1.75px] -mt-6"></div>
                                    <div className="absolute border-t-2 border-solid border-[#506994] w-96 ml-0 -mt-6"></div>
                                    <div className="absolute border-r-2 border-solid border-[#506994] h-[18px] ml-96 -mt-10"></div>
                                  </>
                                )}
                              </div>
                              <div
                                className={`flex flex-col w-full px-[20px] py-[15px] rounded-[15px] bg-[#314D7E] hover:bg-gray-200 hover:bg-opacity-20 cursor-pointer overflow-hidden distribution-data ${
                                  openDistributionTimeline[index]
                                    ? "h-auto"
                                    : "h-18"
                                }`}
                                onClick={() =>
                                  handleDistributionTimeline(index)
                                }
                              >
                                <div className="flex flex-row justify-between items-center">
                                  <div className="flex flex-row items-center">
                                    <div className="flex flex-col gap-[4px]">
                                      <p className="text-[#FCFCFC] text-base text-left font-sans font-normal capitalize">
                                        {account.action === "Add Status"
                                          ? account.remark.toLowerCase()
                                          : account.action.toLowerCase()}
                                      </p>
                                      <p className="text-[#DADADA] text-[12px] leading-[16.34px]  text-left font-sans font-normal">
                                        Action
                                      </p>
                                    </div>
                                  </div>
                                  <div className="grid place-content-center w-5 h-5 bg-[#1D3A6D] rounded-md">
                                    <img
                                      src={
                                        openDistributionTimeline[index]
                                          ? arrow_up
                                          : arrow_down
                                      }
                                      alt="document"
                                      className="w-2.5 h-2.5 "
                                    />
                                  </div>
                                </div>
                                {openDistributionTimeline[index] && (
                                  <>
                                    <div className="mt-[15px]">
                                      {renderDistributionData(account)}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            {num !== distributionLogs_length && (
                              <>
                                <div className="max-w-5xl m-auto h-5 border-l-2 border-solid border-[#506994]"></div>
                              </>
                            )}
                          </>
                        );
                      })}
                </div>
              </>
            ) : (
              <div className="details-div-trans w-screen bg-[#1d3a6d] mt-[10px] ">
                <div className="detail-section-trans h-auto bg-[#1d3a6d] max-w-5xl">
                  <div className="buttonsfordetails w-full">
                    <button
                      className={`DetailsButton w-1/2 h-[39px] py-[10px] ${
                        showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                      }`}
                      onClick={toggleDetails}
                    >
                      <span
                        className={`text-sm leading-[19.07px] text-center font-sans font-semibold ${
                          showDetails ? "text-black" : "text-white"
                        } `}
                      >
                        Details
                      </span>
                    </button>
                    <button
                      className={`AdditionalDetailsButton w-1/2 h-[39px] py-[10px] ${
                        showAdditionalDetails
                          ? "effective"
                          : "hover:bg-[#A3A3A3]"
                      }`}
                      onClick={toggleAdditionalDetails}
                    >
                      <span
                        className={`text-sm leading-[19.07px] text-center font-sans font-semibold ${
                          showAdditionalDetails ? "text-black" : "text-white"
                        }`}
                      >
                        Additional Details
                      </span>
                    </button>
                  </div>
                  <div className="p-[20px]">
                    {showDetails &&
                      viewTxnDetails?.map((account) => (
                        <div className={`flex flex-col gap-[20px]`}>
                          <div
                            className="
                           w-full
                        h-auto px-[20px] py-[15px] rounded-[20px] bg-[#314D7E]"
                          >
                            <p className="text-[#DADADA] text-base  text-left font-sans font-semibold pb-5">
                              Origin Details
                            </p>
                            <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal mb-1 truncate">
                              {formatDateViewAll(
                                account?.created_on,
                                true,
                                true
                              )}
                            </p>
                            <p className="text-[#DADADA] text-xs text-left font-sans font-normal border-b border-solid border-[#102C5D] pb-[15px]">
                              Initiated on
                            </p>
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                account?.logs?.[0]?.first_name +
                                " " +
                                account?.logs?.[0]?.last_name[0].toUpperCase() +
                                account?.logs?.[0]?.last_name.slice(1)
                              }
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal pt-[15px] mb-1 truncate">
                                {account?.logs?.[0]?.first_name +
                                  " " +
                                  account?.logs?.[0]?.last_name[0].toUpperCase() +
                                  account?.logs?.[0]?.last_name.slice(1)}
                              </p>
                            </Tooltip>
                            <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                              Initiated by
                            </p>
                          </div>

                          {account?.distribution_lifecycle_status ===
                          "distribution_complete" ? (
                            <>
                              <div className="w-full h-auto px-[20px] py-[15px]  rounded-[20px] bg-[#314D7E]">
                                <p className="text-[#DADADA] text-sm text-left font-sans font-semibold pb-5">
                                  Settlement Details
                                </p>
                                <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal mb-1">
                                  {account?.distribution_credited_at
                                    ? ISO_DataFormatter(
                                        account?.distribution_credited_at
                                      )
                                    : "N/A"}
                                </p>
                                <p className="text-[#DADADA] text-xs text-left font-sans font-normal border-b border-solid border-[#102C5D] pb-2.5">
                                  Settled on
                                </p>
                                <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal pt-2.5 mb-1 block w-full truncate">
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                          [`& .${tooltipClasses.arrow}`]: {
                                            color: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={account.distribution_settlement_id}
                                    arrow
                                    placement="bottom"
                                    PopperProps={{
                                      style: {
                                        maxWidth: "none",
                                      },
                                    }}
                                  >
                                    <p className="text-[#F9F9F9] text-sm font-sans font-normal text-left truncate block w-full mb-1">
                                      {account?.distribution_settlement_id
                                        ? account?.distribution_settlement_id
                                        : "N/A"}
                                    </p>
                                  </Tooltip>
                                </p>
                                <p className="text-[#DADADA] text-xs text-left font-sans font-normal">
                                  Settlement ID
                                </p>
                              </div>
                              <div className="w-[328px] h-auto px-7 pt-5 rounded-[20px] bg-[#314D7E]">
                                <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5">
                                  Support Information
                                </p>

                                <p
                                  id="id-firstLetter"
                                  className="text-[#F9F9F9] text-sm font-sans font-normal text-left block w-full mb-1"
                                >
                                  {account.txn_id}
                                </p>

                                <p className="text-[#DADADA] text-xs font-sans font-normal text-left">
                                  Transaction ID
                                </p>
                              </div>
                            </>
                          ) : (
                            <div className="w-full h-auto px-[20px] py-[15px] rounded-[20px] bg-[#314D7E]">
                              <p className="text-base text-[#DADADA] text-left font-sans font-semibold pb-5">
                                Support Information
                              </p>

                              <p
                                id="id-firstLetter"
                                className="text-[#F9F9F9] text-sm font-sans font-normal text-left block w-full mb-1"
                              >
                                {account.txn_id}
                              </p>
                              <p className="text-[#DADADA] text-xs font-sans font-normal text-left">
                                Transaction ID
                              </p>
                            </div>
                          )}
                        </div>
                      ))}
                    {showAdditionalDetails && (
                      <>
                        <div
                          className={`px-[20px] py-[15px] bg-[#314D7E] h-auto rounded-[20px] w-full`}
                        >
                          <p className="text-[#DADADA] text-base text-left font-sans font-semibold">
                            Additional Details
                          </p>

                          {validItems?.length === 0 ? (
                            <>
                              <p className="text-left text-[#DADADA] text-xs font-sans font-normal">
                                Additional details are not supported for the
                                selected category.
                              </p>
                            </>
                          ) : (
                            <div className="mx-auto">
                              {validItems?.map((obj, index) => {
                                if (index % 3 === 0) {
                                  const isLastRow =
                                    index + 3 >= validItems?.length;
                                  const isFirstRow = index === 0;

                                  return (
                                    <div
                                      key={index}
                                      className={`flex flex-col `}
                                    >
                                      {validItems
                                        .slice(index, index + 3)
                                        .map((subItem, subIndex) => {
                                          const isLastInRow = subIndex === 2;
                                          const nextItemExists =
                                            validItems[index + subIndex + 1] !==
                                            undefined;
                                          return (
                                            <>
                                              <div
                                                key={subIndex}
                                                className={`flex flex-col gap-[4px] w-full py-[15px] ${
                                                  !isLastRow
                                                    ? "border-b border-solid border-[#102C5D]"
                                                    : ""
                                                }`}
                                              >
                                                {subItem.value ? (
                                                  <Tooltip
                                                    componentsProps={{
                                                      popper: {
                                                        sx: {
                                                          [`& .${tooltipClasses.tooltip}`]:
                                                            {
                                                              backgroundColor: (
                                                                theme
                                                              ) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                          [`& .${tooltipClasses.arrow}`]:
                                                            {
                                                              color: (theme) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                        },
                                                      },
                                                    }}
                                                    title={
                                                      typeof subItem?.value ===
                                                      "boolean"
                                                        ? JSON.stringify(
                                                            subItem?.value
                                                          )
                                                        : subItem?.data_type ===
                                                          "multi_select_list"
                                                        ? subItem?.value?.join(
                                                            ","
                                                          )
                                                        : subItem?.data_type ===
                                                          "date_range"
                                                        ? (subItem?.value?.start_date).concat(
                                                            " - ",
                                                            subItem?.value
                                                              ?.end_date
                                                          )
                                                        : subItem?.data_type ===
                                                          "from_year_to_year"
                                                        ? (subItem?.value?.from)
                                                            .toString()
                                                            .concat(
                                                              " - ",
                                                              (subItem?.value?.to).toString()
                                                            )
                                                        : typeof subItem?.value !==
                                                          "object"
                                                        ? subItem.value
                                                        : "Not Given"
                                                    }
                                                    placement="bottom-start"
                                                    PopperProps={{
                                                      modifiers: [
                                                        {
                                                          name: "offset",
                                                          options: {
                                                            offset: [-5, -10],
                                                          },
                                                        },
                                                      ],
                                                    }}
                                                  >
                                                    <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal truncate">
                                                      {typeof subItem?.value ===
                                                      "boolean"
                                                        ? JSON.stringify(
                                                            subItem?.value
                                                          )
                                                        : subItem?.data_type ===
                                                          "multi_select_list"
                                                        ? subItem?.value?.join(
                                                            ","
                                                          )
                                                        : subItem?.data_type ===
                                                          "date_range"
                                                        ? (subItem?.value?.start_date).concat(
                                                            " - ",
                                                            subItem?.value
                                                              ?.end_date
                                                          )
                                                        : subItem?.data_type ===
                                                          "from_year_to_year"
                                                        ? (subItem?.value?.from)
                                                            .toString()
                                                            .concat(
                                                              " - ",
                                                              (subItem?.value?.to).toString()
                                                            )
                                                        : typeof subItem?.value !==
                                                          "object"
                                                        ? subItem.value
                                                        : "Not Given"}
                                                    </p>
                                                  </Tooltip>
                                                ) : (
                                                  <p className="text-[#F9F9F9] text-sm text-left font-sans font-normal capitalize">
                                                    Not given
                                                  </p>
                                                )}
                                                <p
                                                  className={`text-[#C9C9C9] text-xs text-left font-sans font-normal`}
                                                >
                                                  {subItem?.data_type ===
                                                  "from_year_to_year"
                                                    ? "From year".concat(
                                                        " - ",
                                                        "To year"
                                                      )
                                                    : subItem?.data_type ===
                                                      "date_range"
                                                    ? "From date".concat(
                                                        " - ",
                                                        "To date"
                                                      )
                                                    : subItem.name}
                                                </p>
                                              </div>
                                              {!isLastInRow &&
                                                nextItemExists && (
                                                  <div className="border-r border-solid border-[#102C5D] mx-7"></div>
                                                )}
                                            </>
                                          );
                                        })}
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BeneTxnDetails;
